import { Constants } from '../../../shared/constants';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class PrependMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    try {
      console.log('prepend');
      rule = rule || '';
      let toPrepend = rule.toprepend || '';
      
      if (dataElementsData) {
        toPrepend = this.toolsService.ExtractValuesByType(dataElementsData).string || Constants.Defaults.DataItemValue;
      }
      
      dataBus = this.DataActionModifierApply(dataBus, this.Modifier, toPrepend);
      
      // console.log(`molecule working, final value: ${toPrepend}`);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, prepend: string): string {
    const type = this.toolsService.GetObjectType(value);
    if (type === 'string' || type === 'number') {
      if (value.toString().startsWith(prepend)) {
        value = value.substr(prepend.length);
      }
      
      value = prepend + value;
    }
    return value;
  }
}
