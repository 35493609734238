export enum LeapXLEventType {
  Init = 'init',
  OptionSelected = 'option-selected',
  OptionDeselected = 'option-deselected',
  OptionsSelected = 'options-selected',
  Hover = 'hover',
  Click = 'click',
  Typing = 'keyup',
  Search = 'search',
  PageChanged = 'page-changed',
  RowSelected = 'row-selected',
  Reload = 'reload',
  ValueChange = 'value-change',
  StateChange = 'state-change',
  StateChecked = 'state-checked',
  StateUnchecked = 'state-unchecked',
  DateSelected = 'date-selected',
  ViewStart = 'View Start',
  ViewChange = 'View Change',
  ViewEnd = 'View End',
  AppInit = 'App Init',
  DatasourceChange = 'Datasource Change',
  InboundExternalDatasource = 'Inbound External Datasource',
  InterimVolunteer = 'Interim Volunteer',
  AppBroadcast = 'App Broadcast',
  Custom = 'Custom User',
  StartDateSelected = 'start-date-selected',
  EndDateSelected = 'end-date-selected',
  RangeDateSelected = 'range-date-selected',
  StepSelected = 'step-selected',
  DataReceived = 'data-received',
  ProgressCompleted = 'progress-completed',
}
