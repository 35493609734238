import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { BusService } from '../core/molecular/services/bus.service';
import { ApiMoleculesService } from '../core/services/api-molecules.service';
import { ApiPropertiesService } from '../core/services/api-properties.service';
import { ToolsService } from '../core/services/tools.service';
import { DraggableWindow } from '../shared/draggable-window';
import { PropertyVersioningDto } from '../shared/dtos/versioning-dto';
import { RepresentativeMoleculesType } from '../shared/enums/representative-molecules-types.enum';
import { View } from '../shared/interfaces/view';
import { CobbleNode } from '../shared/models/cobble-node';
import { CompanyLicense } from '../shared/models/company-license.model';
import { ParticleTrackFlow } from '../shared/models/particle-track-flow.model';
import { Bus } from '../shared/representative-molecule/interfaces/bus';
import { Particle } from '../shared/representative-molecule/interfaces/particle';
import { RepresentativeMolecule } from '../shared/representative-molecule/interfaces/representative-molecule';
import {
  IRepresentativeMolecule,
} from '../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../shared/services/communication.service';
import { DraggableWindowService } from '../shared/services/draggable-window.service';
import { SnackerService } from '../shared/services/snacker.service';
import { UnsavedDataApologyComponent } from './unsaved-data-apology/unsaved-data-apology.component';
import { UnsavedDataComponent } from './unsaved-data/unsaved-data.component';
import { TreeNode } from './views-panel/views-panel.component';

@Injectable({
  providedIn: 'root',
})
export class WorkAreaService implements OnDestroy {
  eventLoopData: {
    repMolecule: IRepresentativeMolecule;
    bus: Bus;
    particle: any;
  }[][] = [];
  // region DEV OPTIONS
  cacheEnabled = true;
  treesEnabled = true;
  disableAppsTree = true;
  libraryMoleculeIds = {};
  devOnlyAccess = false;
  // endregion
  
  loopTrack: ParticleTrackFlow = null;
  
  unsavedDataWindow: DraggableWindow = null;
  inDebounce = null;
  
  enableFocusMode = false;
  systemSectionOpen = false;
  apiSectionOpen = false;
  leapXLSectionOpen = false;
  customSectionOpen = false;
  spreadsheetsSectionOpen = false;
  databaseSectionOpen = false;
  unifiedDatabaseSectionOpen = false;
  localDataStoreSectionOpen = false;
  internetMessagingSectionOpen = false;
  focusRepresentativeMolecule: IRepresentativeMolecule = null;
  repMoleculeJustCreated = false;
  eventsPanelSectionsState = {
    leapxlCommunication: false,
    system: false,
    view: false,
    elements: false,
    custom: false,
  };
  
  eventsPanelSectionsSearchState = {
    leapxlCommunication: false,
    system: false,
    view: false,
    elements: false,
    custom: false,
  };
  
  arrowKeysMovementDisabled = false;
  public notificationMessage = '';
  public notificationUnsavedProperties = 0;
  public notificationSavedProperties = 0;
  public notificationUnsavedRemainingProperties = 0;
  public notificationButtonText = '';
  public showNotification = false;
  actualEditorViews: View[] = this.cobbleService ? [this.cobbleService.Cobble.properties.views[0]] : [];
  historyChanges: {
    moleculeId: number;
    // change: PropertyVersioningDto;
    versionId: number;
    active: boolean;
  }[] = [];
  leftPanelIndexTabSelected = 0;
  rightPanelIndexTabSelected = 0;
  isDragSelectionRunning = false;
  isDragSelectionInsideWorkgroup = false;
  exportAppWindow: DraggableWindow = null;
  importAppWindow: DraggableWindow = null;
  initialAssistPageWindow: DraggableWindow = null;
  stylePropertiesWindow: DraggableWindow = null;
  appThemeConfiguration: DraggableWindow = null;
  cloneWindow: DraggableWindow = null;
  appDetailsWindow: DraggableWindow = null;
  apiSetupDraggableWindow: DraggableWindow = null;
  unifiedDbSetupDraggableWindow: DraggableWindow = null;
  workareaOverlayActive = false;
  sizeStart: any;
  userOpenLibraryPanel = false;
  userOpenCobblePanel = true;
  runMobileTest = false;
  mobileLayoutPosition: any;
  centerMobileTest = false;
  loadMobileTest = false;
  mobileTestSrc: SafeResourceUrl;
  mobilePortrait = true;
  showMobileLayout = true;
  mobileLayoutHeight = 667;
  mobileLayoutWidth = 375;
  mobileWidthPadding = 24;
  mobileHeightPadding = 105;
  mobileEmulatorSize = 'normal';
  mobileDeviceTemplate = 'iphone XR';
  viewFocused: number;
  canvasAreaSize = 100;
  sheetAreaSize = 0;
  dataSourceRenameWindow: DraggableWindow;
  sheetNodeToRename: any;
  dataSourceRenameId: number;
  leftAreaSize = 14;
  rightAreaSize = 0;
  contentAreaSize = 86;
  recoveredWindow = null;
  tabManagement = {
    counter: 0,
    settingTab: false,
    repMoleculeInitiating: null,
  };
  contextMenuPosition = { event: null, x: '0px', y: '0px' };
  shadowBuses: Bus[] = [];
  smartTemplateWindow: DraggableWindow = null;
  userGuideWindow: DraggableWindow = null;
  devToolsWindow: DraggableWindow = null;
  userGuideId = null;
  userGuideType = null;
  lastDeviceTypeSelected = 'desktop';
  jsonTreeData = null;
  loadingOverlay: {
    iconAnimated: boolean;
    display: boolean;
    showSpinner: boolean;
    spinnerType: 'bar' | 'spin';
    message: string;
    icon: string;
    iconColor: string;
  } = {
    iconAnimated: true,
    display: false,
    showSpinner: true,
    spinnerType: 'spin',
    message: '',
    icon: '',
    iconColor: 'black',
  };
  busState = {};
  moleculeState = {};
  mostUsedMolecules = [];
  lastUsedElements: any = {};
  
  public subscriptions: Subscription;
  companiesCobbles = new BehaviorSubject<CobbleNode[]>([]);
  public collaborators: {
    initials: string;
    user: string;
    userId: number;
    data?: any;
  }[] = [];
  editorPreferences: {
    grid: boolean;
    moleculesMenu: boolean;
    autoDsHighlight: boolean;
    push: boolean;
    collaboratorsUpdates: boolean;
    guidelines: boolean;
    groupDrag: boolean;
    resizeAll: boolean;
    layoutsPanel: boolean;
    compactToolBar: boolean;
    compactToolBarPosition: string;
    toolbarPanel: boolean;
    mostUsedSection: boolean;
    searchAddParticlesOnBus: boolean;
    fixedContextMenu: boolean;
    expandedContextMenu: boolean;
    gridline: boolean;
    customGuideline: boolean;
    displayLockViewElements: boolean;
    dragRepMoleculesPreview: boolean;
    oneDatasourceOpenAtTime: boolean;
    busDiagnostic: boolean;
    displayElementsBounds: boolean;
    zoom: boolean;
    pinDS: boolean;
  } = {
    grid: true,
    moleculesMenu: true,
    push: false,
    busDiagnostic: true,
    guidelines: false,
    autoDsHighlight: false,
    displayLockViewElements: false,
    resizeAll: false,
    customGuideline: false,
    displayElementsBounds: true,
    compactToolBar: false,
    compactToolBarPosition: 'top',
    expandedContextMenu: false,
    oneDatasourceOpenAtTime: true,
    groupDrag: false,
    gridline: false,
    layoutsPanel: false,
    mostUsedSection: false,
    fixedContextMenu: false,
    dragRepMoleculesPreview: false,
    searchAddParticlesOnBus: false,
    collaboratorsUpdates: true,
    toolbarPanel: false,
    zoom: false,
    pinDS: false,
  };
  dragStartPositions = {};
  zoomLevel = 50;
  copyRepMolecule = null;
  cutRepMolecule = null;
  hideUnusedDataSources = false;
  hideNotOwnedDataSources = false;
  leftPanelIndexTab = 0;
  rightPanelIndexTab = 0;
  topBarTitle: string;
  activeCobble = new Subject<number>();
  loadingCobble = new Subject<number>();
  cobbleLoaded = new Subject<boolean>();
  fileUploader: any;
  cobbleNodes: CobbleNode[];
  elementClicked: IRepresentativeMolecule;
  elementsSelected: IRepresentativeMolecule[] = [];
  firstElementSelected: IRepresentativeMolecule = null;
  secondElementSelected: IRepresentativeMolecule = null;
  elementsSelectedOriginalPosition = {};
  elementsSelectedParticleAssociation = {};
  elementsSelectedShadowParticleAssociation = {};
  primaryElementsSelected: IRepresentativeMolecule[] = [];
  showElementFocusedMenu = false;
  renameElementFocusedMenu = false;
  elementFocusedNewName = '';
  elementFocusedMenuPosition = {
    x: 0,
    y: 0,
  };
  $cobbleCreated = new Subject<CobbleNode>();
  openingProgress = 0;
  progressBarMode = 'indeterminate';
  draggableWindow: DraggableWindow[] = [];
  windowHorizontalLayout = false;
  snapshots = {
    autoSave: true,
    saveFrequency: 10,
  };
  
  private loadSheetPanelSource = new Subject<File>();
  private sheetPanelDraggedSource = new Subject();
  sheetPanelDragged$ = this.sheetPanelDraggedSource.asObservable();
  private openSheetInPanelSource = new Subject<any>();
  
  constructor(
    private moleculesService: ApiMoleculesService,
    private busService: BusService,
    public sanitizer: DomSanitizer,
    private cobbleService: CobbleService,
    private propertiesService: ApiPropertiesService,
    private toolsService: ToolsService,
    private draggableWindowService: DraggableWindowService,
    private snackerService: SnackerService,
    private communicationService: CommunicationService,
    private angularZone: NgZone,
  ) {
    this.subscriptions = this.communicationService.Event.Editor.Views.$VisibilityChange.subscribe(
      (treeNode: TreeNode) => {
        console.log('=event=');
        // console.log('$VisibilityChange');
        
        const pushPreviousState = this.editorPreferences.push;
        if (this.editorPreferences.push) {
          this.editorPreferences.push = false;
          this.communicationService.Event.Editor.Preferences.$PreferenceChange.emit('push');
        }
        
        if (this.actualEditorViews.map(v => v.id)
        .includes(treeNode.id)) {
          this.actualEditorViews = this.actualEditorViews.filter(view => view.id !== treeNode.id);
        } else {
          // this.actualEditorViews.push(treeNode);
          this.actualEditorViews = [treeNode];
        }
        
        this.DeselectAllRepresentativeMolecules();
        this.HideElementFocusedMenu();
        this.HideDraggableWindows();
        
        // console.log('actual editor views', this.actualEditorViews);
        
        this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
        
        if (pushPreviousState) {
          setTimeout(() => {
            this.editorPreferences.push = true;
            this.communicationService.Event.Editor.Preferences.$PreferenceChange.emit('push');
          }, 300);
        }
      });
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$MoleculePropertyChange.subscribe((versioning: any[]) => {
        console.log('=event=');
        // console.log('$MoleculePropertyChange');
        const unactiveChanges = this.historyChanges.filter(c => !c.active)
        .map(i => i.versionId);
        
        this.historyChanges = this.historyChanges.filter(c => c.active);
        if (unactiveChanges.length > 0) {
          this.propertiesService.RemoveVersionedProperties(unactiveChanges)
          .subscribe();
        }
        
        versioning.forEach(v => {
          this.historyChanges.push(v);
        });
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.Saving.$BackendNotSavingState.subscribe(
        propertiesUnsavedCount => {
          console.log('=event=');
          // console.log('$BackendNotSavingState');
          this.ActivateWorkareaCriticState(propertiesUnsavedCount);
        }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$DeselectRepresentativeMolecule.subscribe(repMoleculeId => {
        console.log('=event=');
        this.DeselectRepresentativeMolecule(repMoleculeId.toString());
        if (
          this.primaryElementsSelected &&
          this.primaryElementsSelected.length === 1 &&
          (this.primaryElementsSelected[0].Id === repMoleculeId || this.busService.IsMyChild(repMoleculeId,
            this.primaryElementsSelected[0].Id))
        ) {
          this.HideElementFocusedMenu();
        }
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.Saving.$UnsavedRemainingProperties.subscribe(
        propertiesRemainingCount => {
          console.log('=event=');
          // console.log('$BackendNotSavingState');
          this.notificationUnsavedRemainingProperties = propertiesRemainingCount;
        }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.WorkArea.$ChangeTopBarPosition.subscribe(position => {
        console.log('=event=');
        this.ChangeTopBarPosition(position);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.WorkArea.$DraggableWindowClosed.subscribe(componentId => {
        console.log('=event=');
        this.draggableWindow = this.draggableWindow.filter(dw => dw.Id !== componentId);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.WorkArea.$ShowLoadingOverlay.subscribe((options: any) => {
        console.log('=event=');
        this.ShowLoadingOverlay(options);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.WorkArea.$HideLoadingOverlay.subscribe((options: any) => {
        console.log('=event=');
        setTimeout(() => {
          this.HideLoadingOverlay();
        }, 300);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.Saving.$AllDataSaved.subscribe(condition => {
        console.log('=event=');
        // console.log('$AllDataSaved');
        if (this.showNotification) {
          this.snackerService.ShowMessageOnBottom('All changes saved', 'save', null, true);
          this.HideUnsavedDataWindow();
          this.DeactivateWorkareaCriticState();
        }
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.System.Connection.$StateChange.subscribe(connection => {
        console.log('=event='); // console.log('$StateChange');
        if (connection) {
          this.UnblockEditor();
        } else {
          this.BlockEditor();
        }
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.WorkArea.$ShowFocusedMenu.subscribe(repMolecule => {
        console.log('=event=');
        this.ShowElementFocusedMenu(repMolecule);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.Views.$SwitchView.subscribe((viewId: number) => {
        console.log('=event=');
        this.HideElementFocusedMenu();
        this.SwitchToView(viewId);
      }),
    );
    
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(false);
  }
  
  get ActualView(): View {
    return this.actualEditorViews[0];
  }
  
  SwitchToView(viewId: number) {
    this.actualEditorViews = [this.cobbleService.Cobble.properties.views.find(v => v.id === viewId)];
    console.log('switch view');
    this.communicationService.Event.Editor.$ReloadApp.emit(true);
    this.communicationService.Event.Editor.Views.$RefreshUI.emit();
  }
  
  StartSettingTabOrder(repMoleculeInitiating: IRepresentativeMolecule, reset = false) {
    this.snackerService.ShowMessageOnBottom('Select element to set the order', 'checklist_rtl');
    const siblings = this.busService.GetSiblings(repMoleculeInitiating.Id.toString());
    let maxTab = 0;
    
    if (reset) {
      repMoleculeInitiating.Properties.tabindex = 0;
      this.propertiesService
      .SaveProperty(
        new PropertyVersioningDto({
          elementId: repMoleculeInitiating.Id.toString(),
          property: 'tabindex',
          value: repMoleculeInitiating.Properties.tabindex,
          path: 'properties',
          change: `Setting tab order`,
          name: repMoleculeInitiating.Properties.name,
        }),
      )
      .subscribe(r => {
      });
      siblings.forEach(s => {
        s.Properties.tabindex = 0;
        this.propertiesService
        .SaveProperty(
          new PropertyVersioningDto({
            elementId: s.Id.toString(),
            property: 'tabindex',
            value: s.Properties.tabindex,
            path: 'properties',
            change: `Setting tab order`,
            name: s.Properties.name,
          }),
        )
        .subscribe(r => {
        });
      });
    } else {
      const tabs = siblings.map(s => s.Properties.tabindex);
      maxTab = Math.max(...tabs);
    }
    
    this.tabManagement.settingTab = true;
    this.tabManagement.counter = maxTab;
    this.tabManagement.repMoleculeInitiating = repMoleculeInitiating;
    
    repMoleculeInitiating.ShowTabOrderDisplay();
    siblings.forEach(s => s.ShowTabOrderDisplay());
    document.querySelector(`#gridsterItem-${ repMoleculeInitiating.ParentId }`)
    .classList
    .add('setting-tab-order-molecule');
  }
  
  SetTabOrder(repMolecule: IRepresentativeMolecule) {
    if (!this.tabManagement.settingTab) {
      return;
    }
    
    if (repMolecule.ParentId !== this.tabManagement.repMoleculeInitiating.ParentId) {
      this.EndSettingTabOrder();
    }
    
    this.tabManagement.counter++;
    repMolecule.Properties.tabindex = this.tabManagement.counter;
    this.propertiesService
    .SaveProperty(
      new PropertyVersioningDto({
        elementId: repMolecule.Id.toString(),
        property: 'tabindex',
        value: repMolecule.Properties.tabindex,
        path: 'properties',
        change: `Setting tab order`,
        name: repMolecule.Properties.name,
      }),
    )
    .subscribe(r => {
    });
  }
  
  EndSettingTabOrder() {
    if (this.tabManagement.settingTab) {
      this.tabManagement.repMoleculeInitiating.HideTabOrderDisplay();
      const siblings = this.busService.GetSiblings(this.tabManagement.repMoleculeInitiating.Id.toString());
      siblings.forEach(s => s.HideTabOrderDisplay());
      document.querySelector(`#gridsterItem-${ this.tabManagement.repMoleculeInitiating.ParentId }`)
      .classList
      .remove('setting-tab-order-molecule');
      
      this.tabManagement.settingTab = false;
      this.tabManagement.counter = 0;
      this.tabManagement.repMoleculeInitiating = null;
      
      this.snackerService.ShowMessageOnBottom('Tab order set disable', 'keyboard_tab');
    }
  }
  
  ShowLoadingOverlay(options: {
    display: boolean;
    iconAnimated: boolean;
    showSpinner: boolean;
    spinnerType: 'bar' | 'spin';
    message: string;
    icon: string;
    iconColor: string;
  }) {
    console.log('loading');
    this.loadingOverlay = options;
    this.loadingOverlay.display = true;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.communicationService.Event.System.App.$RefreshUI.emit();
  }
  
  HideLoadingOverlay() {
    this.loadingOverlay.display = false;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.communicationService.Event.System.App.$RefreshUI.emit();
  }
  
  BlockEditor() {
    this.communicationService.Event.Editor.WorkArea.$ShowLoadingOverlay.emit({
      display: true,
      showSpinner: true,
      iconAnimated: true,
      spinnerType: 'bar',
      message: 'Saving Properties...',
      icon: 'save',
      iconColor: 'gray',
    });
    
    const floatingWindows = document.getElementsByClassName('floating-window') as any;
    
    if (floatingWindows.length > 0) {
      for (let i = 0; i < floatingWindows.length; i++) {
        const element = floatingWindows[i] as any;
        if (element) {
          element.classList.add('hide-floating-window');
        }
      }
    }
  }
  
  UnblockEditor() {
    setTimeout(() => {
      this.communicationService.Event.Editor.WorkArea.$HideLoadingOverlay.emit();
      const floatingWindows = document.getElementsByClassName('hide-floating-window') as any;
      
      if (floatingWindows.length > 0) {
        for (let i = 0; i < floatingWindows.length; i++) {
          const element = floatingWindows[i] as any;
          if (element) {
            element.classList.remove('hide-floating-window');
          }
        }
        for (let i = 0; i < floatingWindows.length; i++) {
          const element = floatingWindows[i] as any;
          if (element) {
            element.classList.remove('hide-floating-window');
          }
        }
        for (let i = 0; i < floatingWindows.length; i++) {
          const element = floatingWindows[i] as any;
          if (element) {
            element.classList.remove('hide-floating-window');
          }
        }
      }
    }, 200);
  }
  
  sheetPanelDragged() {
    this.sheetPanelDraggedSource.next();
  }
  
  RefreshMobileTestApp() {
    this.mobileTestSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${ this.toolsService.GetDomain() }/run/preview-mobile/${ this.cobbleService.Cobble.companySlug }/${ this.cobbleService.Cobble.slug }`,
    );
  }
  
  RunMobileTest(run = true, theme = 'light') {
    this.mobileTestSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${ this.toolsService.GetDomain() }/run/preview-mobile/${ this.cobbleService.Cobble.companySlug }/${ this.cobbleService.Cobble.slug }/${ theme }`,
    );
    
    if (run) {
      this.communicationService.Event.System.App.$RefreshUI.emit(true);
      this.centerMobileTest = run;
      setTimeout(() => {
        this.communicationService.Event.System.App.$RefreshUI.emit(true);
        
        const mobileLayoutBounds = document.getElementsByClassName(
          'mobileLayout')[0].getBoundingClientRect() as any;
        this.mobileLayoutPosition = {
          x: mobileLayoutBounds.x - this.mobileWidthPadding,
          y: mobileLayoutBounds.y - this.mobileHeightPadding * 1.5,
        };
        
        this.runMobileTest = run;
        setTimeout(() => {
          this.showMobileLayout = false;
          this.communicationService.Event.System.App.$RefreshUI.emit(true);
          
          setTimeout(() => {
            this.loadMobileTest = run;
            this.communicationService.Event.System.App.$RefreshUI.emit(true);
          }, 200);
        }, 200);
      }, 200);
    } else {
      this.showMobileLayout = true;
      this.loadMobileTest = run;
      this.communicationService.Event.System.App.$RefreshUI.emit(true);
      setTimeout(() => {
        this.centerMobileTest = run;
        this.communicationService.Event.System.App.$RefreshUI.emit(true);
        setTimeout(() => {
          this.runMobileTest = run;
          this.communicationService.Event.System.App.$RefreshUI.emit(true);
        }, 100);
      }, 100);
    }
  }
  
  getCompanyCobbles(company: CompanyLicense) {
    this.moleculesService.GetAppsByCompany(company.id)
    .subscribe(cobbles => {
      let k = 1;
      const cobbleNodes: CobbleNode[] = [];
      cobbleNodes.push({ id: 0, name: 'Companies', list: [], other: {} });
      cobbles.forEach(cobble => {
        k++;
        cobbleNodes[0].list.push(cobble);
      });
      this.companiesCobbles.next(cobbleNodes);
    });
  }
  
  ChangeTopBarPosition(position = 'top') {
    this.editorPreferences.compactToolBarPosition = position;
    
    if (this.editorPreferences.compactToolBar) {
      document.body.classList.remove('top-toolbar');
      document.body.classList.remove('right-side-toolbar');
      document.body.classList.remove('left-side-toolbar');
      
      document.body.classList.add('compact');
      
      let topbarPositionClass = '';
      switch (this.editorPreferences.compactToolBarPosition) {
        case 'top':
          topbarPositionClass = 'top-toolbar';
          break;
        case 'right':
          topbarPositionClass = 'right-side-toolbar';
          break;
        case 'left':
          topbarPositionClass = 'left-side-toolbar';
          break;
        
        default:
          break;
      }
      
      document.body.classList.add(topbarPositionClass);
    } else {
      document.body.classList.add('top-toolbar');
      document.body.classList.remove('compact');
    }
  }
  
  loadExcelFileIntoPanel(file: File) {
    this.loadSheetPanelSource.next(file);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  openExcelDataSource(sheetIdx, book) {
    this.openSheetInPanelSource.next({ sheetIdx: sheetIdx, book: book });
  }
  
  getDocumentOffsetPosition(el) {
    const position = {
      top: el.offsetTop,
      left: el.offsetLeft,
    };
    if (el.offsetParent) {
      const parentPosition = this.getDocumentOffsetPosition(el.offsetParent);
      position.top += parentPosition.top;
      position.left += parentPosition.left;
    }
    return position;
  }
  
  AdjustElementFocusedMenuPosition(repMolecule?: IRepresentativeMolecule, event?: MouseEvent) {
    // console.log('AdjustElementFocusedMenuPosition');
    
    if (this.editorPreferences.fixedContextMenu && !this.repMoleculeJustCreated) {
      return;
    }
    
    const element = repMolecule || this.primaryElementsSelected[0] || null;
    const ctrlKey = event ? event.ctrlKey || event.metaKey || false : false;
    
    if (element) {
      const htmlElement = document.querySelector(`#gridsterItem-${ element.Id }`);
      
      if (htmlElement) {
        const elementBounding = htmlElement.getBoundingClientRect();
        this.elementFocusedMenuPosition.y = elementBounding.top + document.querySelector(
          '.work-area-content').scrollTop - 100;
        this.elementFocusedMenuPosition.x =
          elementBounding.left +
          document.querySelector('.work-area-content').scrollLeft -
          document.querySelector('#leftSplitArea')
          .getBoundingClientRect().width -
          10;
      }
      
      // const noParent = element.ParentId === this.cobbleService.Cobble.id;
      //
      // event = noParent ? undefined : event;
      // this.elementFocusedMenuPosition = element.GetPosition(
      //   event,
      //   this.leftAreaSize > 0
      // );
      //
      // if (!noParent) {
      //   const wgParent = this.busService.Get(element.ParentId.toString());
      //   const parentPosition = wgParent.GetPosition(
      //     event,
      //     this.leftAreaSize > 0
      //   );
      //
      //   // console.log('parent', parentPosition);
      //   // console.log('event', event);
      //   // console.log('this.leftAreaSize', this.leftAreaSize);
      //
      //   if (element.SubParentId > 0) {
      //     if (event) {
      //       this.elementFocusedMenuPosition.y = event.layerY + parentPosition.y;
      //       if (this.leftAreaSize > 0) {
      //         this.elementFocusedMenuPosition.x = event.clientX - 360;
      //       }
      //     }
      //   } else {
      //     this.elementFocusedMenuPosition.x = event
      //       ? event.layerX - 110
      //       : this.elementFocusedMenuPosition.x + parentPosition.x;
      //     this.elementFocusedMenuPosition.y =
      //       this.elementFocusedMenuPosition.y + parentPosition.y - 10;
      //   }
      // }
      
      if (!ctrlKey) {
        document.getElementById('elementsContextMenu').style.transform = `translate(${
          window.innerWidth - this.elementFocusedMenuPosition.x < 150
            ? this.elementFocusedMenuPosition.x - 220
            : this.elementFocusedMenuPosition.x < 0
              ? 20
              : this.elementFocusedMenuPosition.x
        }px, ${ this.elementFocusedMenuPosition.y < 20 ? 30 : this.elementFocusedMenuPosition.y }px)`;
      }
    }
  }
  
  HideDraggableWindows() {
    this.draggableWindow.forEach(dw => {
      dw.Hide();
    });
    this.draggableWindow = [];
  }
  
  ShowElementFocusedMenu(element?: IRepresentativeMolecule, event?: any, renameElement = false) {
    console.log('ShowElementFocusedMenu');
    this.renameElementFocusedMenu = false;
    const ctrlKey = event ? event.ctrlKey || event.metaKey || false : false;
    
    if (!ctrlKey) {
      this.DeselectAllRepresentativeMolecules();
    }
    
    if (element) {
      this.elementFocusedNewName = element.Properties.name;
      this.communicationService.Event.System.App.$RefreshUI.emit();
      this.SelectRepresentativeMolecule(element, ctrlKey);
      this.AdjustElementFocusedMenuPosition(element, event);
      this.showElementFocusedMenu = true;
      if (renameElement) {
        setTimeout(
          () => {
            this.communicationService.Event.Editor.$RenameRepMolecule.emit(true);
          },
          element.Type === RepresentativeMoleculesType.WorkGroup ? 100 : 200,
        );
      } else {
        this.communicationService.Event.Editor.$CancelRepMoleculeRename.emit(true);
      }
    }
  }
  
  HideElementFocusedMenu(deselectAllRepMolecules = true) {
    // console.log('hiding menu');
    
    if (deselectAllRepMolecules) {
      document.querySelectorAll(`.element-selected`)
      .forEach(selectedElement => {
        selectedElement.classList.remove('element-selected');
      });
      this.DeselectAllRepresentativeMolecules();
    }
    this.showElementFocusedMenu = false;
    this.communicationService.Event.System.App.$RefreshUI.emit(true);
    this.communicationService.Event.Editor.Views.$RefreshViewsPanelUI.emit(true);
  }
  
  ElementsToPushWest(element: IRepresentativeMolecule, substractValue: number): IRepresentativeMolecule[] {
    const elementsFound = [];
    
    this.busService.DirectChildrenElements(element.ParentId)
    .forEach(m => {
      if (
        ((m.ResponsiveProperties().y + m.ResponsiveProperties().rows - 2 >= element.ResponsiveProperties().y &&
            m.ResponsiveProperties().y + m.ResponsiveProperties().rows <= element.ResponsiveProperties().y + element.ResponsiveProperties().rows) ||
          (m.ResponsiveProperties().y >= element.ResponsiveProperties().y &&
            m.ResponsiveProperties().y <= element.ResponsiveProperties().y + element.ResponsiveProperties().rows - 2) ||
          (m.ResponsiveProperties().y <= element.ResponsiveProperties().y &&
            m.ResponsiveProperties().y + m.ResponsiveProperties().rows >= element.ResponsiveProperties().y + element.ResponsiveProperties().rows) ||
          (m.ResponsiveProperties().y >= element.ResponsiveProperties().y &&
            m.ResponsiveProperties().y + m.ResponsiveProperties().rows <= element.ResponsiveProperties().y + element.ResponsiveProperties().rows)) &&
        m.ResponsiveProperties().x + m.ResponsiveProperties().cols >= element.ResponsiveProperties().x - substractValue &&
        m.ResponsiveProperties().x + m.ResponsiveProperties().cols < element.ResponsiveProperties().x + element.ResponsiveProperties().cols &&
        element.Id !== m.Id
      ) {
        elementsFound.push(m);
      }
    });
    
    return elementsFound;
  }
  
  ElementsToPushNorth(element: IRepresentativeMolecule, substractValue: number): IRepresentativeMolecule[] {
    const elementsFound = [];
    
    this.busService.DirectChildrenElements(element.ParentId)
    .forEach(m => {
      if (
        ((m.ResponsiveProperties().x + m.ResponsiveProperties().cols - 2 >= element.ResponsiveProperties().x &&
            m.ResponsiveProperties().x + m.ResponsiveProperties().cols <= element.ResponsiveProperties().x + element.ResponsiveProperties().cols) ||
          (m.ResponsiveProperties().x >= element.ResponsiveProperties().x &&
            m.ResponsiveProperties().x <= element.ResponsiveProperties().x + element.ResponsiveProperties().cols - 2) ||
          (m.ResponsiveProperties().x <= element.ResponsiveProperties().x &&
            m.ResponsiveProperties().x + m.ResponsiveProperties().cols >= element.ResponsiveProperties().x + element.ResponsiveProperties().cols) ||
          (m.ResponsiveProperties().x >= element.ResponsiveProperties().x &&
            m.ResponsiveProperties().x + m.ResponsiveProperties().cols <= element.ResponsiveProperties().x + element.ResponsiveProperties().cols)) &&
        m.ResponsiveProperties().y + m.ResponsiveProperties().rows >= element.ResponsiveProperties().y - substractValue &&
        m.ResponsiveProperties().y + m.ResponsiveProperties().rows < element.ResponsiveProperties().y + element.ResponsiveProperties().rows &&
        element.Id !== m.Id
      ) {
        elementsFound.push(m);
      }
    });
    
    return elementsFound;
  }
  
  GetChainedElementsNorth(element: IRepresentativeMolecule, substractValue = 0): IRepresentativeMolecule[] {
    const elementsWest = this.ElementsToPushNorth(element, substractValue);
    
    let elementsChained = [];
    
    if (elementsWest.length > 0) {
      for (let i = 0; i < elementsWest.length; i++) {
        const elementWest = elementsWest[i];
        
        const elementsFound = elementsWest.concat(this.GetChainedElementsNorth(elementWest, substractValue));
        
        elementsChained = elementsChained.concat(elementsFound);
      }
      
      return elementsChained;
    } else {
      return [element];
    }
  }
  
  GetChainedElementsWest(element: IRepresentativeMolecule, substractValue = 0): IRepresentativeMolecule[] {
    const elementsWest = this.ElementsToPushWest(element, substractValue);
    
    let elementsChained = [];
    
    if (elementsWest.length > 0) {
      for (let i = 0; i < elementsWest.length; i++) {
        const elementWest = elementsWest[i];
        
        const elementsFound = elementsWest.concat(this.GetChainedElementsWest(elementWest, substractValue));
        
        elementsChained = elementsChained.concat(elementsFound);
      }
      
      return elementsChained;
    } else {
      return [element];
    }
  }
  
  ResizeWorkgroupWidth(workgroupId: number) {
    const molecule = this.busService.Get(workgroupId.toString());
    const newWgCols = molecule.ResponsiveProperties().cols;
    const propertiesToSave = [];
    
    if (!this.editorPreferences.resizeAll) {
      const elementsToSave = [];
      const colsContracted = this.sizeStart.cols - newWgCols;
      // console.log('cols contracted', colsContracted);
      
      if (colsContracted > 0) {
        const startElementsOnEdge = [];
        for (let i = 1; i <= colsContracted; i++) {
          const elementsResized = this.busService
          .DirectChildrenElements(molecule.Id)
          .filter(e => e.ResponsiveProperties().x + e.ResponsiveProperties().cols > newWgCols)
          .sort((a, b) =>
            a.ResponsiveProperties().x + a.ResponsiveProperties().cols > b.ResponsiveProperties().x + b.ResponsiveProperties().cols ? -1 : 1,
          );
          
          // console.log('elements affected', elementsResized);
          
          const startElements = [];
          elementsResized.forEach(e => {
            const elementsToRight = [];
            
            elementsResized.forEach(eCompare => {
              const yFinal = eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows;
              const yStart = eCompare.ResponsiveProperties().y;
              
              if (
                eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols >
                e.ResponsiveProperties().x + e.ResponsiveProperties().cols &&
                ((yFinal >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                  (yStart >= e.ResponsiveProperties().y && yStart <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                  (yStart <= e.ResponsiveProperties().y && yFinal >= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
                  (yStart >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows))
              ) {
                elementsToRight.push(eCompare);
              }
            });
            
            if (elementsToRight.length === 0) {
              startElements.push(e);
            }
          });
          
          // console.log('start elements', startElements);
          
          startElements.forEach(startElement => {
            startElementsOnEdge.push(startElement);
            let elementsChained = [...new Set([startElement].concat(
              this.GetChainedElementsWest(startElement, 1)))] as IRepresentativeMolecule[];
            
            // console.log('elements on chain', elementsChained);
            
            if (elementsChained.filter(e => e.ResponsiveProperties().x < 1).length > 0) {
              // console.log('shrink chain');
              
              elementsChained = elementsChained.sort(
                (a, b) => (a.ResponsiveProperties().x > b.ResponsiveProperties().x ? 1 : -1));
              
              elementsChained.forEach(elementToShrink => {
                const elementToShrinkPositioning = elementToShrink.ResponsiveProperties();
                
                const colsDifference =
                  elementToShrinkPositioning.cols - (elementToShrinkPositioning.cols * (this.sizeStart.cols - i)) / (this.sizeStart.cols - i + 1);
                
                const leftElements = this.GetLeftElements(elementToShrink)
                .sort((a, b) =>
                  a.ResponsiveProperties().x + a.ResponsiveProperties().cols > b.ResponsiveProperties().x + b.ResponsiveProperties().cols ? 1 : -1,
                );
                
                const isAnyOfChainedElementsForElementOnEdge =
                  this.GetChainedElementsWest(elementToShrink, 2)
                  .filter(e => e.ResponsiveProperties().x < 1).length > 0;
                
                if (isAnyOfChainedElementsForElementOnEdge || elementToShrinkPositioning.x <= 1) {
                  // =========setting cols for shrinked elments
                  elementToShrinkPositioning.cols = Math.floor(
                    elementToShrinkPositioning.cols - colsDifference);
                  // ==========================================
                  
                  if (leftElements.length > 0) {
                    const overlapingme =
                      leftElements[leftElements.length - 1].ResponsiveProperties().x +
                      leftElements[leftElements.length - 1].ResponsiveProperties().cols >
                      elementToShrinkPositioning.x + 1;
                    if (overlapingme) {
                      const newX = (elementToShrinkPositioning.x * (this.sizeStart.cols - i)) / (this.sizeStart.cols - i + 1);
                      // console.log('diff', newX);
                      elementToShrinkPositioning.x = Math.round(newX);
                    } else {
                      elementToShrinkPositioning.x = Math.floor(
                        leftElements[leftElements.length - 1].ResponsiveProperties().x +
                        leftElements[leftElements.length - 1].ResponsiveProperties().cols,
                      );
                    }
                  } else {
                    elementToShrinkPositioning.x = 0;
                  }
                } else {
                  elementToShrinkPositioning.x = elementToShrinkPositioning.x - 1;
                }
                elementToShrinkPositioning.x = elementToShrinkPositioning.x < 0 ? 0 : elementToShrinkPositioning.x;
                
                if (elementsToSave.filter(ets => ets.Id === elementToShrink.Id).length === 0) {
                  elementsToSave.push(elementToShrink);
                }
              });
            } else {
              elementsChained.forEach(e => {
                if (elementsToSave.filter(ets => ets.Id === e.Id).length === 0) {
                  elementsToSave.push(e);
                }
                
                e.ResponsiveProperties().x = e.ResponsiveProperties().x - 1;
              });
              
              // console.log('push chain');
            }
          });
        }
        
        startElementsOnEdge.forEach((see: IRepresentativeMolecule) => {
          if (see.ResponsiveProperties().x + see.ResponsiveProperties().cols + 1 >= newWgCols) {
            see.ResponsiveProperties().cols = newWgCols - see.ResponsiveProperties().x;
          }
        });
        
        elementsToSave.forEach((e: IRepresentativeMolecule) => {
          propertiesToSave.push(
            new PropertyVersioningDto({
              elementId: e.Id.toString(),
              property: 'cols',
              value: e.ResponsiveProperties().cols,
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
              change: `Size Changed`,
              name: 'Width',
            }),
          );
          propertiesToSave.push(
            new PropertyVersioningDto({
              elementId: e.Id.toString(),
              property: 'x',
              value: e.ResponsiveProperties().x,
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
              change: `Position Changed`,
              name: 'Position X',
            }),
          );
        });
      }
    } else {
      this.ShrinkElementsHorizontally(this.busService.DirectChildrenElements(molecule.Id), molecule,
        newWgCols);
    }
    
    this.propertiesService.SaveProperties(propertiesToSave)
    .subscribe();
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(molecule.Id);
  }
  
  ResizeWorkgroupHeight(workgroupId: number) {
    const molecule = this.busService.Get(workgroupId.toString());
    const newWgRows = molecule.ResponsiveProperties().rows;
    const propertiesToSave = [];
    
    if (!this.editorPreferences.resizeAll) {
      const elementsToSave = [];
      const rowsContracted = this.sizeStart.cols - newWgRows;
      
      if (rowsContracted > 0) {
        for (let i = 1; i <= rowsContracted; i++) {
          const elementsResized = this.busService
          .DirectChildrenElements(molecule.Id)
          .filter(e => e.ResponsiveProperties().y + e.ResponsiveProperties().rows > newWgRows)
          .sort((a, b) =>
            a.ResponsiveProperties().y + a.ResponsiveProperties().rows > b.ResponsiveProperties().y + b.ResponsiveProperties().rows ? -1 : 1,
          );
          
          // console.log('elements affected', elementsResized);
          
          const startElements = [];
          elementsResized.forEach(e => {
            const elementsToRight = [];
            
            elementsResized.forEach(eCompare => {
              const xFinal = eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols;
              const xStart = eCompare.ResponsiveProperties().x;
              
              if (
                eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows >
                e.ResponsiveProperties().y + e.ResponsiveProperties().rows &&
                ((xFinal >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                  (xStart >= e.ResponsiveProperties().x && xStart <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                  (xStart <= e.ResponsiveProperties().x && xFinal >= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
                  (xStart >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols))
              ) {
                elementsToRight.push(eCompare);
              }
            });
            
            if (elementsToRight.length === 0) {
              startElements.push(e);
            }
          });
          
          // console.log('start elements', startElements);
          
          startElements.forEach(startElement => {
            let elementsChained = [...new Set([startElement].concat(
              this.GetChainedElementsNorth(startElement, 1)))] as IRepresentativeMolecule[];
            
            // console.log('elements on chain', elementsChained);
            
            if (elementsChained.filter(e => e.ResponsiveProperties().y < 1).length > 0) {
              // console.log('shrink chain');
              
              elementsChained = elementsChained.sort(
                (a, b) => (a.ResponsiveProperties().y > b.ResponsiveProperties().y ? 1 : -1));
              
              elementsChained.forEach(elementToShrink => {
                const elementToShrinkPositioning = elementToShrink.ResponsiveProperties();
                
                const rowsDifference =
                  elementToShrinkPositioning.rows - (elementToShrinkPositioning.rows * (this.sizeStart.rows - i)) / (this.sizeStart.rows - i + 1);
                
                const northElements = this.GetNorthElements(elementToShrink)
                .sort((a, b) =>
                  a.ResponsiveProperties().y + a.ResponsiveProperties().rows > b.ResponsiveProperties().y + b.ResponsiveProperties().rows ? 1 : -1,
                );
                
                const isAnyOfChainedElementsForElementOnEdge =
                  this.GetChainedElementsNorth(elementToShrink, 2)
                  .filter(e => e.ResponsiveProperties().y < 1).length > 0;
                
                if (isAnyOfChainedElementsForElementOnEdge || elementToShrinkPositioning.y <= 1) {
                  // =========setting rows for shrinked elments
                  elementToShrinkPositioning.rows = Math.floor(
                    elementToShrinkPositioning.rows - rowsDifference);
                  // ========================================== here
                  
                  if (northElements.length > 0) {
                    const overlapingme =
                      northElements[northElements.length - 1].ResponsiveProperties().y +
                      northElements[northElements.length - 1].ResponsiveProperties().rows >
                      elementToShrinkPositioning.y + 1;
                    if (overlapingme) {
                      const newY = (elementToShrinkPositioning.y * (this.sizeStart.rows - i)) / (this.sizeStart.rows - i + 1);
                      // console.log('diff', newY);
                      elementToShrinkPositioning.y = Math.round(newY);
                    } else {
                      elementToShrinkPositioning.y = Math.floor(
                        northElements[northElements.length - 1].ResponsiveProperties().y +
                        northElements[northElements.length - 1].ResponsiveProperties().rows,
                      );
                    }
                  } else {
                    elementToShrinkPositioning.y = 0;
                  }
                } else {
                  elementToShrinkPositioning.y = elementToShrinkPositioning.y - 1;
                }
                elementToShrinkPositioning.y = elementToShrinkPositioning.y < 0 ? 0 : elementToShrinkPositioning.y;
                
                if (elementsToSave.filter(ets => ets.Id === elementToShrink.Id).length === 0) {
                  elementsToSave.push(elementToShrink);
                }
              });
            } else {
              elementsChained.forEach(e => {
                if (elementsToSave.filter(ets => ets.Id === e.Id).length === 0) {
                  elementsToSave.push(e);
                }
                
                e.ResponsiveProperties().y = e.ResponsiveProperties().y - 1;
              });
              
              // console.log('push chain');
            }
          });
        }
        
        elementsToSave.forEach((e: IRepresentativeMolecule) => {
          propertiesToSave.push(
            new PropertyVersioningDto({
              elementId: e.Id.toString(),
              property: 'rows',
              value: e.ResponsiveProperties().rows,
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
              change: `Size Changed`,
              name: 'Height',
            }),
          );
          propertiesToSave.push(
            new PropertyVersioningDto({
              elementId: e.Id.toString(),
              property: 'y',
              value: e.ResponsiveProperties().y,
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
              change: `Position Changed`,
              name: 'Position Y',
            }),
          );
        });
      }
    } else {
      this.ShrinkElementsVertically(this.busService.DirectChildrenElements(molecule.Id), molecule, newWgRows);
    }
    
    this.propertiesService.SaveProperties(propertiesToSave)
    .subscribe();
    this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(molecule.Id);
  }
  
  ShrinkElementsHorizontally(elements: IRepresentativeMolecule[], parent: IRepresentativeMolecule, parentCols: number) {
    const propertiesToSave = [];
    
    elements.forEach(child => {
      let gridRatio = 1;
      
      if (this.sizeStart.cols !== parent.ResponsiveProperties().colsQty) {
        // console.log('wg cols b', this.sizeStart.cols);
        
        gridRatio = this.sizeStart.cols / parent.ResponsiveProperties().colsQty;
      }
      
      // console.log('grid ratio', gridRatio);
      
      let ratioColsChange = ((child.ResponsiveProperties().cols * parentCols) / this.sizeStart.cols) * gridRatio;
      let ratioXChange = ((child.ResponsiveProperties().x * parentCols) / this.sizeStart.cols) * gridRatio;
      // console.log('ratio cols change', ratioColsChange);
      // console.log('ratio x change', ratioXChange);
      
      if (Math.round(ratioColsChange) + Math.round(ratioXChange) > parentCols) {
        ratioColsChange = Math.floor(ratioColsChange);
        ratioXChange = Math.floor(ratioXChange);
      } else {
        ratioColsChange = Math.round(ratioColsChange);
        ratioXChange = Math.round(ratioXChange);
      }
      
      ratioColsChange = ratioColsChange < 1 ? 1 : ratioColsChange;
      ratioXChange = ratioXChange < 0 ? 0 : ratioXChange;
      
      child.ResponsiveProperties().cols = ratioColsChange;
      child.ResponsiveProperties().x = ratioXChange;
      
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'x',
          value: child.ResponsiveProperties().x,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Position Changed`,
          name: 'Position X',
        }),
      );
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'cols',
          value: child.ResponsiveProperties().cols,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Size Changed`,
          name: 'Width',
        }),
      );
    });
    
    this.propertiesService.SaveProperties(propertiesToSave)
    .subscribe();
  }
  
  ShrinkElementsVertically(elements: IRepresentativeMolecule[], parent: IRepresentativeMolecule, parentRows: number) {
    const propertiesToSave = [];
    
    elements.forEach(child => {
      let gridRatio = 1;
      
      if (this.sizeStart.rows !== parent.ResponsiveProperties().rowsQty) {
        // console.log('wg cols b', this.sizeStart.rows);
        
        gridRatio = this.sizeStart.rows / parent.ResponsiveProperties().rowsQty;
      }
      
      // console.log('grid ratio', gridRatio);
      
      let ratioRowsChange = ((child.ResponsiveProperties().rows * parentRows) / this.sizeStart.rows) * gridRatio;
      let ratioYChange = ((child.ResponsiveProperties().y * parentRows) / this.sizeStart.rows) * gridRatio;
      
      if (Math.round(ratioRowsChange) + Math.round(ratioYChange) > parentRows) {
        ratioRowsChange = Math.floor(ratioRowsChange);
        ratioYChange = Math.floor(ratioYChange);
      } else {
        ratioRowsChange = Math.round(ratioRowsChange);
        ratioYChange = Math.round(ratioYChange);
      }
      
      ratioRowsChange = ratioRowsChange < 1 ? 1 : ratioRowsChange;
      ratioYChange = ratioYChange < 0 ? 0 : ratioYChange;
      
      child.ResponsiveProperties().rows = ratioRowsChange;
      child.ResponsiveProperties().y = ratioYChange;
      
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'y',
          value: child.ResponsiveProperties().y,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Position Changed`,
          name: 'Position Y',
        }),
      );
      propertiesToSave.push(
        new PropertyVersioningDto({
          elementId: child.Id.toString(),
          property: 'rows',
          value: child.ResponsiveProperties().rows,
          path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
          change: `Size Changed`,
          name: 'Height',
        }),
      );
    });
    
    this.propertiesService.SaveProperties(propertiesToSave)
    .subscribe();
  }
  
  GetLeftElements(e: IRepresentativeMolecule) {
    const elementsFound = [];
    this.busService.DirectChildrenElements(e.ParentId)
    .forEach(eCompare => {
      const yFinal = eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows;
      const yStart = eCompare.ResponsiveProperties().y;
      
      if (
        ((yFinal >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart >= e.ResponsiveProperties().y && yStart <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart <= e.ResponsiveProperties().y && yFinal >= e.ResponsiveProperties().y + e.ResponsiveProperties().rows) ||
          (yStart >= e.ResponsiveProperties().y && yFinal <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows)) &&
        eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols &&
        eCompare.Id !== e.Id
      ) {
        // console.log('Elements found', eCompare);
        elementsFound.push(eCompare);
      }
    });
    
    return elementsFound;
  }
  
  GetNorthElements(e: IRepresentativeMolecule) {
    const elementsFound = [];
    this.busService.DirectChildrenElements(e.ParentId)
    .forEach(eCompare => {
      const xFinal = eCompare.ResponsiveProperties().x + eCompare.ResponsiveProperties().cols;
      const xStart = eCompare.ResponsiveProperties().x;
      
      if (
        ((xFinal >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart >= e.ResponsiveProperties().x && xStart <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart <= e.ResponsiveProperties().x && xFinal >= e.ResponsiveProperties().x + e.ResponsiveProperties().cols) ||
          (xStart >= e.ResponsiveProperties().x && xFinal <= e.ResponsiveProperties().x + e.ResponsiveProperties().cols)) &&
        eCompare.ResponsiveProperties().y + eCompare.ResponsiveProperties().rows <= e.ResponsiveProperties().y + e.ResponsiveProperties().rows &&
        eCompare.Id !== e.Id
      ) {
        // console.log('Elements found', eCompare);
        elementsFound.push(eCompare);
      }
    });
    
    return elementsFound;
  }
  
  CanPositionElement(
    moleculeId: number,
    position: {
      x: number;
      y: number;
      rows: number;
      cols: number;
    },
    parentId = null,
  ) {
    const element = this.busService.Get(moleculeId.toString());
    let parent = null;
    
    if (element) {
      // exists
    } else {
      return false;
    }
    
    if (element.Type === RepresentativeMoleculesType.WorkGroup) {
      parent = this.busService.Get(this.cobbleService.Cobble.id.toString());
      parent.Properties.responsive = {
        desktop: {
          cols: 1000,
          rows: 1000,
        },
        smartphone: {
          cols: 1000,
          rows: 1000,
        },
      };
    } else {
      parent = this.busService.Get(parentId ? parentId.toString() : element.ParentId.toString());
    }
    
    if (position.x < 0 || position.y < 0 || position.cols < 1 || position.rows < 1) {
      return false;
    }
    
    if (position.x + position.cols > parent.ResponsiveProperties().cols || position.x + position.cols < 0) {
      return false;
    }
    
    if (position.y + position.rows > parent.ResponsiveProperties().rows || position.y + position.rows < 0) {
      return false;
    }
    
    return true;
  }
  
  ShowUnsavedDataWindow() {
    if (!this.propertiesService.SavingInProcess()) {
      this.snackerService.ShowMessageOnBottom('All changes saved', 'save', null, true);
      return;
    }
    
    if (this.unsavedDataWindow) {
      this.unsavedDataWindow.Hide();
    }
    
    this.toolsService.DragWindowConfig = {
      changeLayout: false,
      x: 500,
      y: 50,
      width: 800,
      height: 600,
      icon: 'save',
    };
    
    this.toolsService.mousePosition = {
      x: window.innerWidth / 2 - 227.5 - 100,
      y: window.innerHeight / 2 - 150,
    };
    
    this.unsavedDataWindow = this.draggableWindowService.GenerateWindow(UnsavedDataComponent, {
      title: `Unsaved Data for ${ this.cobbleService.Cobble.properties.name }`,
      data: null,
    });
    
    this.unsavedDataWindow.Show();
  }
  
  HideUnsavedDataWindow() {
    if (this.unsavedDataWindow) {
      this.unsavedDataWindow.Hide();
      this.unsavedDataWindow = null;
    }
  }
  
  ShowUnsavedApologyWindow() {
    this.toolsService.mousePosition = {
      x: window.innerWidth / 2 - 227.5 - 100,
      y: window.innerHeight / 2 - 200,
    };
    
    if (this.recoveredWindow) {
      this.recoveredWindow.Hide();
    } else {
      this.recoveredWindow = this.draggableWindowService.GenerateWindow(UnsavedDataApologyComponent, {
        title: ``,
        data: null,
      });
    }
    this.recoveredWindow.Show();
  }
  
  HideUnsavedApologyWindow() {
    if (this.recoveredWindow) {
      this.recoveredWindow.Hide();
      this.recoveredWindow = null;
    }
  }
  
  ShowNotification(message: string, buttonText: string) {
    this.notificationMessage = message;
    this.notificationButtonText = buttonText;
    this.showNotification = true;
  }
  
  HideNotification() {
    this.notificationMessage = '';
    this.notificationButtonText = '';
    this.showNotification = false;
  }
  
  ActivateWorkareaCriticState(propertiesUnsavedCount = 0) {
    this.notificationUnsavedProperties = propertiesUnsavedCount;
    this.notificationUnsavedRemainingProperties = propertiesUnsavedCount;
    this.BlockEditor();
    this.ShowNotification(
      'Communication between your machine and the sever has slowed. There may be a slowdown in our server, the web or your network. This page is attempting to recover. Notice: there may be unsaved changes in your work. Click UNSAVED CHANGES below to see the potential loss of work from leaving or refreshing the page',
      'Unsaved Changes',
    );
  }
  
  DeactivateWorkareaCriticState() {
    this.UnblockEditor();
    this.HideNotification();
  }
  
  SetUnsavedElementsIndicators() {
    this.propertiesService.GetUnsavedProperties()
    .forEach(up => {
      const htmlElement = document.getElementById(`gridsterItem-${ up.elementId }`);
      htmlElement.classList.remove('critic-border-busy-indicator');
      htmlElement.classList.remove('border-busy-indicator');
      htmlElement.classList.remove('fade');
      htmlElement.classList.add('warning-border');
    });
  }
  
  SwitchLeftPanelTab(tabIndex: number) {
    this.leftPanelIndexTab = tabIndex;
  }
  
  SelectRepresentativeMolecule(repMolecule: IRepresentativeMolecule, add = false, keepFirstSecondPriority = false) {
    if (this.elementsSelected.length === 0 || !add) {
      console.log('set');
      this.elementsSelected.forEach(es => {
        es.Deselect();
      });
      
      if (!keepFirstSecondPriority) {
        this.secondElementSelected = null;
        this.firstElementSelected = repMolecule;
      }
      
      this.elementsSelected = [repMolecule];
      this.primaryElementsSelected = [repMolecule];
      repMolecule.Select();
    } else {
      // console.log('add');
      const selected = this.elementsSelected.find(es => es.Id === repMolecule.Id);
      if (!selected) {
        if (!keepFirstSecondPriority) {
          if (this.elementsSelected.length === 0) {
            this.firstElementSelected = repMolecule;
          } else if (this.elementsSelected.length === 1) {
            this.secondElementSelected = repMolecule;
          }
        }
        
        this.elementsSelected.push(repMolecule);
        const primary = this.primaryElementsSelected.find(rm => rm.Type === repMolecule.Type);
        if (!primary) {
          this.primaryElementsSelected.push(repMolecule);
        }
        repMolecule.Select();
      } else {
        if (add || (!add && this.isDragSelectionRunning)) {
          this.DeselectRepresentativeMolecule(selected.Id.toString());
        }
      }
    }
    
    if (this.elementsSelected.length <= 1) {
      this.communicationService.Event.Editor.DataSource.$RemoveDataSourceHighlight.emit();
      this.communicationService.Event.Editor.EventsTree.$RemoveHighlight.emit();
    }
    
    setTimeout(() => {
      this.elementsSelectedOriginalPosition = {};
      this.elementsSelected.forEach(es => {
        const htmlElement = document.querySelector(`#gridsterItem-${ es.Id }`) as any;
        
        if (htmlElement) {
          htmlElement.classList.add('element-selected');
          
          if (!this.isDragSelectionRunning) {
            // console.log('updates');
            this.angularZone.runOutsideAngular(() => {
              if (this.editorPreferences.autoDsHighlight) {
                repMolecule.HighlightDatasourcesPath();
              }
              repMolecule.HighlightEventsPath();
              repMolecule.HighlightViewsPath();
            });
          }
        }
        
        this.angularZone.runOutsideAngular(() => {
          const position = {
            x: es.ResponsiveProperties().x,
            y: es.ResponsiveProperties().y,
            cols: es.ResponsiveProperties().cols,
            rows: es.ResponsiveProperties().rows,
          };
          
          this.elementsSelectedOriginalPosition[es.Id] = position;
        });
      });
      
      this.communicationService.Event.Editor.$ChangesRepresentativePropertiesPanel.emit();
      this.communicationService.Event.Editor.$SelectedElementsChange.emit(this.elementsSelected);
      this.CreateElementsSelectedParticleAssociation();
    }, 50);
  }
  
  ElementsSelectedContainsId(id: number): boolean {
    return this.elementsSelected.map(es => es.Id)
    .includes(id);
  }
  
  DeselectRepresentativeMolecule(repMoleculeId: string) {
    const repMolecule = this.busService.Get(repMoleculeId);
    
    this.elementsSelected = this.elementsSelected.filter(es => es.Id !== repMolecule.Id);
    
    // if (this.elementsSelected.length === 0) {
    //   this.firstElementSelected = null;
    //   this.secondElementSelected = null;
    // } else if (this.elementsSelected.length === 1) {
    //   this.firstElementSelected = repMolecule;
    // } else if (this.elementsSelected.length > 2) {
    //   if (!this.ElementsSelectedContainsId(this.firstElementSelected.Id)) {
    //     this.firstElementSelected = null;
    //
    //     if (this.secondElementSelected) {
    //       this.firstElementSelected = this.secondElementSelected;
    //       this.secondElementSelected = null;
    //     }
    //   }
    // }
    
    this.primaryElementsSelected = this.primaryElementsSelected.filter(es => es.Id !== repMolecule.Id);
    const htmlElement = document.querySelector(`#gridsterItem-${ repMoleculeId }`) as any;
    
    if (htmlElement) {
      htmlElement.classList.remove('element-selected');
    }
    
    if (this.elementsSelected.length === 0) {
      this.showElementFocusedMenu = false;
    }
    
    repMolecule.Deselect();
    this.CreateElementsSelectedParticleAssociation();
  }
  
  RefreshElementsSelected() {
    this.elementsSelected = this.busService
    .GetWorkareaViewMolecules(this.cobbleService.Cobble, this.actualEditorViews)
    .filter(repMol => this.elementsSelected.map(es => es.Id)
    .includes(repMol.Id));
  }
  
  DeselectAllRepresentativeMolecules(keepFirstSecondPriority = false) {
    console.log('deselect');
    this.elementsSelected.forEach(es => {
      es.Deselect();
    });
    
    this.elementsSelected = [];
    this.primaryElementsSelected = [];
    this.showElementFocusedMenu = false;
    
    if (!keepFirstSecondPriority) {
      this.firstElementSelected = null;
      this.secondElementSelected = null;
    }
    
    this.communicationService.Event.Editor.DataSource.$RemoveDataSourceHighlight.emit();
    this.communicationService.Event.Editor.EventsTree.$RemoveHighlight.emit();
    this.communicationService.Event.Editor.Views.$RefreshViewsPanelUI.emit();
    this.CreateElementsSelectedParticleAssociation();
  }
  
  CreateElementsSelectedParticleAssociation() {
    this.toolsService.Throttle(
      (func, delay, context) => {
        this.elementsSelectedParticleAssociation = {};
        this.elementsSelected.forEach(es => {
          es.Buses.forEach(bus => {
            bus.Particles.forEach(particle => {
              this.elementsSelectedParticleAssociation[particle.ParticleId] = es.Id;
            });
          });
        });
      },
      300,
      this,
      null,
    );
  }
  
  ReplaceParticleForAllElements(particle: Particle, shadowParticleId: string, positionIndex = 0) {
    if (this.elementsSelected.length >= 1) {
      const particlesAssociation = this.elementsSelectedShadowParticleAssociation[shadowParticleId] as {
        particleId: string;
        busId: string;
        repMolecule: RepresentativeMolecule;
        particles: Particle[];
      }[];
      
      particlesAssociation.forEach(pa => {
        pa.repMolecule.GetBus(pa.busId)
        .AddParticle(particle, positionIndex, pa.repMolecule.Id.toString());
        
        pa.repMolecule.GetBus(pa.busId)
        .RemoveParticle(pa.particles[0].ParticleId);
        pa.repMolecule.SaveProperty('buses', `Particle replaced`)
        .subscribe();
      });
      
      setTimeout(() => {
        this.communicationService.Event.Editor.$RecreateProcessBuses.emit(true);
      }, 100);
      
      this.Throttle(
        (func, delay, context2) => {
          this.snackerService.ShowMessageOnBottom('Particle replaced', 'swap_horizontal_circle', null, true);
        },
        300,
        this,
        null,
      );
    }
  }
  
  RemoveParticleFromAllElements(shadowParticleId: string, busId: string) {
    if (this.elementsSelected.length >= 1) {
      const particlesAssociation = this.elementsSelectedShadowParticleAssociation[shadowParticleId] as {
        particleId: string;
        busId: string;
        repMolecule: RepresentativeMolecule;
        particles: Particle[];
      }[];
      
      if (particlesAssociation) {
        particlesAssociation.forEach(pa => {
          pa.repMolecule.GetBus(pa.busId)
          .RemoveParticle(pa.particles[0].ParticleId);
          pa.repMolecule.SaveProperty('buses', `Molecule removed`)
          .subscribe();
        });
      } else {
        this.elementsSelected.forEach(es => {
          es.GetBus(busId)
          .RemoveParticle(shadowParticleId);
          es.SaveProperty('buses', `Molecule removed`)
          .subscribe();
        });
      }
      
      this.communicationService.Event.Editor.$RepresentativeMoleculeDetection.emit(
        { repMoleculeId: null, state: true });
      
      setTimeout(() => {
        this.communicationService.Event.Editor.$RecreateProcessBuses.emit(true);
      }, 100);
      
      this.Throttle(
        (func, delay, context2) => {
          this.snackerService.ShowMessageOnBottom('Particle removed', 'do_not_disturb_on', null, true);
        },
        300,
        this,
        null,
      );
    }
  }
  
  GetEventsForSelectedMolecule(repMolecule = this.elementsSelected[0]) {
    console.log(repMolecule.Events);
  }
  
  Throttle(func, delay, context, args) {
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => func.apply(context, arguments), delay);
  }
  
  SaveLastUsedElement(tab: string, section: string, value: string) {
    this.lastUsedElements = {
      ...this.lastUsedElements,
      [tab.toLowerCase()]: {
        ...this.lastUsedElements[tab.toLowerCase()],
        [section.toLowerCase()]: value,
      },
    };
    this.communicationService.Event.Editor.$LastUsedElement.emit(this.lastUsedElements);
  }
}
