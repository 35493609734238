import { Component, Input, OnInit } from '@angular/core';
import { BusService } from '../../core/molecular/services/bus.service';
import { ToolsService } from '../../core/services/tools.service';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowService } from '../../shared/services/draggable-window.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-event-source',
  templateUrl: './event-source.component.html',
  styleUrls: ['./event-source.component.scss'],
})
export class EventSourceComponent implements OnInit {
  
  @Input() event: LeapXLEvent;
  repMolecules: IRepresentativeMolecule[] = [];
  repMoleculesEvents: any = [];
  
  constructor(
    private draggableWindowService: DraggableWindowService,
    private toolsService: ToolsService,
    private cobbleService: CobbleService,
    private workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
    private busService: BusService,
  ) {
  }
  
  ngOnInit() {
    this.event = this.draggableWindowService.GetData().event;
    this.repMolecules = this.busService.GetChannelArray().filter(repMol => repMol.GetBusesWithSpecificEvent(this.event.EventName, this.event.SourceId).length > 0);
    this.repMoleculesEvents = this.GetRepMoleculesByView();
    console.log('rep molecules', this.repMolecules);
  }
  
  
  GetRepMoleculesByView() {
    console.log('get event');
    const views = [];
    
    this.cobbleService.Cobble.properties.views.forEach(view => {
      
      const viewMols = {
        view: view.name,
        repMolecules: [],
      };
      
      this.repMolecules.filter(repMolecule => repMolecule.Properties.view === view.id).forEach(repMolecule => {
        
        const repMolEvent = {
          repMol: repMolecule,
          in: false,
          out: false,
          error: false,
        };
        
        repMolecule.GetBusesWithSpecificEvent(this.event.EventName, this.event.SourceId).forEach(b => {
          if (b.FirstParticle().IsEvent() && (b.FirstParticle() as LeapXLEvent).EventName.toLowerCase() === this.event.EventName.toLowerCase()) {
            repMolEvent.in = true;
          } else {
            repMolEvent.out = true;
          }
          
          const foundEvent = repMolecule.GetEventByEventName(this.event.EventName);
          
          if (foundEvent.SourceId !== this.event.SourceId) {
            repMolEvent.error = true;
          }
          
        });
        
        viewMols.repMolecules.push(repMolEvent);
      });
      
      views.push(viewMols);
    });
    
    return views;
  }
  
  HighlightRepMolecule(repMolecule: IRepresentativeMolecule) {
    
    if (repMolecule.Type === RepresentativeMoleculesType.WorkGroup) {
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(repMolecule);
    } else {
      const parentRepMolecule = this.busService.Get(repMolecule.ParentId.toString());
      this.communicationService.Event.Editor.Views.$SwitchToRepresentativeMoleculeView.emit(parentRepMolecule);
      setTimeout(() => {
        repMolecule.ScrollIntoFocus();
      }, 200);
    }
    
    setTimeout(() => {
      this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(null);
      this.workAreaService.ShowElementFocusedMenu(repMolecule);
    }, 200);
  }
}
