import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-custom-molecule',
  templateUrl: './custom-molecule.component.html',
  styleUrls: ['./custom-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CustomMoleculeComponent extends BaseMoleculeComponent
  implements OnInit {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Custom;
  
  @ViewChild('customComponentWrapper', { static: true })
  customComponentWrapper: ElementRef;
  customControlHTML: SafeHtml;
  customControlHTMLString: string;
  
  identifier = '';
  ruleSelectorsSet = [];
  cssParsingError = false;
  externaLibrariesLoadedCount = 0;
  allDependenciesLoaded = false;
  externalJsLibraries = [];
  width = 0;
  height = 0;
  
  errorLoadingDependency = false;
  errorDependencyName = '';
  repMoleculeInstanceProperties = {};
  displayIframe = false;
  iframeBody = '';
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private sanitized: DomSanitizer,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    this.context.Type = RepresentativeMoleculesType.Custom;
    this.RefreshGridsterConfiguration();
    this.InitCustomControl();
  }
  
  InitInstanceProperties() {
    const height = this.context.ResponsiveProperties().rows * 4;
    const width = this.context.ResponsiveProperties().cols * 4;
    
    this.repMoleculeInstanceProperties['LeapXL.Instance.width.cm'] = +'cm';
    this.repMoleculeInstanceProperties['LeapXL.Instance.height.cm'] = height + 'cm';
    this.repMoleculeInstanceProperties['LeapXL.Instance.width.mm'] = width + 'mm';
    this.repMoleculeInstanceProperties['LeapXL.Instance.height.mm'] = height + 'mm';
    this.repMoleculeInstanceProperties['LeapXL.Instance.width.Q'] = width + 'Q';
    this.repMoleculeInstanceProperties['LeapXL.Instance.height.Q'] = height + 'Q';
    this.repMoleculeInstanceProperties['LeapXL.Instance.width.in'] = width + 'in';
    this.repMoleculeInstanceProperties['LeapXL.Instance.height.in'] = height + 'in';
    this.repMoleculeInstanceProperties['LeapXL.Instance.width.pt'] = width + 'pt';
    this.repMoleculeInstanceProperties['LeapXL.Instance.height.pt'] = height + 'pt';
    this.repMoleculeInstanceProperties['LeapXL.Instance.width.pc'] = width + 'pc';
    this.repMoleculeInstanceProperties['LeapXL.Instance.height.pc'] = height + 'pc';
    this.repMoleculeInstanceProperties['LeapXL.Instance.width.px'] = width + 'px';
    this.repMoleculeInstanceProperties['LeapXL.Instance.height.px'] = height + 'px';
    this.repMoleculeInstanceProperties['LeapXL.Instance.width'] = width;
    this.repMoleculeInstanceProperties['LeapXL.Instance.height'] = height;
  }
  
  UpdateData(): void {
    super.UpdateData();
    if (this.IsEditor) {
      this.InitCustomControl();
    }
  }
  
  RemoveJsScript() {
    var script = document.getElementById(`${ this.context.Id }_js`);
    if (script) {
      script.remove();
    }
  }
  
  async InitCustomControl() {
    this.RemoveJsScript();
    this.InitInstanceProperties();
    
    this.width = this.context.ResponsiveProperties().cols * 4;
    this.height = this.context.ResponsiveProperties().rows * 4;
    
    //region constants
    const htmlReplacements: { toReplace: string, replaceWith: string }[] = [];
    //endregion
    
    if (!this.toolsService.IsEditor()) {
      
      // if (this.context.Properties.customControl.options.externalCss.length > 0 || this.context.Properties.customControl.options.externalJs.length > 0) {
      if (this.context.Properties.customControl.options.isolated) {
        this.RenderIframe();
      } else {
        
        //region css parsing
        this.LoadCss();
        //endregion
        
        //region load js
        let jsLoadResult = await this.LoadJs();
        //endregion
        
        //region html replacements
        Object.keys(jsLoadResult.replacements).forEach(key => {
          htmlReplacements.push({
            toReplace: key + '(',
            replaceWith: jsLoadResult.replacements[key] + '(',
          });
        });
        
        htmlReplacements.push({
          toReplace: `href="#"`,
          replaceWith: `href="${ window.location.href }#"`,
        });
        //endregion
        
        const html = this.ReplaceOnHTML(this.context.Properties.customControl.html, htmlReplacements);
        this.customControlHTML = this.sanitized.bypassSecurityTrustHtml(html);
        
        if (this.context.Properties.customControl.options) {
          
          if (this.context.Properties.customControl.options.externalCss) {
            const externalCssLibraries = this.context.Properties.customControl.options.externalCss.filter(extCss => extCss.url !== '' && extCss.valid);
            
            externalCssLibraries.forEach(extCss => {
              if (document.querySelector(`link[href*='${ extCss.url }']`) || extCss.url === '') {
                // exists, do not add again
              } else {
                const externalStyleElement = document.createElement('link');
                externalStyleElement.href = extCss.url;
                externalStyleElement.rel = 'stylesheet';
                document.head.appendChild(externalStyleElement);
              }
            });
          }
          
          setTimeout(() => {
            
            //region third party js libraries loader
            if (this.context.Properties.customControl.options.externalJs && this.context.Properties.customControl.options.externalJs.length > 0) {
              
              this.externalJsLibraries = this.context.Properties.customControl.options.externalJs.filter(extJs => extJs.url !== '' && extJs.valid);
              this.LoadJsDependency(this.externalJsLibraries, jsLoadResult.scriptElement);
              
            } else {
              document.body.appendChild(jsLoadResult.scriptElement);
            }
            //endregion
            
          }, 200);
        }
        
      }
      
    } else {
      if (this.context.Properties.customControl.options.previewOnEditor) {
        this.RenderIframe(true);
      }
      // this.customControlHTML = this.sanitized.bypassSecurityTrustHtml(this.context.Properties.customControl.html);
    }
  }
  
  RenderIframe(disableLeapJs = false) {
    try {
      const jsReplacements = {
        'LeapXL.TriggerEvent(': 'window.parent.LeapXL.TriggerEvent(' + this.context.Id + ',',
        'LeapXL.RegisterControlReceptor(': 'window.parent.LeapXL.RegisterControlReceptor(' + this.context.Id + ',',
      };
      
      if (disableLeapJs) {
        jsReplacements['LeapXL.TriggerEvent('] = 'console.log(';
        jsReplacements['LeapXL.RegisterControlReceptor('] = 'console.log(';
      }
      
      const html = this.context.Properties.customControl.html;
      let js = this.context.Properties.customControl.js;
      let css = this.context.Properties.customControl.css;
      js = this.ApplyInstancePropertiesToCode(js);
      
      if (this.context.Properties.customControl.options.isolatedTransparentBackground) {
        css += '\n\nbody {background: none transparent !important;}';
      }
      
      let clearHtml = html;
      clearHtml = (clearHtml as any).replaceAll('<script>', '');
      clearHtml = (clearHtml as any).replaceAll('</script> ', '');
      clearHtml = (clearHtml as any).replaceAll('<html>', '');
      clearHtml = (clearHtml as any).replaceAll('</html>', '');
      clearHtml = (clearHtml as any).replaceAll('<!DOCTYPE html>', '');
      
      let externalJs = '';
      let externalCss = '';
      
      if (this.context.Properties.customControl.options) {
        if (this.context.Properties.customControl.options.externalJs) {
          this.context.Properties.customControl.options.externalJs.forEach(extJs => {
            if (extJs && extJs.url !== '' && extJs.valid) {
              const jsTag = `<script ${ extJs.module ? 'type="module"' : '' } src="${ extJs.url }" type="application/javascript"></script>`;
              externalJs += jsTag;
            }
          });
        }
        
        if (this.context.Properties.customControl.options.externalCss) {
          this.context.Properties.customControl.options.externalCss.forEach(extCss => {
            if (extCss && extCss.url !== '' && extCss.valid) {
              const cssTag = `<link href="${ extCss.url }" rel="stylesheet">`;
              externalCss += cssTag;
            }
          });
        }
      }
      
      Object.keys(jsReplacements).forEach(key => {
        js = this.toolsService.ReplaceInText(js, key, jsReplacements[key]);
      });
      
      if (js.includes('InitCustomControl')) {
        js = js + `\n\n InitCustomControl();\n`;
      }
      
      const iframeBody =
        '<html>' +
        '<head>' +
        externalCss +
        '<style>' +
        css +
        '</style>' +
        '</head>' +
        '<body style="margin: 0px;">' +
        '<div>' + clearHtml + '</div>' +
        externalJs +
        `${ this.context.Properties.customControl.options.jsModuleType ? '<script type="module" >' : '<script>' }` +
        js +
        '</script>' +
        '</body>' +
        '</html>';
      
      
      this.iframeBody = iframeBody;
      this.displayIframe = true;
      if (this.IsEditor) {
        this.RefreshUI();
      }
    } catch (e) {
      console.error(e);
    }
  }
  
  PreviewOnEditor() {
    this.context.Properties.customControl.options.previewOnEditor = true;
    this.UpdateData();
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
  }
  
  LoadCss() {
    
    //region constants
    const doNotModifySelectors = [];
    const avoidSelectors = [];
    const customStyleSheetName = 'customControlsStyles';
    const repMolElementId = `#gridsterItem-${ this.context.Id } custom-control-html`;
    const bodyClass = `cc-body`;
    const htmlClass = `cc-html`;
    const scopedSelectors = [':root'];
    const selectorsReplacements = {
      'body': `.${ bodyClass }`,
      'html': `.${ htmlClass }`,
    };
    
    const styleSheets = [].slice.call(document.styleSheets);
    const customStyleSheet = styleSheets.find(ss => ss.title === customStyleSheetName);
    console.log(customStyleSheet);
    
    this.ruleSelectorsSet.forEach(selector => {
      try {
        const ruleIndex = [].slice.call(customStyleSheet.rules).findIndex(r => r.selectorText === selector);
        customStyleSheet.removeRule(ruleIndex);
      } catch (e) {
      
      }
    });
    
    this.ruleSelectorsSet = [];
    this.identifier = this.toolsService.GenerateGuid();
    let bodyExists = false;
    let htmlExists = false;
    
    try {
      let css = this.ApplyInstancePropertiesToCode(this.context.Properties.customControl.css);
      css = this.ReplaceVhVw(css);
      const parsedCss = this.toolsService.ParseCSS(css);
      console.log(parsedCss);
      
      parsedCss.forEach((section) => {
        if (section.rules) {
          let rules = '';
          
          section.rules.forEach(rule => {
            rules += `${ rule.directive }: ${ rule.value };`;
          });
          
          if (!avoidSelectors.includes(section.selector)) {
            
            let ruleSelector = '';
            
            if (doNotModifySelectors.includes(section.selector)) {
              ruleSelector = section.selector;
            } else {
              
              const selectors = section.selector.split(',');
              const newRuleSelectors = [];
              
              selectors.forEach(selector => {
                
                selector = selector.trim();
                
                if (selector === 'body') {
                  bodyExists = true;
                  
                  if (section.rules.find(r => r.directive === 'display')) {
                    // do not override
                  } else {
                    section.rules.push({
                      directive: 'display',
                      value: 'block',
                    });
                    rules = rules + 'display: block;';
                  }
                  
                }
                
                if (selector === 'html') {
                  htmlExists = true;
                  
                  if (section.rules.find(r => r.directive === 'display')) {
                    // do not override
                  } else {
                    section.rules.push({
                      directive: 'display',
                      value: 'block',
                    });
                    rules = rules + 'display: block;';
                  }
                }
                
                if (selectorsReplacements[selector]) {
                  newRuleSelectors.push(`${ repMolElementId } ${ scopedSelectors.includes(selector.trim()) ? ' ' : (selectorsReplacements[selector].trim() as any).replaceAll('\n', ' ') }`);
                } else {
                  newRuleSelectors.push(`${ repMolElementId } ${ scopedSelectors.includes(selector.trim()) ? ' ' : (selector.trim() as any).replaceAll('\n', ' ') }`);
                }
              });
              
              ruleSelector = newRuleSelectors.join(', ');
            }
            
            this.ruleSelectorsSet.push(ruleSelector);
            this.toolsService.GenerateClassDynamically(ruleSelector, rules, customStyleSheetName);
          }
          
        } else if (section.type) {
          this.toolsService.GenerateClassDynamically(section.selector, section.styles, customStyleSheetName);
        }
        
      });
      this.cssParsingError = false;
    } catch (e) {
      this.cssParsingError = true;
      console.warn('Error parsing custom control css', e);
    }
    
    if (bodyExists) {
      this.context.Properties.customControl.html = `<cc-body class="${ bodyClass }">${ this.context.Properties.customControl.html }</cc-body>`;
    }
    
    if (htmlExists) {
      this.context.Properties.customControl.html = `<cc-html class="${ htmlClass }">${ this.context.Properties.customControl.html }</cc-html>`;
    }
    
  }
  
  async LoadJs() {
    //region constants
    const eventIdentifier = 'LeapXL.TriggerEvent(';
    const receptorIdentifier = 'LeapXL.RegisterControlReceptor(';
    const selectorIdentifier = `.querySelector('`;
    const selectorIdIdentifier = `.getElementById('`;
    const selectorIdentifierAll = `.querySelectorAll('`;
    const selectorIdentifierQuote = `.querySelector("`;
    const selectorIdIdentifierQuote = `.getElementById("`;
    const selectorIdentifierAllQuote = `.querySelectorAll("`;
    const initControlFunctionName = 'InitCustomControl';
    const repMolElementId = `#gridsterItem-${ this.context.Id } custom-control-html`;
    let replacements = {};
    
    let myScriptElement: HTMLScriptElement = null;
    myScriptElement = document.createElement('script');
    myScriptElement.id = `${ this.context.Id }_js`;
    
    let js = this.toolsService.ReplaceInText(this.context.Properties.customControl.js, eventIdentifier, `${ eventIdentifier }${ this.context.Id },`);
    js = this.toolsService.ReplaceInText(js, receptorIdentifier, `${ receptorIdentifier }${ this.context.Id },`);
    js = this.toolsService.ReplaceInText(js, selectorIdentifier, `${ selectorIdentifier }${ repMolElementId } `);
    js = this.toolsService.ReplaceInText(js, selectorIdentifierAll, `${ selectorIdentifierAll }${ repMolElementId } `);
    
    js = this.toolsService.ReplaceInText(js, selectorIdIdentifier, `${ selectorIdentifier }${ repMolElementId } #`);
    
    js = this.toolsService.ReplaceInText(js, selectorIdentifierQuote, `${ selectorIdentifierQuote }${ repMolElementId } `);
    
    js = this.toolsService.ReplaceInText(js, selectorIdIdentifierQuote, `${ selectorIdentifierQuote }${ repMolElementId } #`);
    
    js = this.toolsService.ReplaceInText(js, selectorIdentifierAllQuote, `${ selectorIdentifierAllQuote }${ repMolElementId } `);
    js = this.ApplyInstancePropertiesToCode(js);
    
    /////
    if (this.context.Properties.customControl.options.obfuscateJs) {
      const minifiedCode = await this.toolsService.MinifyMangleJsCode(js, true, !this.IsDebug, `leap_${ this.context.Id }`, [initControlFunctionName], this.IsDebug);
      js = minifiedCode.result;
      replacements = minifiedCode.replacements;
    }
    ////
    
    if (this.IsDebug) {
      js = '\n// Custom Control JS Code for: ' + this.context.Properties.name + '\n\n' + js;
    }
    
    if (js.indexOf(initControlFunctionName) >= 0) {
      const uniqueInitCustomControlFunctionName = this.GenerateRandomName();
      js = js.replace(initControlFunctionName, uniqueInitCustomControlFunctionName);
      js += `\n\n${ uniqueInitCustomControlFunctionName }();`;
    } else {
      const warningFunctionName = this.GenerateRandomName();
      js += `\n\nfunction ${ warningFunctionName }() { console.warn("---Needs to initialize the custom control [${ this.context.Properties.name }] using 'InitCustomControl' naming function---"); }`;
      js += `\n\n${ warningFunctionName }();`;
    }
    
    myScriptElement.defer = true;
    myScriptElement.text = js;
    if (this.context.Properties.customControl.options.jsModuleType) {
      myScriptElement.type = 'module';
    }
    
    if (this.IsDebug) {
      myScriptElement.title = `JS_${ (this.context.Properties.name as any).replaceAll(' ', '_') }_${ this.context.Id }`;
    }
    
    
    return {
      scriptElement: myScriptElement,
      replacements: replacements,
    };
  }
  
  ReplaceVhVw(cssCode) {
    // regular expression to match vh and vw values
    const pattern = /(\d+(\.\d+)?\s*(vh|vw))/g;
    
    // Replace vh and vw values using the callback function
    cssCode = cssCode.replace(pattern, (match, value, _, unit) => {
      const replacement = this.CustomReplacement(parseFloat(value), unit);
      return replacement !== undefined ? replacement : match;
    });
    return cssCode;
  }
  
  CustomReplacement(originalValue, unit) {
    console.log(originalValue, unit);
    if (unit === 'vh') {
      // Replace vh values
      return `${ (originalValue / 100) * this.context.ResponsiveProperties().rows * 4 }px`;
    } else if (unit === 'vw') {
      // Replace vw values
      return `${ (originalValue / 100) * this.context.ResponsiveProperties().cols * 4 }px`;
    }
    // Return undefined for other units to keep them unchanged
    return undefined;
  }
  
  ReplaceOnHTML(html: string, replacements: { toReplace: string, replaceWith: string }[]) {
    replacements.forEach(replacement => {
      html = (html as any).replaceAll(replacement.toReplace, replacement.replaceWith);
    });
    return html;
  }
  
  LoadJsDependency(dependencies: any[], myScriptElement: HTMLScriptElement) {
    
    if (dependencies.length === 0) {
      console.warn('All dependencies loaded');
      this.allDependenciesLoaded = true;
      document.body.appendChild(myScriptElement);
    } else {
      
      const jsLibrary = dependencies.shift();
      const libraryLoading = this.runtimeService.jsDependenciesLoaded[jsLibrary.url];
      
      if (libraryLoading) {
        
        if (libraryLoading.loaded) {
          this.externaLibrariesLoadedCount++;
          this.LoadJsDependency(dependencies, myScriptElement);
        } else {
          setTimeout(() => {
            dependencies.unshift(jsLibrary);
            this.LoadJsDependency(dependencies, myScriptElement);
          }, 100);
        }
        
      } else {
        const externalScriptElement = document.createElement('script');
        externalScriptElement.src = jsLibrary.url;
        if (jsLibrary.module) {
          externalScriptElement.type = 'module';
        }
        
        externalScriptElement.addEventListener('load', (ev) => {
          console.log(jsLibrary.url + ' loaded');
          
          this.runtimeService.jsDependenciesLoaded[jsLibrary.url] = { loaded: true };
          this.externaLibrariesLoadedCount++;
          this.LoadJsDependency(dependencies, myScriptElement);
        });
        
        externalScriptElement.addEventListener('error', (ev) => {
          this.errorLoadingDependency = true;
          this.errorDependencyName = jsLibrary.url;
          console.warn('Error loading third party library');
        });
        
        this.runtimeService.jsDependenciesLoaded[jsLibrary.url] = { loaded: false };
        document.body.appendChild(externalScriptElement);
      }
    }
  }
  
  GenerateRandomName() {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let randomName = '';
    for (let i = 0; i < 20; i++) {
      randomName += characters[Math.floor(Math.random() * characters.length)];
    }
    return randomName;
  }
  
  ApplyInstancePropertiesToCode(code: string) {
    
    Object.keys(this.repMoleculeInstanceProperties).forEach(key => {
      code = this.toolsService.ReplaceInText(code, key, this.repMoleculeInstanceProperties[key]);
    });
    
    return code;
  }
  
  ReplaceFunctionNamesWithRandom(text) {
    if (this.context.Properties.customControl.options.obfuscateJs) {
      const functionNameHash = [];
      
      // Regular expression to match JavaScript function declarations
      const functionDeclarationRegex = /function\s+([a-zA-Z_$][0-9a-zA-Z_$]*)\s*\(/g;
      
      // Replace function names with random names
      let replacedText = text.replace(functionDeclarationRegex, (match, functionName) => {
        const randomName = this.GenerateRandomName();
        functionNameHash.push({
          functionName: functionName,
          newName: randomName,
        });
        return `function ${ randomName }(`;
      });
      
      functionNameHash.forEach(replacement => {
        console.log(replacement.functionName, replacement.newName);
        
        replacedText = replacedText.replaceAll(`${ replacement.functionName }(`, `${ replacement.newName }(`);
        replacedText = replacedText.replaceAll(` ${ replacement.functionName } `, ` ${ replacement.newName } `);
        replacedText = replacedText.replaceAll(`${ replacement.functionName },`, `${ replacement.newName },`);
        replacedText = replacedText.replaceAll(`${ replacement.functionName })`, `${ replacement.newName })`);
      });
      
      console.log(functionNameHash);
      return { replacedText, functionNameHash };
    } else {
      return { replacedText: text, functionNameHash: [] };
    }
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('click', null, true);
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.customComponentWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.customComponentWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.customComponentWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
}
