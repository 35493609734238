import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import IMask from 'imask';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-textarea-molecule',
  templateUrl: './textarea-molecule.component.html',
  styleUrls: ['./textarea-molecule.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TextareaMoleculeComponent extends BaseMoleculeComponent implements OnInit, OnDestroy {
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Textarea;
  
  @ViewChild('textareaWrapper', { static: true })
  textareaWrapper: ElementRef;
  
  mask = null;
  inputEl = null;
  inputValue = '';
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  get TextboxValue() {
    return this.inputValue;
  }
  
  set TextboxValue(data: string) {
    this.inputValue = data;
    setTimeout(() => {
      this.context.Value = this.HasMasking() && this.mask ? this.mask.unmaskedValue : this.inputEl.value;
      this.FireKeyEvent(null);
    }, 15);
  }
  
  ngOnInit() {
    super.ngOnInit();
    if (!this.context.RunningMode) {
      this.DetachChangeDetection();
    }
    this.context.Type = RepresentativeMoleculesType.Textarea;
    this.RefreshGridsterConfiguration();
    this.inputEl = document.querySelector(`#gridsterItem-${ this.context.Id } textarea`) as any;
    
    if (this.HasMasking()) {
      this.mask = IMask(this.inputEl, this.GetMaskOptions());
    }
    
    this.UpdateData();
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.textareaWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.textareaWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.textareaWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  AttachRunningEventListeners() {
    const clickEventListener = this.renderer.listen(this.textareaWrapper.nativeElement, 'click', (evt) => {
      this.FireClickEvent(evt);
    });
  }
  
  FireClickEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('click', this.context.Value, true);
  }
  
  FireKeyEvent(e: any) {
    this.FireRepresentativeMoleculeEvent('keyup', this.context.Value, true);
  }
  
  FirePasteEvent(e: any) {
    setTimeout(() => {
      this.FireRepresentativeMoleculeEvent('keyup', this.context.Value, true);
    }, 20);
  }
  
  SetValue(e: any) {
    this.TextboxValue = (e.target as any).value;
  }
  
  UpdateData(): void {
    if (this.HasMasking() && this.context.Value) {
      var mask = IMask.createMask(this.GetMaskOptions());
      this.inputValue = mask.resolve(this.context.Value);
    } else {
      this.inputValue = this.context.Value;
    }
  }
}
