import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ApiPropertiesService } from '../../../core/services/api-properties.service';
import { EditorStateService } from '../../../core/services/editor-state.service';
import { GenericDialogService } from '../../../core/services/generic-dialog.service';
import { ToolsService } from '../../../core/services/tools.service';
import { Bus } from '../../../shared/representative-molecule/interfaces/bus';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { DragService } from '../../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../../shared/services/communication.service';
import { ConnectionStateService } from '../../../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../../shared/services/draggable-window.service';
import { SnackerService } from '../../../shared/services/snacker.service';
import { WorkAreaService } from '../../workarea.service';

@Component({
  selector: 'app-bus-frame',
  templateUrl: './bus-frame.component.html',
  styleUrls: ['./bus-frame.component.scss'],
  host: {
    '[class.detached]': 'isDetached',
    '[class.w-100]': 'bus.Open',
  },
})
export class BusFrameComponent implements OnInit {
  @ViewChild('editBusNameInput', { static: false })
  editBusNameInput: ElementRef;
  @Input() elementFocused: IRepresentativeMolecule;
  @Input() intoPanel = false;
  @Input() bus: Bus;
  receptorBus: Bus;
  renameBus = '';
  renameBusId = '';
  isDetached = false;
  hover = false;
  receptors = [];
  
  constructor(
    public workAreaService: WorkAreaService,
    private toolsService: ToolsService,
    private connectionStateService: ConnectionStateService,
    private editorStateService: EditorStateService,
    private genericDialogService: GenericDialogService,
    private propertiesService: ApiPropertiesService,
    private draggableWindowService: DraggableWindowService,
    public draggableWindowManagerService: DraggableWindowManagerService,
    public dragService: DragService,
    private snackerService: SnackerService,
    private communicationService: CommunicationService,
  ) {
    if (!this.elementFocused) {
      const data = this.draggableWindowService.GetData();
      this.elementFocused = data.elementFocused;
      this.bus = data.bus;
      this.isDetached = data.separated;
    }
    
  }
  
  OpenSeparateBus(bus: Bus, event: MouseEvent) {
    this.draggableWindowService.OpenDraggableWindow(
      `Process - ${ bus.Name }`,
      DraggableWindowType.SeparateBus,
      event,
      {
        bus: bus,
        elementFocused: this.elementFocused,
        separated: true,
      },
      true,
    );
  }
  
  RemoveBus(bus: Bus) {
    if (bus.Particles.length === 0) {
      this.deleteBus(bus);
    } else {
      this.genericDialogService.OpenConfirmDialog({
        title: 'Remove Process',
        message: `This Process contains ${ bus.Particles.length } particles or molecules. Are you sure you want to delete ${ bus.Name }?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
      }).then(confirm => {
        if (confirm) {
          this.deleteBus(bus);
        }
      });
    }
  }
  
  RenameBus(bus: Bus) {
    if (this.toolsService.RunningMode) {
      return;
    }
    
    this.workAreaService.arrowKeysMovementDisabled = true;
    this.renameBus = bus.Name;
    this.renameBusId = bus.id;
    setTimeout(() => {
      this.editBusNameInput.nativeElement.focus();
      this.editBusNameInput.nativeElement.select();
    }, 100);
  }
  
  SaveBusName(bus: Bus) {
    bus.Name = this.renameBus;
    this.renameBus = '';
    this.elementFocused.SaveProperty('buses', `Process ${ bus.Name } renamed`).subscribe();
    this.StopBusRename();
  }
  
  StopBusRename() {
    this.renameBus = '';
    this.renameBusId = '';
    setTimeout(() => {
      this.workAreaService.arrowKeysMovementDisabled = false;
    }, 200);
  }
  
  ReceptorMenuOpened(bus: Bus) {
    this.receptorBus = bus;
  }
  
  SetReceptorToBus(receptor: string) {
    this.receptorBus.Receptor = receptor;
    this.elementFocused.SaveProperty('buses', `Receptor changed`).subscribe();
  }
  
  DuplicateBus(bus: Bus, event: MouseEvent) {
    this.elementFocused.AddBus(bus.CompleteClone());
    this.snackerService.ShowMessageOnBottom(`Bus duplicated`, `filter_2`, null, true);
    
    this.elementFocused.SaveProperty('buses', `Bus Duplicated`).subscribe();
  }
  
  CreateCompound(bus: Bus, event: MouseEvent) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    this.draggableWindowService.OpenDraggableWindow(
      'Create Compound',
      DraggableWindowType.CreateCompound,
      event,
      {
        bus: bus,
      },
    );
  }
  
  ToggleBus(bus: Bus) {
    bus.Open = !bus.Open;
    this.workAreaService.busState[bus.id] = bus.Open;
    this.editorStateService.SaveBusState();
  }
  
  ToggleEnable(bus: Bus) {
    bus.Enabled = !bus.Enabled;
    this.elementFocused.SaveProperty('buses', `Bus ${ bus.Enabled ? 'enabled' : 'disabled' }`).subscribe();
    this.snackerService.ShowMessageOnBottom(
      `Bus ${ bus.Enabled ? 'activated' : 'deactivated' }`,
      `${ bus.Enabled ? 'check_circle' : 'unpublished' }`,
      null,
      true,
    );
  }
  
  BusMouseControl(into: boolean, bus: Bus) {
    this.intoPanel = !into;
    bus.SearchActive = into;
  }
  
  dragHover(value: boolean) {
    this.hover = value;
  }
  
  ngOnInit(): void {
    let receptors = [...this.elementFocused.Receptors];
    
    if (this.elementFocused.Properties.customControl && this.elementFocused.Properties.customControl.receptors) {
      receptors = [...receptors, ...this.elementFocused.Properties.customControl.receptors.map(cr => cr.name)];
    }
    
    this.receptors = receptors;
  }
  
  private deleteBus(bus: Bus) {
    const busName = bus.Name;
    this.elementFocused.RemoveBus(bus.id);
    this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
    setTimeout(() => {
      this.elementFocused.SaveProperty('buses', `Process ${ busName } removed`).subscribe();
      this.snackerService.ShowMessageOnBottom(`Process ${ busName } removed`, 'do_not_disturb_on');
    }, 100);
  }
}
