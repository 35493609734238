import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { Library } from '../../shared/interfaces/library';
import { ActionMoleculeProperties } from '../../shared/representative-molecule/interfaces/action-molecule-properties';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { badgeTemplate } from '../../shared/templates/badge';
import { breadcrumbTemplate } from '../../shared/templates/breadcrumb';
import { buttonTemplate } from '../../shared/templates/button';
import { chartTemplate } from '../../shared/templates/chart';
import { checkboxTemplate } from '../../shared/templates/checkbox';
import { cobbleTemplate } from '../../shared/templates/cobble';
import { compoundTemplates } from '../../shared/templates/compound';
import { customTemplate } from '../../shared/templates/custom';
import { datepickerTemplate } from '../../shared/templates/datepicker';
import { dropdownTemplate } from '../../shared/templates/dropdown';
import { h1Template } from '../../shared/templates/h1';
import { h2Template } from '../../shared/templates/h2';
import { h3Template } from '../../shared/templates/h3';
import { h4Template } from '../../shared/templates/h4';
import { h5Template } from '../../shared/templates/h5';
import { iconTemplate } from '../../shared/templates/icon';
import { iframeTemplate } from '../../shared/templates/iframe';
import { imageTemplate } from '../../shared/templates/image';
import { labelTemplate } from '../../shared/templates/label';
import { progressTemplate } from '../../shared/templates/progress';
import { qrCodeTemplate } from '../../shared/templates/qrcode';
import { radioTemplate } from '../../shared/templates/radio';
import { sliderTemplate } from '../../shared/templates/slider';
import { stepperTemplate } from '../../shared/templates/stepper';
import { tableTemplate } from '../../shared/templates/table';
import { textareaTemplate } from '../../shared/templates/textarea';
import { textboxTemplate } from '../../shared/templates/textbox';
import { workgroupTemplate } from '../../shared/templates/workgroup';
import { ApiDataService } from './api-data.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateService extends ApiDataService {
  Libraries: Library[] = [];
  RetrocompatibilityMolecules = {
    FilterDataByValueMolecule: 'FilterDatasourceDataByValueEqualsAndMolecule',
    PaginateDataMolecule: 'DatasourcePaginateDataMolecule',
  };
  ActionMoleculesTemplates: ActionMoleculeProperties[] = [
    // {
    //   name: 'Show Value',
    //   icon: 'visibility',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    //   type: 'ShowValueMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    {
      name: 'Navigate View',
      icon: 'launch',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'NavigateViewMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'select',
          name: 'view',
          label: 'View',
          placeholder: 'Select View',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Encode',
      icon: 'repeat_on',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'EncodeMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'dropdown',
          name: 'encodeType',
          label: 'Type',
          placeholder: 'Select Encode Type',
          validation: [],
          options: [
            { name: 'Base 64', value: 'b64' },
            { name: 'URI/URL', value: 'uri' },
            { name: 'UTF-8', value: 'utf8' },
            { name: 'UTF-16', value: 'utf16' },
            { name: 'HEX', value: 'hex' },
            { name: 'ASCII', value: 'ascii' },
            { name: 'HTML', value: 'html' },
            { name: 'XML', value: 'xml' },
          ],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
      ],
    },
    {
      name: 'Decode',
      icon: 'repeat_on',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'DecodeMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'dropdown',
          name: 'decodeType',
          label: 'Type',
          placeholder: 'Select Decode Type',
          validation: [],
          options: [
            { name: 'Base 64', value: 'b64' },
            { name: 'URI/URL', value: 'uri' },
            { name: 'UTF-8', value: 'utf8' },
            { name: 'UTF-16', value: 'utf16' },
            { name: 'HEX', value: 'hex' },
            { name: 'ASCII', value: 'ascii' },
            { name: 'HTML', value: 'html' },
            { name: 'XML', value: 'xml' },
          ],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
      ],
    },
    {
      name: 'Formatting Mask',
      icon: 'text_rotation_none',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FormattingMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'mask',
          label: 'Mask',
          placeholder: 'Mask',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: false,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Take DataElements',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FilterByDataElementReferenceMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Print',
      icon: 'print',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'PrintMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'select',
          name: 'workgroupIds',
          label: 'Workgroups',
          placeholder: 'Select Workgroups',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
      ],
    },
    {
      name: 'Get Datasource Data',
      icon: 'pan_tool',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: false,
      delayGetDataElements: true,
      type: 'GetElementsDatasourceDataMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Add To Datasource',
      icon: 'playlist_add',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'AddToDatasourceMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Clear Value',
      icon: 'clear',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'ClearValueMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      
      parameters: [],
    },
    {
      name: 'OS Notification',
      icon: 'announcement',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'OSNotificationMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'message',
          label: 'Message',
          placeholder: 'Message',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Notification',
      icon: 'notifications',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'NotificationMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'message',
          label: 'Message',
          placeholder: 'Message',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
        {
          type: 'input',
          name: 'duration',
          label: 'Duration',
          placeholder: '(Milliseconds)',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Skip All On',
      icon: 'redo',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'SkipAllOnMolecule',
      
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Include All On',
      icon: 'double_arrow',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'IncludeAllOnMolecule',
      
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.Textbox],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    // {
    //   name: 'Get Workgroup Elements',
    //   icon: 'pan_tool',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    //bypassGetDataElements: false,
    //   type: 'GetWorkgroupElementsMolecule',
    
    //   defaults: [],
    
    //   parameters: [],
    // },
    {
      name: 'Disable',
      icon: 'block',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'DisableMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Enable',
      icon: 'done',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'EnableMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    // {
    //   name: 'Display Datasource Value',
    //   icon: 'computer',
    //   moleculeType: 'Behavior',
    //   advanced: false, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'DisplayDatasourceValueMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    // {
    //   name: 'Translate',
    //   icon: 'translate',
    //   moleculeType: 'DataAction',
    //   advanced: false, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'TranslateMolecule',
    //
    // defaults: [],
    //
    //   parameters: [
    //     {
    //       type: 'input',
    //       name: 'words',
    //       label: 'Words to translate',
    //       placeholder: 'Words to translate',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: true,
    //     },
    //     {
    //       type: 'input',
    //       name: 'translation',
    //       label: 'translation',
    //       placeholder: 'translation',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: true,
    //     },
    //   ],
    // },
    {
      name: 'Replace',
      icon: 'repeat',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'ReplaceMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'toreplace',
          label: 'Word to replace',
          placeholder: 'Word to replace',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
        {
          type: 'input',
          name: 'replaceby',
          label: 'Replace by',
          placeholder: 'Replace by',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Parser',
      icon: 'format_clear',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'ParseMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'parser',
          label: 'Parser Character',
          placeholder: 'Parser Character',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
        {
          type: 'checkbox',
          name: 'col',
          label: 'Column Based',
          placeholder: 'Column Based',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
      ],
    },
    {
      name: 'Max Length',
      icon: 'short_text',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'MaxLengthMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'length',
          label: 'Text Length',
          placeholder: 'Text Length',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
      ],
    },
    {
      name: 'Numeric Only',
      icon: 'filter_1',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'NumericOnlyMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Alpha Only',
      icon: 'text_format',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'AlphaOnlyMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Symbol Only',
      icon: 'euro_symbol',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'SymbolOnlyMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Not Symbol',
      icon: 'block',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'NotSymbolMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    // {
    //   name: 'Display Value',
    //   icon: 'visibility',
    //   moleculeType: 'DataAction',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'DisplayValueMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    {
      name: 'Not Numeric',
      icon: 'block',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'NotNumericMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Not Alpha',
      icon: 'block',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'NotAlphaMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Prepend',
      icon: 'add',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'PrependMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'toprepend',
          label: 'Word to prepend',
          placeholder: 'Word to prepend',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: false,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Literal',
      icon: 'add',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'LiteralMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'value',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Disallow',
      icon: 'short_text',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'DisallowMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'disallow',
          label: 'Not allowed',
          placeholder: 'Not allowed',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: false,
          isMultiple: true,
        },
      ],
    },
    {
      name: 'Append',
      icon: 'add',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'AppendMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'toappend',
          label: 'Word to append',
          placeholder: 'Word to append',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: false,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Capitalization',
      icon: 'text_fields',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'CapitalizationMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Camelcase',
      icon: 'format_size',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'CamelCaseMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Remove',
      icon: 'delete_sweep',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'RemoveMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'toremove',
          label: 'Character to remove',
          placeholder: 'Character to remove',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: false,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Link New Tab',
      icon: 'insert_link',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'LinkToTabMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'url',
          label: 'Url',
          placeholder: 'Url',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Link',
      icon: 'insert_link',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'LinkToMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'url',
          label: 'Url',
          placeholder: 'Url',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    // {
    //   name: 'Display Masking',
    //   icon: 'visibility',
    //   moleculeType: 'DataAction',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'DisplayMaskingMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    // {
    //   name: 'Preserve',
    //   icon: 'save_alt',
    //   moleculeType: 'DataAction',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'PreserveMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    {
      name: 'Lowercase',
      icon: 'text_fields',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'LowerCaseMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Uppercase',
      icon: 'format_size',
      moleculeType: 'DataAction',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'UpperCaseMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.Textbox, RepresentativeMoleculesType.Textarea, RepresentativeMoleculesType.Dropdown],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['keyup', 'init'],
                bus: [
                  {
                    event: 'keyup',
                    receptor: 'value-input',
                  },
                  {
                    event: 'keyup',
                    receptor: 'value-output',
                  },
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [
            RepresentativeMoleculesType.Label,
            RepresentativeMoleculesType.H1,
            RepresentativeMoleculesType.H2,
            RepresentativeMoleculesType.H3,
            RepresentativeMoleculesType.H4,
            RepresentativeMoleculesType.H5,
          ],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    // {
    //   name: 'Column value for Row',
    //   icon: 'flip_to_front',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'GetColumnValueForRowMolecule',
    
    //   defaults: [],
    
    //   parameters: [
    //     {
    //       type: 'input',
    //       name: 'column',
    //       label: 'Column',
    //       placeholder: 'Column',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //   ],
    // },
    // {
    //   name: 'Display Cell Value',
    //   icon: 'visibility',
    //   moleculeType: 'DataAction',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'DisplayCellValueMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    // {
    //   name: 'Hide Characters',
    //   icon: 'expand_less',
    //   moleculeType: 'DataAction',
    //   advanced: false, allowRunOnEditor: false,
    //   bypassGetDataElements: false,
    //   delayGetDataElements: false,
    //   type: 'HideCharactersMolecule',
    //   defaults: [
    //     {
    //       elementType: [
    //         RepresentativeMoleculesType.Textbox,RepresentativeMoleculesType.Textarea,
    //         RepresentativeMoleculesType.Dropdown
    //       ],
    //       contexts: [
    //         {
    //           keys: ['default'],
    //           setup: {
    //             events: ['keyup', 'init'],
    //             bus: [
    //               {
    //                 event: 'keyup',
    //                 receptor: 'value-input'
    //               },
    //               {
    //                 event: 'keyup',
    //                 receptor: 'value-output'
    //               },
    //               {
    //                 event: 'init',
    //                 receptor: 'value-input'
    //               }
    //             ]
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       elementType: [
    //         RepresentativeMoleculesType.Label,
    //         RepresentativeMoleculesType.H1,
    //         RepresentativeMoleculesType.H2,
    //         RepresentativeMoleculesType.H3,
    //         RepresentativeMoleculesType.H4,
    //         RepresentativeMoleculesType.H5
    //       ],
    //       contexts: [
    //         {
    //           keys: ['default'],
    //           setup: {
    //             events: ['init'],
    //             bus: [
    //               {
    //                 event: 'init',
    //                 receptor: 'value-input'
    //               }
    //             ]
    //           }
    //         }
    //       ]
    //     },
    //     {
    //       elementType: [RepresentativeMoleculesType.All],
    //       contexts: [
    //         {
    //           keys: ['default'],
    //           setup: {
    //             events: ['init'],
    //             bus: [
    //               {
    //                 event: 'init',
    //                 receptor: 'value-input'
    //               }
    //             ]
    //           }
    //         }
    //       ]
    //     }
    //   ],
    //   parameters: [
    //     {
    //       type: 'input',
    //       name: 'character',
    //       label: 'Character',
    //       placeholder: 'Character',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false
    //     }
    //   ]
    // },
    {
      name: 'Right Align',
      icon: 'format_align_right',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'RightAlignMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    // {
    //   name: 'Timestamp',
    //   icon: 'today',
    //   moleculeType: 'Behavior',
    //   advanced: false, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'TimestampMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    // {
    //   name: 'Can Submit',
    //   icon: 'send',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //
    // defaults: [],
    //
    //   parameters: [
    //     {
    //       type: 'input',
    //       name: 'rowIndex',
    //       label: 'Row Index',
    //       placeholder: 'Row Index',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //     {
    //       type: 'input',
    //       name: 'colIndex',
    //       label: 'Col Index',
    //       placeholder: 'Col Index',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //     {
    //       type: 'input',
    //       name: 'collection',
    //       label: 'Sheet Name',
    //       placeholder: 'Sheet Name',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //     {
    //       type: 'select',
    //       name: 'dataSourceId',
    //       label: 'DataSource',
    //       placeholder: 'DataSource',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //   ],
    // },
    // {
    //   name: 'Username',
    //   icon: 'perm_identity',
    //   moleculeType: 'Behavior',
    //   advanced: false, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'UsernameMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    {
      name: 'Center Align',
      icon: 'format_align_center',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'CenterAlignMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    // {
    //   name: 'Date Masking',
    //   icon: 'event_busy',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    //   bypassGetDataElements: false,
    //   delayGetDataElements: false,
    //   type: 'DateMaskingMolecule',
    //   defaults: [
    //     {
    //       elementType: [RepresentativeMoleculesType.All],
    //       contexts: [
    //         {
    //           keys: ['default'],
    //           setup: {
    //             events: ['keyup'],
    //             bus: [
    //               {
    //                 event: 'keyup',
    //                 receptor: 'value-input'
    //               }
    //             ]
    //           }
    //         }
    //       ]
    //     }
    //   ],
    //   parameters: []
    // },
    // {
    //   name: 'Save Datasource Data',
    //   icon: 'save',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'SaveDatasourceDataMolecule',
    //
    // defaults: [],
    //
    //   parameters: [
    //     {
    //       type: 'select',
    //       name: 'dataSourceName',
    //       label: 'Datasource',
    //       placeholder: 'Select Datasource',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //   ],
    // },
    {
      name: 'Update Datasource Data',
      icon: 'update',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'UpdateDatasourceDataMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
          dependencies: [
            {
              bus: 'value-input',
              molecules: [
                {
                  name: 'FilterByDataElementReferenceMolecule',
                  sameValue: false,
                  value: null,
                },
              ],
            },
          ],
          dataElements: {
            copyTo: [
              {
                molecule: 'FilterByDataElementReferenceMolecule',
                append: false,
                createIfNotExists: false,
                bus: 'value-input',
              },
            ],
          },
        },
      ],
      parameters: [],
    },
    {
      name: 'Delete Datasource Data',
      icon: 'delete',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'DeleteDatasourceDataMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Get Related Data',
      icon: 'pan_tool',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'GetRelatedDataMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Datasource Paginate Data',
      icon: 'filter_1',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'DatasourcePaginateDataMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'paginate',
          label: 'Items Per Page',
          placeholder: 'Items Per Page',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    // {
    //   name: 'Paginate Data',
    //   icon: 'filter_3',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    //   bypassGetDataElements: false,
    //   delayGetDataElements: false,
    //   type: 'PaginateDataMolecule',
    //   defaults: [
    //     {
    //       elementType: [RepresentativeMoleculesType.All],
    //       contexts: [
    //         {
    //           keys: ['default'],
    //           setup: {
    //             events: ['init'],
    //             bus: [
    //               {
    //                 event: 'init',
    //                 receptor: 'value-output'
    //               }
    //             ]
    //           }
    //         }
    //       ]
    //     }
    //   ],
    //   parameters: [
    //     {
    //       type: 'input',
    //       name: 'paginate',
    //       label: 'Items Per Page',
    //       placeholder: 'Items Per Page',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false
    //     }
    //   ]
    // },
    {
      name: 'Get Cell Data',
      icon: 'pan_tool',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'GetCellDataElementMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Get Row Data',
      icon: 'pan_tool',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'GetRowDataElementsMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    // {
    //   name: 'Filter Data',
    //   icon: 'filter_list',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'GetValueFromRangeDataMolecule',
    //
    // defaults: [],
    //
    //   parameters: [
    //     {
    //       type: 'select',
    //       name: 'searchType',
    //       label: 'Search Type',
    //       placeholder: 'Search Type',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //     {
    //       type: 'input',
    //       name: 'index',
    //       label: 'Index',
    //       placeholder: 'Index',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //   ],
    // },
    // {
    //   name: 'Filter By Value',
    //   icon: 'filter_list',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    //   bypassGetDataElements: true,
    //   delayGetDataElements: false,
    //   type: 'FilterDataByValueMolecule',
    //   defaults: [
    //     {
    //       elementType: [RepresentativeMoleculesType.All],
    //       contexts: [
    //         {
    //           keys: ['default'],
    //           setup: {
    //             events: ['init'],
    //             bus: [
    //               {
    //                 event: 'init',
    //                 receptor: 'value-input'
    //               }
    //             ]
    //           }
    //         }
    //       ]
    //     }
    //   ],
    //   parameters: [
    //     {
    //       type: 'input',
    //       name: 'valueFilter',
    //       label: 'Value',
    //       placeholder: 'Value',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false
    //     }
    //   ]
    // },
    {
      name: 'Datasource Data Not Equals',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueNotEqualsAndMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Contains',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: true,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueContainsAndMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Equals',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueEqualsAndMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Not Contains',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: true,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueNotContainsAndMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Not Equals OR',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueNotEqualsOrMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Contains OR',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueContainsOrMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Equals OR',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueEqualsOrMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Not Contains OR',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueNotContainsOrMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Greater',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueGreaterAndMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Greater OR',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueGreaterOrMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Lesser OR',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueLesserOrMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Datasource Data Lesser',
      icon: 'filter_list',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: true,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'FilterDatasourceDataByValueLesserAndMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'valueFilter',
          label: 'Value',
          placeholder: 'Value',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    {
      name: 'Select File',
      icon: 'attach_file',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'SelectFileMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Accept Image File',
      icon: 'image',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'AcceptImageFileMolecule',
      
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      
      parameters: [],
    },
    {
      name: 'Accept PDF File',
      icon: 'picture_as_pdf',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'AcceptPDFFileMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Upload File',
      icon: 'cloud_upload',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'UploadFileMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    // {
    //   name: 'Get Cell Reference',
    //   icon: 'cloud_upload',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'GetCellReferenceMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    // {
    //   name: 'Filter Elements By Molecule',
    //   icon: 'filter_list',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'FilterElementsByMoleculeMolecule',
    //
    // defaults: [],
    //
    //   parameters: [
    //     {
    //       type: 'input',
    //       name: 'moleculeName',
    //       label: 'Molecule Name',
    //       placeholder: 'Molecule Name',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //   ],
    // },
    {
      name: 'Download File',
      icon: 'cloud_download',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'DownloadFileMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'input',
          name: 'filename',
          label: 'Filename',
          placeholder: 'Filename',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: false,
          isMultiple: false,
        },
        {
          type: 'checkbox',
          name: 'zip',
          label: 'Zip Files',
          placeholder: 'Zip Files',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: false,
          isMultiple: false,
        },
      ],
    },
    // {
    //   name: 'Can Populate',
    //   icon: 'all_inclusive',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'PopulatableMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    {
      name: 'Log User',
      icon: 'exit_to_app',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'LogUserMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Log AAD User',
      icon: 'exit_to_app',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'LogUserAADMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'LogOut User',
      icon: 'exit_to_app',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'LogOutUserMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Username Field',
      icon: 'person',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'LoginUsernameMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Password Field',
      icon: 'lock',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'LoginPasswordMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    {
      name: 'Password Masking',
      icon: 'lock_open',
      moleculeType: 'Behavior',
      advanced: true,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'PasswordMaskingMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['init'],
                bus: [
                  {
                    event: 'init',
                    receptor: 'value-input',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [],
    },
    // {
    //   name: 'Set Elements Value',
    //   icon: 'check',
    //   moleculeType: 'Behavior',
    //   advanced: true, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'SetElementsValueMolecule',
    //
    // defaults: [],
    //
    //   parameters: [],
    // },
    {
      name: 'Show Elements',
      icon: 'visibility',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'ShowRepresentativeMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'select',
          name: 'repMoleculeIds',
          label: 'Elements',
          placeholder: 'Drop Elements',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
        {
          type: 'checkbox',
          name: 'showMe',
          label: 'Show Me',
          placeholder: 'Show Me',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
      ],
    },
    {
      name: 'Get Element Value',
      icon: 'visibility',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: true,
      delayGetDataElements: false,
      type: 'GetRepresentativeMoleculeValue',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'select',
          name: 'repMoleculeIds',
          label: 'Element',
          placeholder: 'Drop Element',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: false,
        },
      ],
    },
    // {
    //   name: 'Select Workgroup',
    //   icon: 'visibility',
    //   moleculeType: 'Behavior',
    //   advanced: false, allowRunOnEditor: false,
    // bypassGetDataElements: false,
    //   type: 'SelectWorkgroupMolecule',
    //
    // defaults: [],
    //
    //   parameters: [
    //     {
    //       type: 'select',
    //       name: 'workgroupIds',
    //       label: 'Workgroup',
    //       placeholder: 'Select Workgroup',
    //       validation: [],
    //       tooltip: '',
    //       tooltipPosition: '',
    //       isRequired: true,
    //       isMultiple: false,
    //     },
    //   ],
    // },
    {
      name: 'Hide Elements',
      icon: 'visibility_off',
      moleculeType: 'Behavior',
      advanced: false,
      allowRunOnEditor: false,
      bypassGetDataElements: false,
      delayGetDataElements: false,
      type: 'HideRepresentativeMolecule',
      defaults: [
        {
          elementType: [RepresentativeMoleculesType.All],
          contexts: [
            {
              keys: ['default'],
              setup: {
                events: ['click'],
                bus: [
                  {
                    event: 'click',
                    receptor: 'value-output',
                  },
                ],
              },
            },
          ],
        },
      ],
      parameters: [
        {
          type: 'select',
          name: 'repMoleculeIds',
          label: 'Elements',
          placeholder: 'Drop Elements',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
        {
          type: 'checkbox',
          name: 'hideMe',
          label: 'Hide Me',
          placeholder: 'Hide Myself',
          validation: [],
          tooltip: '',
          tooltipPosition: '',
          isRequired: true,
          isMultiple: true,
        },
      ],
    },
  ];
  
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService, private toolsService: ToolsService) {
    super('MoleculeTemplates', http, errorMessengerService);
    
    // this.getLibrary().subscribe();
  }
  
  ShareTemplate(resource: any[]) {
    return this.http.post(this.apiEndpointUrl + `/Share`, resource).pipe(
      map((response) => <any>response),
      catchError((error) => this.errorMessengerService.HandleError(error, `Error sharing element.`, resource)),
    );
  }
  
  getLibrary() {
    return this.http.get(this.apiEndpointUrl + `/libraries`).pipe(
      map((response) => {
        this.Libraries = response as Library[];
        return <any>response;
      }),
      catchError((error) => this.errorMessengerService.HandleError(error, `Error getting library.`)),
    );
  }
  
  GetTemplateVersions(templateId: number) {
    return this.http.get(this.apiEndpointUrl + `/RelatedTemplates/` + templateId).pipe(
      map((response) => {
        return <any>response;
      }),
      catchError((error) => this.errorMessengerService.HandleError(error, `Error getting template version.`)),
    );
  }
  
  GetTemplate(templateId: number) {
    return this.http.get(this.apiEndpointUrl + '/' + templateId).pipe(
      map(response => response as any),
      catchError((error) => this.errorMessengerService.HandleError(error, `Error getting template.`)),
    );
  }
  
  UpdateTemplates(downloadTemplates = true) {
    if (this.RunningMode) {
      return;
    }
    
    const templates = this.GetTemplatesObject();
    
    if (downloadTemplates) {
      this.toolsService.ExportToTextFile('leapxl-templates', templates);
    }
    // console.log('updating templates...', properties);
    
    return this.http
    .post(this.apiEndpointUrl, templates)
    .pipe(catchError((error) => this.errorMessengerService.HandleError(error, `Error initializing DB`)));
  }
  
  GetTemplatesObject() {
    const molecules = [
      this.ActionMoleculesTemplates,
      compoundTemplates,
      buttonTemplate,
      breadcrumbTemplate,
      badgeTemplate,
      iconTemplate,
      datepickerTemplate,
      dropdownTemplate,
      h1Template,
      h2Template,
      h3Template,
      h4Template,
      h5Template,
      labelTemplate,
      textboxTemplate,
      textareaTemplate,
      iframeTemplate,
      workgroupTemplate,
      cobbleTemplate,
      chartTemplate,
      imageTemplate,
      checkboxTemplate,
      tableTemplate,
      radioTemplate,
      qrCodeTemplate,
      stepperTemplate,
      sliderTemplate,
      progressTemplate,
    ];
    
    const templates: any[] = [];
    
    molecules.forEach((molecule: any[]) => {
      molecule.forEach((template) => {
        const templateProperties = [];
        Object.keys(template).forEach((propertie) => {
          templateProperties.push({
            name: propertie,
            value: template[propertie],
          });
        });
        templates.push(templateProperties);
      });
    });
    
    return templates;
  }
  
  GetActionMoleculeProperties(moleculeNames?: string[]): Observable<ActionMoleculeProperties[]> {
    let result: any;
    result = this.ActionMoleculesTemplates;
    if (moleculeNames) {
      result = this.ActionMoleculesTemplates.filter((m) => moleculeNames.includes(m.type));
      
      if (result && result.length > 0) {
        // found
      } else {
        const compatibilityMolecule = this.RetrocompatibilityMolecules[moleculeNames[0]];
        result = this.ActionMoleculesTemplates.filter((amt) => amt.type === compatibilityMolecule);
      }
    }
    
    return of(result);
  }
  
  GetActionMoleculePropertie(moleculeName: string): ActionMoleculeProperties {
    return this.ActionMoleculesTemplates.find((m) => moleculeName.includes(m.type));
  }
  
  DeleteComponent(componentId: number) {
    return this.http.delete(this.apiEndpointUrl + `/RemoveComponent/` + componentId, {}).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error removing component ${ name }.`, {
          name: name,
        }),
      ),
    );
  }
  
  DeleteTemplate(templateId: number) {
    return this.http.delete(this.apiEndpointUrl + `/` + templateId, {}).pipe(
      map((response) => <any>response),
      catchError((error) =>
        this.errorMessengerService.HandleError(error, `Error removing template ${ templateId }.`, {
          id: templateId,
        }),
      ),
    );
  }
  
  GetRepresentativeTemplateByType(repMoleculeType: string) {
    const representativeTemplates = {
      Button: buttonTemplate,
      Breadcrumb: breadcrumbTemplate,
      Badge: badgeTemplate,
      WorkGroup: workgroupTemplate,
      Textbox: textboxTemplate,
      Textarea: textareaTemplate,
      Label: labelTemplate,
      Datepicker: datepickerTemplate,
      Dropdown: dropdownTemplate,
      H1: h1Template,
      H2: h2Template,
      H3: h3Template,
      H4: h4Template,
      H5: h5Template,
      Chart: chartTemplate,
      Image: imageTemplate,
      Iframe: iframeTemplate,
      Checkbox: checkboxTemplate,
      Table: tableTemplate,
      Icon: iconTemplate,
      Radio: radioTemplate,
      QrCode: qrCodeTemplate,
      Stepper: stepperTemplate,
      Progress: progressTemplate,
      Slider: sliderTemplate,
      Custom: customTemplate,
    };
    
    const template = representativeTemplates[repMoleculeType];
    
    return template ? template[0] : null;
  }
  
  CreateCompound(properties: any[]) {
    return this.http.post(this.apiEndpointUrl + `/AddCompoundMolecule`, properties).pipe(
      map((response) => <any>response),
      catchError((error) => this.errorMessengerService.HandleError(error, `Error creating Compound.`, properties)),
    );
  }
  
  ChangeMoleculeTemplate(repMoleculeId: number, templateId: number) {
    return this.http.post(this.apiEndpointUrl + `/ChangeMoleculeTemplate`, { moleculeId: repMoleculeId, newTemplateId: templateId }).pipe(
      map((response) => <any>response),
      catchError((error) => this.errorMessengerService.HandleError(error, `Error switching template`, templateId)),
    );
  }
  
  GetMoleculeTemplateById(id: number): {
    icon: string;
    id: number;
    moleculeType: string;
    name: string;
    type: string;
  } {
    let template = null;
    
    for (let lIndex = 0; lIndex < this.Libraries.length; lIndex++) {
      for (let sIndex = 0; sIndex < this.Libraries[lIndex].sections.length; sIndex++) {
        template = this.Libraries[lIndex].sections[sIndex].templates.find((t) => t.id === id);
        if (template) {
          return template;
        }
      }
    }
  }
  
  GetMoleculeTemplateByMoleculeType(moleculeType: string): {
    icon: string;
    id: number;
    moleculeType: string;
    name: string;
    type: string;
  } {
    let template = null;
    
    for (let lIndex = 0; lIndex < this.Libraries.length; lIndex++) {
      for (let sIndex = 0; sIndex < this.Libraries[lIndex].sections.length; sIndex++) {
        template = this.Libraries[lIndex].sections[sIndex].templates.find((t) => t.type === moleculeType);
        if (template) {
          return template;
        }
      }
    }
  }
  
  GetEditablePropertiesForRepresentativeMoleculeType(repMoleculeType: string) {
    const template = this.GetRepresentativeTemplateByType(repMoleculeType);
    return template.editableProperties;
  }
  
  CreateCustomControlTemplate(customControlTemplate: any, parentTemplateId: number = null) {
    const templateProperties = [];
    Object.keys(customControlTemplate).forEach((propertie) => {
      templateProperties.push({
        name: propertie,
        value: customControlTemplate[propertie],
      });
    });
    
    const customControlTemplateProperties = {
      templateId: parentTemplateId,
      templateProperties,
    };
    
    return this.http
    .post(this.apiEndpointUrl + '/CustomControl', customControlTemplateProperties)
    .pipe(catchError((error) => this.errorMessengerService.HandleError(error, `Error creating custom control`)));
  }
}
