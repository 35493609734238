export const stepperTemplate = [
  {
    icon: 'switch_left',
    moleculeType: 'Representative',
    name: 'Stepper',
    type: 'Stepper',
    moleculesAllow: ['Representative', 'Cobblet'],
    receptors: [
      'value-input',
      'select-step-input',
      'select-step-output',
      'value-output',
      'tooltip-input',
      'badge-value-input',
      'badge-value-output',
      'none',
    ],
    buses: [],
    minValue: null,
    maxValue: null,
    dataSource: {},
    styleMetadata: {
      styles: [],
      specificStyles: {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      },
      manualAdjustedProperties: [],
    },
    events: ['init', 'step-selected', 'hover', 'data-received'],
    defaultEvent: 'init',
    creationFromTemplate: false,
    editableProperties: ['cols', 'rows', 'x', 'y', 'font', 'opacity', 'shadow', 'borders', 'backgroundType',
      'backgroundType'],
    properties: {
      align: 'left', horizontalPadding: 0,
      verticalPadding: 0,
      wrapText: false,
      alwaysRenderComponent: false,
      progressType: 'progress',
      progressColor: '#EA5C2F',
      progressBackgroundColor: '#E5957C',
      progressDiameter: 50,
      progressMode: 'indeterminate',
      progressStrokeWidth: 5,
      sliderVertical: false,
      sliderStep: 1,
      sliderThumbLabel: false,
      sliderTicks: false,
      sliderColor: '#EA5C2F',
      location: null,
      lockWidth: false,
      lockHeight: false,
      lockImageSize: false,
      originalQuality: false,
      selectFirstOption: true,
      originalSize: false,
      allowEmptyValues: false,
      viewNames: [],
      hover: {
        hoverBackground: 'white',
        hoverBackgroundOpacity: 1,
        hoverBorderRadius: 5,
        hoverBorderStyle: 'none',
        hoverBorderColor: 'transparent',
        hoverBorderWidth: 0,
        hoverTextDecoration: null,
        hoverPointer: null,
      },
      options: [
        {
          value: 'value 1',
          text: 'Text 1',
        },
      ],
      show: true,
      opacity: 1,
      orientation: 'col',
      margin: 3,
      enable: true,
      badge: {
        badgeEnable: true,
        badgeBackgroundColor: '#f22525',
        badgeFontFamily: 'Source Sans Pro',
        badgeFontColor: 'white',
        badgeStyle: 'circle',
        badgePosition: 'right',
      },
      maskType: 'none',
      mask: '',
      altText: '',
      dateRange: false,
      search: false,
      multiSelect: false,
      autoDataRefresh: false,
      ignoreValueDataIndex: false,
      textDecoration: 'none',
      inOutAnimation: 'slideUp',
      showAltText: true,
      offsetRows: 26,
      offsetCols: 0,
      stepper: {
        stepperScrollbars: false,
        stepperLabelPosition: 'end',
        stepperSequential: false,
        stepperOrientation: 'horizontal',
      },
      responsive: {
        desktop: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: 0,
          cols: 130,
          rows: 80,
          colsQty: 130,
          rowsQty: 54,
          layer: 1,
          centerPositioning: false,
          x: 0,
          y: 0,
          font: {
            fontSize: 16,
            fontColor: '#000000',
            fontFamily: 'inherit',
            fontStyle: 'inherit',
            fontWeight: 'normal',
          },
        },
        smartphone: {
          customGuidelines: {
            x: [],
            y: [],
          },
          id: null,
          cols: null,
          rows: null,
          colsQty: null,
          rowsQty: null,
          layer: null,
          centerPositioning: false,
          x: null,
          y: null,
          font: {
            fontSize: null,
            fontColor: null,
            fontFamily: null,
            fontStyle: null,
            fontWeight: null,
          },
        },
      },
      name: 'Stepper',
      legend: true,
      loadingText: '',
      background: {
        backgroundColor: '#ffffff',
        backgroundTypeImage: false,
        backgroundImageUrl: '',
        backgroundOpacity: 1,
      },
      bordersValues: {
        borderWidth: 1,
        borderStyle: 'none',
        borderColor: '#212529',
        borderRadius: 5,
      },
      shadowValues: {
        hShadow: 0,
        vShadow: 0,
        shadowColor: '#212529',
        shadowBlur: 0,
      },
      preferences: {},
      dragEnabled: true,
      resizeEnabled: true,
      view: 1,
      chartLibrary: 'ngxCharts',
      chartType: 'line',
      tabindex: 0,
      textToDisplay: '',
      tooltip: '',
      placeholder: '',
      tableOrder: [],
      minItemCols: 5,
      maxItemCols: 1000,
    },
    molecules: [],
    placeholders: [],
    isSelected: false,
    enable: true,
    value: null,
    children: [],
    replaceableByRepresentative: false,
    loading: false,
    autoGenerationInfo: [],
    moleculeSubType: 'Stepper',
    optionsMenu: {
      configuration: true,
      molecules: true,
      compound: false,
      cobblet: true,
      remove: true,
      versioning: true,
      dataSource: false,
      duplicate: false,
    },
  },
];
