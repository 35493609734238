import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/components/confirm-dialog/confirm-dialog.component';
import { InputsDialogComponent } from '../../shared/components/inputs-dialog/inputs-dialog.component';

@Injectable()
export class GenericDialogService {
  dialogRef: MatDialogRef<any>;
  dialogChanged: Promise<any>;
  
  constructor(public dialog: MatDialog) {
  }
  
  openDialog(data: any, dialogComponent: any): Promise<any> {
    this.dialogChanged = new Promise((resolve, reject) => {
      this.dialogRef = this.dialog.open(dialogComponent, { data: data });
      this.dialogRef.afterClosed().subscribe(result => {
        return resolve(result);
      });
    });
    
    return this.dialogChanged;
  }
  
  closeDialog() {
    this.dialogRef.close();
  }
  
  OpenFormDialog(data: {
    title: string;
    message?: string;
    confirmText: string;
    cancelText: string;
    centerText?: boolean;
    inputData: {
      inputKey: string,
      inputLabel: string
    }[]
  }) {
    return this.openDialog(data,
      InputsDialogComponent,
    );
  }
  
  OpenConfirmDialog(data: {
    title: string;
    message: string;
    confirmText: string;
    cancelText: string;
    maxWidth?: string;
    height?: string;
    fontSize?: string;
    centerText?: boolean;
  }) {
    return this.openDialog(data,
      ConfirmDialogComponent,
    );
  }
}
