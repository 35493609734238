import { Constants } from '../../constants';
import { DatasourceType } from '../../enums/datasource-type.enum';
import { ParticleType } from '../../enums/particle-type.enum';
import { Particle } from './particle';

export class DataElement extends Particle {
  ParticleType = ParticleType.DataElement;
  TranslationId: number;
  Context: string;
  DataSourceType: DatasourceType;
  DataSourceId: number;
  DataSourceName: string;
  Collection: string;
  InternalName: string;
  Reference: string;
  RangeParticleId: string;
  ApplicationId: number;
  Required: boolean;
  IsTemplate: boolean;
  IsVolunteer: boolean;
  Domain: string;
  SubDomain: string;
  SourceTrackRepMoleculeId: number;
  SourceTrackRepMoleculeName: string;
  SourceTrackRepMoleculeIcon: string;
  SourceTrackEventName: string;
  IsRoot: boolean;
  
  constructor(dataElement: any = {}) {
    super(dataElement.particleId || dataElement.ParticleId);
    this.AssingId(dataElement.id || dataElement.Id);
    
    this.TranslationId =
      dataElement.translationId || dataElement.TranslationId || 0;
    this.Context = dataElement.context || dataElement.Context || '';
    this.Touched = dataElement.touched === true || dataElement.Touched === true;
    this.DataSourceType =
      dataElement.dataSourceType || dataElement.DataSourceType || '';
    this.DataSourceId =
      dataElement.dataSourceId || dataElement.DataSourceId || null;
    this.DataSourceName =
      dataElement.dataSourceName || dataElement.DataSourceName || '';
    const collection = dataElement.collection || dataElement.Collection;
    this.Collection = collection && collection !== '' ? collection : this.GetCollectionFromContext();
    this.TriggeredByBusId = dataElement.triggeredByBusId || dataElement.TriggeredByBusId || '';
    this.Required = dataElement.required || dataElement.Required || false;
    this.InternalName =
      dataElement.internalName || dataElement.InternalName || '';
    this.Reference = dataElement.reference || dataElement.Reference || '';
    this.RangeParticleId =
      dataElement.RangeParticleId || dataElement.rangeParticleId || '';
    this.ApplicationId =
      dataElement.applicationId || dataElement.ApplicationId || 0;
    this.Priority = dataElement.priority || dataElement.Priority || 0;
    this.AvoidRun = dataElement.avoidRun || dataElement.AvoidRun || false;
    this.IsTemplate = dataElement.isTemplate || dataElement.IsTemplate || false;
    this.IsVolunteer = dataElement.isVolunteer || dataElement.IsVolunteer || false;
    this.Domain = this.GetDomain();
    this.SubDomain = this.GetSubDomain();
  }
  
  GetDomain() {
    const splitContext = this.Context.split(Constants.ContextSeparator);
    
    const domain = [this.DataSourceType.toString()];
    domain.push(splitContext[0] === this.DataSourceType.toString() ? splitContext[1] : splitContext[0]);
    
    return domain.join(Constants.ContextSeparator);
  }
  
  GetDataSourceName() {
    const splitContext = this.Context.split(Constants.ContextSeparator);
    return splitContext[0] === this.DataSourceType.toString() ? splitContext[1] : splitContext[0];
  }
  
  GetSubDomain() {
    const correctedContext = this.CorrectedContext();
    this.IsRoot = correctedContext.length === 2;
    return `${ this.Domain }${ Constants.ContextSeparator }${ this.Collection || correctedContext[2] || '' }`;
  }
  
  IsSpreadsheetSameSubdomain(dataElement: DataElement) {
    return dataElement.DataSourceType === DatasourceType.Spreadsheet && dataElement.DataSourceType === DatasourceType.Spreadsheet && this.GetSubDomain() === dataElement.GetSubDomain();
  }
  
  public Clone() {
    return new DataElement({
      TranslationId: this.TranslationId,
      Context: this.Context,
      DataSourceType: this.DataSourceType,
      DataSourceId: this.DataSourceId,
      DataSourceName: this.DataSourceName,
      Collection: this.Collection,
      TriggeredByBusId: this.TriggeredByBusId,
      Required: this.Required,
      InternalName: this.InternalName,
      Reference: this.Reference,
      RangeParticleId: this.RangeParticleId,
      ApplicationId: this.ApplicationId,
      ParticleId: this.ParticleId,
      IsVolunteer: this.IsVolunteer,
      Priority: this.Priority,
      IsTemplate: this.IsTemplate,
      id: this.id,
    });
  }
  
  public CloneAndAssignIds(): DataElement {
    const newDataElement = this.Clone();
    newDataElement.AssingId();
    newDataElement.AssingParticleId();
    return newDataElement;
  }
  
  GetName(): string {
    return this.Context;
  }
  
  GetCollectionFromContext() {
    return this.toolsService.BreakContext(this.Context).collection;
  }
  
  FromContext(context: string, datasourceId: number = 0) {
    const breakedContext = this.toolsService.BreakContext(context);
    this.ApplicationId = this.cobbleService.Cobble.id;
    this.Context = breakedContext.context;
    this.DataSourceType = breakedContext.dataSourceType as any;
    this.Collection = breakedContext.collection;
    this.InternalName = breakedContext.internalName;
    this.Reference = breakedContext.reference;
    this.DataSourceName = breakedContext.dataSourceName;
    this.DataSourceId = datasourceId;
    this.Domain = this.GetDomain();
    this.SubDomain = this.GetSubDomain();
    return this;
  }
  
  GetDisplayValues(): string {
    return this.TranslationId.toString();
  }
  
  SplitSpreadsheetReferences(): {
    firstCol: number;
    lastCol: number;
    firstRow: number;
    lastRow: number;
  } {
    const separatedContext = this.Context.split(Constants.ContextSeparator);
    const reference = separatedContext[separatedContext.length - 1];
    const references = reference.split(':');
    this.toolsService = this.toolsService || this.injector.get(this.toolsService);
    
    if (references.length === 1) {
      references.push(references[0]);
    }
    const firstCol = this.toolsService.ColumnNameToIndex(references[0].replace(/[0-9]/g, '')) - 1;
    const lastCol = this.toolsService.ColumnNameToIndex(references[1].replace(/[0-9]/g, '')) - 1;
    const firstRow = +references[0].replace(/\D/g, '') - 1;
    const lastRow = +references[1].replace(/\D/g, '') - 1;
    
    return {
      firstCol: firstCol > 10000 ? 0 : firstCol,
      lastCol: lastCol > 10000 ? 0 : lastCol,
      firstRow: firstRow < 0 ? 0 : firstRow,
      lastRow: lastRow < 0 ? 0 : lastRow,
    };
  }
  
  HighlightDatasourcePathOnEditor() {
    const highlightData = {
      context: this.Context,
      dataSourceId: this.DataSourceId,
      dataSourceName: this.DataSourceName,
      spreadsheetReferences: null,
    };
    
    switch (this.DataSourceType) {
      case DatasourceType.Api:
      case DatasourceType.Database:
      case DatasourceType.LeapXL:
      case DatasourceType.System:
      case DatasourceType.UnifiedDatabase:
      case DatasourceType.InternetMessaging:
        break;
      case DatasourceType.Spreadsheet:
        const spreadSheetReferences = this.SplitSpreadsheetReferences();
        highlightData.spreadsheetReferences = {
          firstRow: +spreadSheetReferences.firstRow,
          firstCol: spreadSheetReferences.firstCol,
          lastRow: +spreadSheetReferences.lastRow,
          lastCol: spreadSheetReferences.lastCol,
          collection: this.Collection,
          dataSourceId: this.DataSourceId,
          show: true,
        };
        break;
      default:
        break;
    }
    this.communicationService.Event.Editor.DataSource.$Highlight.emit(highlightData);
  }
  
  HighlightDatasourceOnSpreadhseet() {
    const highlightData = {
      dataSourceType: this.Context.split(Constants.ContextSeparator)[0],
      context: this.Context,
      dataSourceId: this.DataSourceId,
      dataSourceName: this.DataSourceName,
      spreadsheetReferences: null,
    };
    
    switch (this.DataSourceType) {
      case DatasourceType.Api:
      case DatasourceType.Database:
      case DatasourceType.LeapXL:
      case DatasourceType.InternetMessaging:
      case DatasourceType.Spreadsheet:
        const spreadSheetReferences = this.SplitSpreadsheetReferences();
        highlightData.spreadsheetReferences = {
          firstRow: +spreadSheetReferences.firstRow,
          firstCol: spreadSheetReferences.firstCol,
          lastRow: +spreadSheetReferences.lastRow,
          lastCol: spreadSheetReferences.lastCol,
          collection: this.Collection,
          dataSourceId: this.DataSourceId,
          dataSourceName: this.DataSourceName,
          show: true,
        };
        this.communicationService.Event.Editor.DataSource.$HighlightOnSpreadsheetPanel.emit(highlightData);
        break;
      case DatasourceType.System:
      case DatasourceType.UnifiedDatabase:
      default:
        break;
    }
  }
  
  GetSourceContext(): string {
    const spplitedContext = this.Context.split(Constants.ContextSeparator);
    spplitedContext.pop();
    return spplitedContext.join(Constants.ContextSeparator);
  }
  
  HaveSpecificContext(context: string): boolean {
    return this.Context === context;
  }
  
  IncludesContext(context: string): boolean {
    return this.Context.includes(context);
  }
  
  GetRawObject(): any {
    
    const object = {
      id: this.id,
      ParticleType: this.ParticleType,
      ParticleId: this.ParticleId,
      TranslationId: this.TranslationId,
      Context: this.Context,
      DataSourceType: this.DataSourceType,
      DataSourceId: this.DataSourceId,
      Priority: this.Priority,
      DataSourceName: this.DataSourceName,
      Collection: this.Collection,
      TriggeredByBusId: this.TriggeredByBusId,
      InternalName: this.InternalName,
      Reference: this.Reference,
      IsVolunteer: this.IsVolunteer,
      RangeParticleId: this.RangeParticleId,
      ApplicationId: this.ApplicationId,
      IsTemplate: this.IsTemplate,
      Touched: this.Touched,
    };
    
    return object;
  }
  
  FromRange(firstDataElementRange: DataElement, lastDataElementRange: DataElement): DataElement[] {
    const rangeDataElements = [];
    const range = `${ firstDataElementRange.Reference }:${ lastDataElementRange.Reference }`;
    const separatedRange = this.toolsService.SeparateRangeReference(range);
    const firstColIndex = this.toolsService.ColumnNameToIndex(separatedRange.firstColumn);
    const lastColIndex = this.toolsService.ColumnNameToIndex(separatedRange.lastColumn);
    
    for (let i = firstColIndex; i <= lastColIndex; i++) {
      const newDataElement = new DataElement(firstDataElementRange);
      const oldReference = firstDataElementRange.Reference;
      const newReference = oldReference.replace(this.toolsService.ColumnIndexToName(firstColIndex), this.toolsService.ColumnIndexToName(i));
      newDataElement.AssingId();
      newDataElement.AssingParticleId();
      newDataElement.TranslationId = null;
      newDataElement.Reference = newReference;
      newDataElement.InternalName = newReference;
      newDataElement.Context = newDataElement.Context.replace(oldReference, newReference);
      rangeDataElements.push(newDataElement);
    }
    
    return rangeDataElements;
  }
  
  private CorrectedContext() {
    const splitContext = this.Context.split(Constants.ContextSeparator);
    
    const cc = [this.DataSourceType.toString()];
    cc.push(splitContext[0] === this.DataSourceType.toString() ? splitContext[1] : splitContext[0]);
    
    const collection = this.Collection && this.SubDomain !== '' ? this.Collection : splitContext[0] === this.DataSourceType.toString() ? splitContext[2] : splitContext[1];
    if (collection) {
      cc.push(collection);
    }
    return cc;
  }
}
