import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { cloneDeep, pick } from 'lodash-es';
import { Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BusService } from '../../core/molecular/services/bus.service';
import { ApiDataService } from '../../core/services/api-data.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { LocalStorageService } from '../../core/services/local-storage.service';
import { ToolsService } from '../../core/services/tools.service';
import { Constants } from '../../shared/constants';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { CrossOriginIframeService } from '../../shared/services/cross-origin-iframe.service';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { WindowStoreService } from '../../shared/services/window-store.service';

@Injectable()
export class RuntimeService extends ApiDataService implements OnInit, OnDestroy {
  topBarTitle: string;
  ActualViewId: number;
  ViewRepMoleculesInitialized = [];
  ChangingView = false;
  ViewEventsQueue = [];
  ViewNavigationHistory = [];
  ViewNavigationBreadcrumbs = [];
  ViewNavigationCompleteHistory = [];
  InitialView = 0;
  ProductionApp = false;
  DebugRequestLogs: IDebugRequestLog[] = [];
  DebugRequestLogsCounter = 0;
  loadingOverlay: {
    iconAnimated: boolean;
    display: boolean;
    showSpinner: boolean;
    spinnerType: 'bar' | 'spin';
    message: string;
    icon: string;
    iconColor: string;
  } = {
    iconAnimated: true,
    display: false,
    showSpinner: true,
    spinnerType: 'spin',
    message: '',
    icon: '',
    iconColor: 'black',
  };
  EventsDS = {};
  
  jsDependenciesLoaded = {};
  
  private RuntimeId = '';
  private AppInfo = null;
  private subscriptions: Subscription = new Subscription();
  
  constructor(
    http: HttpClient,
    errorMessengerService: ErrorMessengerService,
    protected cobbleService: CobbleService,
    private busService: BusService,
    private toolsService: ToolsService,
    private windowStoreService: WindowStoreService,
    private crossOriginIframeService: CrossOriginIframeService,
    private communicationService: CommunicationService,
    private clientStorageService: ClientStorageService,
    private localStorageService: LocalStorageService,
  ) {
    super('molecules', http, errorMessengerService);
    
    this.subscriptions.add(
      this.communicationService.Event.Runtime.System.$ShowLoadingOverlay.subscribe((options: any) => {
        console.log('=event=');
        this.ShowLoadingOverlay(options);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Runtime.System.$HideLoadingOverlay.subscribe((options: any) => {
        console.log('=event=');
        this.HideLoadingOverlay();
      }),
    );
  }
  
  get ActualViewName() {
    return this.cobbleService.Cobble.properties.views.find(v => v.id === this.ActualViewId).name;
  }
  
  ngOnInit(): void {
  }
  
  ShowLoadingOverlay(options: {
    display: boolean;
    iconAnimated: boolean;
    showSpinner: boolean;
    spinnerType: 'bar' | 'spin';
    message: string;
    icon: string;
    iconColor: string;
  }) {
    this.loadingOverlay = options;
    this.loadingOverlay.display = true;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.communicationService.Event.System.App.$RefreshUI.emit();
  }
  
  HideLoadingOverlay() {
    this.loadingOverlay.display = false;
    this.communicationService.Event.Editor.$WorkAreaDetection.emit(true);
    this.communicationService.Event.System.App.$RefreshUI.emit();
  }
  
  GetSessionId() {
    const sessionId = this.localStorageService.Get(Constants.SessionId);
    return sessionId || '';
  }
  
  UpdateViewsMolecules() {
    if (!this.cobbleService.Cobble.running) {
      return;
    }
    const molecules = this.busService.GetViewWorkgroupsMolecules(this.cobbleService.Cobble, this.ActualViewId);
    
    molecules.forEach(molecule => {
      this.busService.DirectChildrenElements(molecule.Id).forEach(child => {
        // console.log('molecules to update', child);
        child.$UpdateValue.emit();
      });
    });
  }
  
  AddEventToQueue(event: LeapXLEvent) {
    this.ViewEventsQueue.push(event);
  }
  
  ReleaseQueueEvents() {
    this.ViewEventsQueue.forEach(event => {
      this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(event);
    });
    
    this.ViewEventsQueue = [];
  }
  
  GetRuntimeInformation() {
    const runtimeInformation = {
      ...this.AppInfo,
      ...{
        url: window.location.href,
      },
      ...{
        appRuntimeId: this.AppRuntimeId(),
      },
      ...this.toolsService.GetSystemInformation(),
      ...this.clientStorageService.getUserInfo(),
    };
    
    delete runtimeInformation.clientInformation;
    return runtimeInformation;
  }
  
  GenerateAppRuntimeId(app = null) {
    this.AppInfo = app;
    this.RuntimeId = this.toolsService.GenerateGuid();
    this.windowStoreService.Store('appRuntimeId', this.RuntimeId);
  }
  
  CustomControlsConnector() {
    const triggerEvent = (repMoleculeId, eventName, data) => {
      console.log('connected');
      const repMolecule = this.busService.Get(repMoleculeId);
      
      this.communicationService.Event.Runtime.MolecularEngine.$LeapXLEvent.emit(new LeapXLEvent({
        particleId: this.toolsService.GenerateGuid(),
        id: this.toolsService.GenerateGuid(),
        sourceId: repMolecule.Id,
        eventName: `${ repMolecule.Properties.name } - ${ eventName.toUpperCase() }`,
        eventType: eventName,
        eventSource: 'Molecule',
        data: this.ConvertDataToSend(data, eventName),
        triggeredByUser: true,
      }));
    };
    
    const registerReceptor = (repMoleculeId, receptorName, callback) => {
      const customReceptorsDomain = 'custom-receptors';
      const customReceptors = this.windowStoreService.Read(customReceptorsDomain) || {};
      customReceptors[`${ repMoleculeId }-${ receptorName }`] = callback;
      
      this.windowStoreService.Store(customReceptorsDomain, customReceptors);
    };
    
    this.windowStoreService.Store('TriggerEvent', triggerEvent);
    this.windowStoreService.Store('RegisterControlReceptor', registerReceptor);
  }
  
  CreateDataElement(value, index, context, event) {
    return {
      affectedBy: [],
      affectingTo: [],
      col: index,
      comment: null,
      context: 'CustomControl::' + 'CustomEvent' + '::' + (context === '' ? '' : context + '::') + event,
      formattedValue: value,
      formula: null,
      row: 1,
      value: value,
    };
  }
  
  GetObjectType(obj) {
    return (
      Object.prototype.toString.call(obj).replace(/^\[object (.+)\]$/, '$1').toLowerCase() || ''
    );
  }
  
  ExtractStringData(obj) {
    switch (this.GetObjectType(obj)) {
      case 'string':
      case 'number':
        return obj.toString();
      case 'array':
        
        if (obj.length > 0) {
          return this.ExtractStringData(obj[0]);
        } else {
          return '';
        }
        break;
      case 'object':
        return this.ExtractStringData(obj[Object.keys(obj)[0]]);
      default:
        return '';
    }
  }
  
  ConvertDataToSend(data, evenType) {
    
    switch (this.GetObjectType(data)) {
      case 'string':
      case 'number':
        return [this.CreateDataElement(this.ExtractStringData(data), 1, '', evenType)];
      case 'array':
        if (data.length > 0) {
          var dataElements = [];
          for (let i = 0; i < data.length; i++) {
            const arrayValue = data[i];
            dataElements.push(this.CreateDataElement(this.ExtractStringData(arrayValue), i + 1, i, evenType));
          }
          return dataElements;
        } else {
          return [this.CreateDataElement('', 1, '', evenType)];
        }
        break;
      case 'object':
        return [this.CreateDataElement(JSON.stringify(data), 1, '', evenType)];
        var dataElements = [];
        var keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
          const objectValue = data[keys[i]];
          dataElements.push(this.CreateDataElement(this.ExtractStringData(objectValue), i + 1, keys[i], evenType));
        }
        return dataElements;
      default:
        return [this.CreateDataElement('', 1, '', evenType)];
    }
  }
  
  AppRuntimeId() {
    return this.RuntimeId;
  }
  
  AppId() {
    let appId = this.AppInfo === null ? 0 : this.AppInfo.id;
    
    if (this.cobbleService && this.cobbleService.Cobble) {
      appId = this.cobbleService.Cobble.id;
    }
    
    return appId.toString();
  }
  
  GetAppByCompanyAndAppSlug(companySlug: string, appSlug: string) {
    return this.http
    .get(
      this.apiEndpointUrl + `/ApplicationMolecules/${ this.toolsService.SanitizeString(companySlug) }/${ this.toolsService.SanitizeString(appSlug) }`,
    )
    .pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error getting App by Slug: ${ appSlug }.`, appSlug)),
    );
  }
  
  GetCobblePublishedByGuid(guid: string) {
    return this.http.get(this.apiEndpointUrl + `/publishedcobble/guid/${ guid }`).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error)),
    );
  }
  
  EmulatorProcessorStart(data: any) {
    try {
      const processData = {
        repMolecule: pick(data.processData.repMolecule, ['Properties', 'Type', 'Id', 'Icon']),
        event: data.processData.event.GetRawObject(),
        bus: data.processData.bus.GetRawObject(),
        busKey: data.processData.busKey,
      };
      
      const clonedData = {
        userId: data.userId,
        appId: data.appId,
        processData,
      };
      
      this.crossOriginIframeService.SendToParent(this.crossOriginIframeService.MessageType.EmulatorEventsFlow, {
        type: 'emulatorProcessorStart',
        data: clonedData,
      });
    } catch (e) {
      console.log(e);
    }
  }
  
  EmulatorProcessorStop(data: any) {
    try {
      const processData = {
        repMolecule: pick(data.processData.repMolecule, ['Properties', 'Type', 'Id', 'Icon']),
        data: data.processData.data,
        particlesChannel: {
          bus: data.processData.particlesChannel.bus.GetRawObject(),
          data: data.processData.particlesChannel.data,
        },
        key: data.processData.key,
      };
      
      const clonedData = {
        userId: data.userId,
        appId: data.appId,
        processData,
      };
      
      this.crossOriginIframeService.SendToParent(this.crossOriginIframeService.MessageType.EmulatorEventsFlow, {
        type: 'emulatorProcessorStop',
        data: clonedData,
      });
    } catch (e) {
      console.log(e);
    }
  }
  
  EmulatorParticleProcessed(data: any) {
    try {
      const clonedData = cloneDeep(data);
      this.crossOriginIframeService.SendToParent(this.crossOriginIframeService.MessageType.EmulatorEventsFlow, {
        type: 'emulatorParticleProcessed',
        data: clonedData,
      });
    } catch (e) {
      console.log(e);
    }
  }
  
  GoToPreviousView() {
    if (this.ViewNavigationHistory.length > 0) {
      this.RemoveViewFromHistory();
      this.communicationService.Event.Runtime.System.$ViewIdChanged.emit(
        this.ViewNavigationHistory.length === 0 ? this.InitialView : this.ViewNavigationHistory.pop(),
      );
    }
  }
  
  RemoveViewFromHistory() {
    const viewId = this.ViewNavigationHistory.pop();
    this.ViewNavigationCompleteHistory.push(viewId);
    this.NavigateBreadcrumb(viewId);
  }
  
  AddViewToHistory(viewId: number) {
    this.ViewNavigationHistory.push(viewId);
    this.ViewNavigationCompleteHistory.push(viewId);
    this.NavigateBreadcrumb(viewId);
  }
  
  NavigateBreadcrumb(viewId: number) {
    const existsIndex = this.ViewNavigationBreadcrumbs.findIndex(v => v === viewId);
    if (existsIndex > -1) {
      this.ViewNavigationBreadcrumbs.length = existsIndex + 1;
    } else {
      this.ViewNavigationBreadcrumbs.push(viewId);
    }
    console.log('breadcrumbs: ', this.ViewNavigationBreadcrumbs);
  }
  
  AddRequestLog(
    repMoleculeId: string,
    repMoleculeName: string,
    requestName: string,
    data: any,
    response: any,
    requestStart: string,
    requestDuration: string,
  ) {
    this.DebugRequestLogsCounter++;
    
    this.DebugRequestLogs.unshift({
      Sequence: this.DebugRequestLogsCounter,
      RepMoleculeId: repMoleculeId,
      RepMoleculeName: repMoleculeName,
      RequestName: requestName,
      RequestStart: requestStart,
      RequestDuration: requestDuration,
      Data: data,
      Response: response,
    });
  }
  
  ClearRequestLogs() {
    this.DebugRequestLogs = [];
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  
  private SendMessage(data: any, type: string) {
    return this.http.post(this.apiUrl + 'messages/send', {
      data: data,
      type: type,
      url: '',
      icon: '',
      description: '',
    });
  }
}

export interface IDebugRequestLog {
  RepMoleculeId: string;
  RepMoleculeName: string;
  Sequence: number;
  RequestName: string;
  RequestStart: string;
  RequestDuration: string;
  Data: any;
  Response: any;
}
