import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDatepicker, MatDateRangePicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { BuilderService } from '../../../../core/builder/builder.service';
import { ProcessorService } from '../../../../core/molecular/services/processor.service';
import { WorkAreaService } from '../../../../workarea/workarea.service';
import { LeapXLEventType } from '../../../enums/leapxl-event-type.enum';
import { RepresentativeMoleculesType } from '../../../enums/representative-molecules-types.enum';
import { DragService } from '../../services/drag.service';
import { BaseMoleculeComponent } from '../base-molecule/base-molecule.component';

@Component({
  selector: 'app-datepicker-molecule',
  templateUrl: './datepicker-molecule.component.html',
  styleUrls: ['./datepicker-molecule.component.scss'],
})
export class DatepickerMoleculeComponent extends BaseMoleculeComponent
  implements OnInit, OnDestroy {
  @ViewChildren('datepickerInput', {
    read: MatInput,
  }) datepickerInput: MatInput;
  @ViewChildren('startRangeDatePickerInput', {
    read: MatInput,
  }) startRangeDatePickerInput: MatInput;
  @ViewChildren('endRangeDatePickerInput', {
    read: MatInput,
  }) endRangeDatePickerInput: MatInput;
  @ViewChild(MatDatepicker)
  datePicker: any;
  @ViewChild(MatDateRangePicker)
  dateRangePicker: any;
  
  MoleculeType = 'Representative';
  Type = RepresentativeMoleculesType.Datepicker;
  date = new FormControl();
  endDate = new FormControl();
  minDate = null;
  maxDate = null;
  
  @ViewChild('datepickerWrapper', { static: true })
  datepickerWrapper: ElementRef;
  
  constructor(
    public builderService: BuilderService,
    public dragService: DragService,
    public workAreaService: WorkAreaService,
    public processorService: ProcessorService,
    bottomSheet: MatBottomSheet,
    public el: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    super(bottomSheet, el, changeDetectorRef);
  }
  
  ngOnInit() {
    super.ngOnInit();
    this.context.Type = RepresentativeMoleculesType.Datepicker;
    this.RefreshGridsterConfiguration();
    
    this.UpdateData();
  }
  
  AttachEditorEventListeners() {
    const dragoverEventListener = this.renderer.listen(this.datepickerWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, true);
    });
    const dragleaveEventListener = this.renderer.listen(this.datepickerWrapper.nativeElement, 'dragover', (evt) => {
      this.drag(evt, false);
    });
    const dropEventListener = this.renderer.listen(this.datepickerWrapper.nativeElement, 'drop', (evt) => {
      this.DataDropped(evt);
    });
  }
  
  UpdateData() {
    // console.log(this.context.Value);
    
    if (this.context.Properties.minValue && this.context.Properties.minValue !== '') {
      this.minDate = this.toolsService.IsFloat(this.context.Properties.minValue) ? this.toolsService.ExcelDateToJSDate(this.context.Properties.minValue) : new Date(this.context.Properties.minValue);
    } else {
      this.minDate = null;
    }
    
    if (this.context.Properties.maxValue && this.context.Properties.maxValue !== '') {
      this.maxDate = this.toolsService.IsFloat(this.context.Properties.maxValue) ? this.toolsService.ExcelDateToJSDate(this.context.Properties.maxValue) : new Date(this.context.Properties.maxValue);
    } else {
      this.maxDate = null;
    }
    
    
    if (this.context.Value === '' || this.context.Value === null) {
      if (this.datepickerInput) {
        this.datepickerInput.value = '';
      }
      if (this.startRangeDatePickerInput) {
        this.startRangeDatePickerInput.value = '';
      }
      if (this.endRangeDatePickerInput) {
        this.endRangeDatePickerInput.value = '';
      }
      
      if (this.dateRangePicker) {
        this.dateRangePicker.select(undefined);
      }
      
      if (this.datePicker) {
        this.datePicker.select(undefined);
      }
      
      this.date.setValue(null);
      this.endDate.setValue(null);
    } else {
      this.context.Value = this.context.Value.replace(/\s+/g, '');
      const separateDates = this.context.Value.split('-');
      const jsDate = this.toolsService.IsFloat(separateDates[0]) ? this.toolsService.ExcelDateToJSDate(separateDates[0]) : new Date(separateDates[0]);
      this.context.StartValue = this.context.Value = this.toolsService.GetFormattedDate(jsDate);
      this.date = new FormControl(new Date(this.context.Value));
      
      if (this.context.Properties.dateRange) {
        this.context.Value = `${ this.context.StartValue } -`;
      }
      
      if (separateDates[1]) {
        const jsEndDate = this.toolsService.IsFloat(separateDates[1]) ? this.toolsService.ExcelDateToJSDate(separateDates[1]) : new Date(separateDates[1]);
        this.context.EndValue = this.toolsService.GetFormattedDate(jsEndDate);
        this.endDate = new FormControl(new Date(this.context.EndValue));
        this.context.Value = `${ this.context.StartValue } - ${ this.context.EndValue }`;
      }
    }
    
    if (this.context.Properties.dateRange) {
      if (this.context.StartValue) {
        const startValue = this.context.Value = this.toolsService.IsFloat(this.context.StartValue) ? this.toolsService.ExcelDateToJSDate(this.context.StartValue) : new Date(this.context.StartValue);
        this.date = new FormControl(new Date(startValue));
        this.context.Value = `${ this.context.StartValue } -`;
      }
      
      if (this.context.EndValue) {
        const endValue = this.context.Value = this.toolsService.IsFloat(this.context.EndValue) ? this.toolsService.ExcelDateToJSDate(this.context.EndValue) : new Date(this.context.EndValue);
        this.endDate = new FormControl(new Date(endValue));
        this.context.Value = `${ this.context.StartValue } - ${ this.context.EndValue }`;
      }
      
    }
    
  }
  
  GetFormattedDateFromControl(date: FormControl) {
    return this.toolsService.GetFormattedDate(date.value);
  }
  
  UpdateValue(date, type = '') {
    if (date.value !== null && date.value !== '') {
      
      const dateType = typeof date.value;
      let dateValue = null;
      
      if (dateType === 'string') {
        dateValue = date.value;
      } else if (dateType === 'object') {
        dateValue = this.GetFormattedDateFromControl(date);
      }
      
      switch (type) {
        case 'start-date':
          this.context.StartValue = dateValue;
          break;
        case 'end-date':
          this.context.EndValue = dateValue;
          this.context.Value = `${ this.context.StartValue } - ${ this.context.EndValue }`;
          break;
        default:
          this.context.Value = dateValue;
          break;
      }
      
      this.FireDateChangeEvent(date, type);
    }
  }
  
  FireDateChangeEvent(e: any, type = '') {
    switch (type) {
      case 'start-date':
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.StartDateSelected, this.context.StartValue);
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.DateSelected, this.context.StartValue);
        break;
      case 'end-date':
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.EndDateSelected, this.context.EndValue);
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.DateSelected, this.context.EndValue);
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.RangeDateSelected, `${ this.context.StartValue } - ${ this.context.EndValue }`);
        break;
      default:
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.StartDateSelected);
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.EndDateSelected);
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.RangeDateSelected, `${ this.context.Value } - ${ this.context.Value }`);
        this.FireRepresentativeMoleculeEvent(LeapXLEventType.DateSelected);
        break;
    }
  }
}
