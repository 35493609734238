export const customTemplate = [
  {
    icon: 'settings_panorama',
    moleculeType: 'Representative',
    name: 'Custom',
    type: 'Custom',
    moleculesAllow: ['Representative', 'DataAction', 'Behavior', 'Configuration', 'Compounds'],
    dataSource: {},
    styleMetadata: {
      styles: [],
      specificStyles: {
        dimension: null,
        font: null,
        hover: null,
        frame: null,
        appearance: null,
      },
      manualAdjustedProperties: [],
    },
    creationFromTemplate: false,
    receptors: ['none'],
    events: [],
    defaultEvent: 'init',
    editableProperties: [
      'cols',
      'rows',
      'x',
      'y',
    ],
    properties: {
      progressType: 'progress',
      progressColor: '#EA5C2F',
      progressBackgroundColor: '#E5957C',
      progressDiameter: 50,
      progressMode: 'indeterminate',
      progressStrokeWidth: 5,
      sliderVertical: false,
      sliderStep: 1,
      sliderThumbLabel: false,
      sliderTicks: false,
      sliderColor: '#EA5C2F',
      align: 'left', horizontalPadding: 0,
      verticalPadding: 0,
      wrapText: false,
      alwaysRenderComponent: true,
      customControl: {
        modified: false,
        receptors: [{ name: 'value-input' }, { name: 'value-output' }],
        events: [{ name: 'click' }],
        js:
          ` function updateText(text) {
  var ccboxHtmlElement = document.querySelector('.cc-box');
  ccboxHtmlElement.innerHTML = text;
 }
 
 function getText() {
  var ccboxHtmlElement = document.querySelector('.cc-box');
  return ccboxHtmlElement.innerHTML
 }

 function triggerClick() {
  console.warn('Click event');
  LeapXL.TriggerEvent("click", "custom control clicked");
 }
 
 function valueInputReceptor(data) {
  updateText(data);
 }
 
 function valueOutputReceptor() {
  return getText();
 }
 
 function InitCustomControl() {
  console.warn('Custom Control initialized');
  console.warn('Trigger events with "LeapXL.TriggerEvent("event-name", "data")"');
  LeapXL.RegisterControlReceptor('value-input', valueInputReceptor);
  LeapXL.RegisterControlReceptor('value-output', valueOutputReceptor);
 }
 `,
        html: '<div onclick="triggerClick()" class="box cc-box">LEAP</div>',
        css: `.box {
\tbackground: linear-gradient(60deg, rgba(227,84,52,1) 0%, rgba(249,107,29,1) 100%);
\tcolor: white;
\t--width: 250px;
\t--height: calc(var(--width) / 3);
\twidth: var(--width);
\theight: var(--height);
\ttext-align: center;
\tline-height: var(--height);
\tfont-weight: bold;
\tfont-size: 48px;
\tfont-family: system-ui;
\tletter-spacing: 0.2em;
\tborder: 1px solid darkgoldenrod;
\tborder-radius: 0.3em;
\ttransform: perspective(500px) rotateY(-15deg);
\ttext-shadow: 6px 3px 2px rgba(0, 0, 0, 0.2);
\tbox-shadow: 2px 0 0 5px rgba(0, 0, 0, 0.2);
\ttransition: 0.5s;
\tposition: relative;
\toverflow: hidden;
}

.box:hover {
\ttransform: perspective(500px) rotateY(15deg);
\ttext-shadow: -6px 3px 2px rgba(0, 0, 0, 0.2);
\tbox-shadow: -2px 0 0 5px rgba(0, 0, 0, 0.2);
}

.box::before {
\tcontent: '';
\tposition: absolute;
\twidth: 100%;
\theight: 100%;
\tbackground: linear-gradient(to right, transparent, white, transparent);
\tleft: -100%;
\ttransition: 0.5s;
}

.box:hover::before {
\tleft: 100%;
}`,
        options: {
          documentation: {
            info: '',
            events: [],
            receptors: [],
          },
          jsModuleType: false,
          encapsulate: true,
          previewOnEditor: false,
          isolated: false,
          isolatedTransparentBackground: false,
          allowImportTemplate: false,
          allowEdit: false,
          obfuscateJs: true,
          externalJs: [],
          externalCss: [],
        },
      },
      location: null,
      hover: {
        hoverBackground: 'white',
        hoverBackgroundOpacity: 1,
        hoverBorderRadius: 5,
        hoverBorderStyle: 'none',
        hoverBorderColor: 'transparent',
        hoverBorderWidth: 1,
        hoverFontColor: 'black',
      },
      bordersValues: {
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'none',
        borderColor: 'transparent',
      },
      options: [
        {
          value: 'value 1',
          text: 'Text 1',
        },
      ],
      show: true,
      opacity: 1,
      orientation: 'col',
      badge: {
        badgeEnable: true,
        badgeBackgroundColor: '#f22525',
        badgeFontFamily: 'Source Sans Pro',
        badgeFontColor: 'white',
        badgeStyle: 'circle',
        badgePosition: 'right',
      },
      enable: true,
      altText: '',
      dateRange: false,
      search: false,
      multiSelect: false,
      autoDataRefresh: false,
      ignoreValueDataIndex: false,
      textDecoration: 'none',
      allowEmptyValues: false,
      responsive: {
        desktop: {
          id: 0,
          cols: 140,
          rows: 70,
          colsQty: 0,
          rowsQty: 0,
          layer: 1,
          centerPositioning: false,
          x: 0,
          y: 0,
          font: {
            fontSize: 16,
            fontColor: '#000000',
            fontFamily: 'inherit',
            fontStyle: 'inherit',
            fontWeight: 'normal',
          },
          tableOptions: {
            collapse: true,
            bodyRoundCorners: false,
            search: true,
            header: true,
            verticallyCenter: true,
            rowSeparator: false,
            columnSeparator: false,
            rowPadding: true,
            tableOrder: [],
            tableWidth: {},
          },
        },
        smartphone: {
          id: null,
          customGuidelines: {
            x: [],
            y: [],
          },
          cols: null,
          rows: null,
          colsQty: null,
          rowsQty: null,
          layer: null,
          centerPositioning: false,
          x: null,
          y: null,
          font: {
            fontSize: null,
            fontColor: null,
            fontFamily: null,
            fontStyle: null,
            fontWeight: null,
          },
          tableOptions: {
            search: true,
            header: true,
            verticallyCenter: null,
            rowSeparator: null,
            columnSeparator: null,
            rowPadding: null,
            tableOrder: [],
            tableWidth: {},
          },
        },
      },
      
      icon: {
        iconType: 'stars',
        iconColor: 'black',
        iconSize: 45,
      },
      name: 'Custom',
      legend: true,
      loadingText: '',
      background: {
        backgroundColor: '#ffffff',
        backgroundTypeImage: false,
        backgroundImageUrl: '',
        backgroundOpacity: 1,
      },
      shadowValues: {
        hShadow: 0.5,
        vShadow: 0.5,
        shadowColor: '#717171',
        shadowBlur: 3,
      },
      preferences: {},
      view: 1,
      chartLibrary: 'ngxCharts',
      chartType: 'line',
      tabindex: 0,
      textToDisplay: 'Custom',
      tooltip: '',
      tableOrder: [],
      placeholder: 'Custom',
      minItemCols: 2,
      maxItemCols: 200,
    },
    molecules: [],
    placeholders: [],
    buses: [],
    minValue: null,
    maxValue: null,
    isSelected: false,
    enable: true,
    value: null,
    children: [],
    replaceableByRepresentative: true,
    loading: false,
    autoGenerationInfo: [],
    moleculeSubType: 'Custom',
    optionsMenu: {
      configuration: true,
      molecules: true,
      compound: true,
      cobblet: true,
      remove: true,
      versioning: true,
      dataSource: true,
      duplicate: true,
    },
  },
];
