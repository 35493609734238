import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { colorSets } from '@swimlane/ngx-charts';
import { cloneDeep, debounce } from 'lodash-es';
import { FileUploader } from 'ng2-file-upload';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Subscription } from 'rxjs';
import { debounceTime, pairwise, startWith, takeWhile } from 'rxjs/operators';
import { Permissions } from '../../admin/models/permissions.enum';
import { BusService } from '../../core/molecular/services/bus.service';
import { ApiDataSourcesService } from '../../core/services/api-data-sources.service';
import { ApiFileService } from '../../core/services/api-file.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { UserMenuService } from '../../core/services/user-menu.service';
import { Constants } from '../../shared/constants';
import { PropertyVersioningDto } from '../../shared/dtos/versioning-dto';
import { ChartType } from '../../shared/enums/chart-type.enum';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { RepresentativeMoleculesType } from '../../shared/enums/representative-molecules-types.enum';
import { IRepresentativeMoleculeStyleData } from '../../shared/interfaces/rep-mol-style.interface';
import { View } from '../../shared/interfaces/view';
import { RepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { DraggableWindowService, DraggableWindowType } from '../../shared/services/draggable-window.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { ApiThematicService } from '../services/api-thematic.service';
import { ThematicService } from '../services/thematic.service';
import { WorkAreaService } from '../workarea.service';

declare const Grapick: any;

@Component({
  selector: 'app-representative-properties',
  templateUrl: './representative-properties.component.html',
  styleUrls: ['./representative-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [transition(':enter', [style({ opacity: '0.3' }), animate('.7s ease-out', style({ opacity: '1' }))])]),
    trigger('sidepanels', [
      transition(':enter', [style({ opacity: '0' }), animate('0.4s ease-out', style({ opacity: '1' }))]),
      transition(':leave', [style({ opacity: '1' }), animate('0.4s ease-out', style({ opacity: '0' }))]),
    ]),
    trigger('add', [transition(':enter', [style({ transform: 'translateY(10%)' }), animate('20000ms', style({ transform: 'translateY(0)' }))])]),
  ],
})
export class RepresentativePropertiesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('propertiesForm', { static: false })
  propertiesForm: NgForm;
  
  @ViewChild('headerLeftTextAlignment', { static: false })
  headerLeftTextAlignment: MatCheckbox;
  
  @ViewChild('headerCenterTextAlignment', { static: false })
  headerCenterTextAlignment: MatCheckbox;
  
  @ViewChild('headerRightTextAlignment', { static: false })
  headerRightTextAlignment: MatCheckbox;
  
  @ViewChild('gradientDirectionSelector', { static: false })
  gradientDirectionSelector: MatSelect;
  
  @ViewChild('gradientTypeSelector', { static: false })
  gradientTypeSelector: MatSelect;
  DraggableWindowType = 'RepresentativeProperties';
  uploader: FileUploader = new FileUploader({});
  elementFocused: IRepresentativeMolecule;
  templateVersions = [];
  primaryShadowElements: RepresentativeMolecule[] = [];
  disabled = true;
  subscriptions: Subscription;
  formSubscriptions: Subscription = new Subscription();
  RepresentativeMoleculesType = RepresentativeMoleculesType;
  ChartColorSets = colorSets;
  ChartType = ChartType;
  ngxRepMoleculeChartType = '';
  savingProperties = false;
  nameInputClicked = false;
  altTextInputClicked = false;
  textToDisplayInputClicked = false;
  placeholderInputClicked = false;
  lastInputName = '';
  SubscribeToFormChanges = debounce(() => {
    // debounce
    this.SubscribeToFormChangesDebounced();
  }, 100);
  Constants = Constants;
  representativeMoleculeStyles: IRepresentativeMoleculeStyleData[] = [];
  representativeMoleculeStylesDimension: IRepresentativeMoleculeStyleData[] = [];
  representativeMoleculeStylesFont: IRepresentativeMoleculeStyleData[] = [];
  representativeMoleculeStylesAppearance: IRepresentativeMoleculeStyleData[] = [];
  representativeMoleculeStylesHover: IRepresentativeMoleculeStyleData[] = [];
  representativeMoleculeStylesFrame: IRepresentativeMoleculeStyleData[] = [];
  representativeMoleculeStylesDimensionSelected: IRepresentativeMoleculeStyleData = null;
  representativeMoleculeStylesFontSelected: IRepresentativeMoleculeStyleData = null;
  representativeMoleculeStylesAppearanceSelected: IRepresentativeMoleculeStyleData = null;
  representativeMoleculeStylesHoverSelected: IRepresentativeMoleculeStyleData = null;
  representativeMoleculeStylesFrameSelected: IRepresentativeMoleculeStyleData = null;
  styleContainerOpen = false;
  indeterminate = true;
  backgroundColorStyle = 'solid';
  manualAdjustedProperties: any = {};
  hasStyleApplied: boolean = false;
  hasManuallyAdjustedProperties = false;
  
  styleSectionIcons = {
    all: 'format_align_justify',
    dimension: 'photo_size_select_small',
    font: 'font_download',
    hover: 'mouse',
    frame: 'format_shapes',
    appearance: 'format_color_fill',
  };
  
  viewsRenamed = [];
  gp = null;
  customControlParsedCss = [];
  updateCustomControl = false;
  editCustomControl = false;
  userId = 0;
  editCustomControlFromImportedApp = false;
  updateModifiedCustomControlTemplate = false;
  
  constructor(
    public workAreaService: WorkAreaService,
    public propertiesService: ApiPropertiesService,
    private busService: BusService,
    public cobbleService: CobbleService,
    private connectionStateService: ConnectionStateService,
    private toolsService: ToolsService,
    private templateService: TemplateService,
    private fileService: ApiFileService,
    private editorStateService: EditorStateService,
    private dragableWindowManagerService: DraggableWindowManagerService,
    private draggableWindowService: DraggableWindowService,
    private dataSourceService: ApiDataSourcesService,
    private imageCompress: NgxImageCompressService,
    private snackerService: SnackerService,
    private communicationService: CommunicationService,
    private thematicService: ThematicService,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef,
    private apiThematicService: ApiThematicService,
    private templatesService: TemplateService,
    private clientStorageService: ClientStorageService,
    private elementRef: ElementRef,
    private userMenuService: UserMenuService,
    private angularZone: NgZone,
  ) {
    this.userId = this.clientStorageService.getUserId();
    this.updateCustomControl = this.userMenuService.checkPermission(Permissions.UpdateCustomControl);
    this.editCustomControl = this.userMenuService.checkPermission(Permissions.EditCustomControl);
    this.updateModifiedCustomControlTemplate = this.userMenuService.checkPermission(Permissions.UpdateModifiedCustomControlTemplate);
    this.editCustomControlFromImportedApp = this.userMenuService.checkPermission(Permissions.EditCustomControlFromImportedApp);
    this.InitPropertiesPanel();
  }
  
  ngAfterViewInit(): void {
    this.elementRef.nativeElement.querySelector('input');
    // .addEventListener('click', this.onClick.bind(this));
  }
  
  ngOnInit() {
    // console.log('init');
    
    this.subscriptions = this.communicationService.Event.Editor.$RefreshRepresentativePropertiesPanel.subscribe(condition => {
      console.log('=event='); // console.log('$RefreshRepresentativePropertiesPanel');
      this.ref.markForCheck();
    });
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.WorkArea.$PropertiesUpdated.subscribe(elementsSelected => {
        console.log('=event=');
        setTimeout(() => {
          this.workAreaService.elementsSelected.forEach(es => es.RefreshUI());
          this.workAreaService.RefreshElementsSelected();
          this.InitPropertiesPanel();
          this.ref.markForCheck();
        }, 1000);
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$SelectedElementsChange.subscribe(elementsSelected => {
        console.log('=event='); // console.log('$InitPropertiesPanel');
        // this.propertiesForm.resetForm();
        this.InitPropertiesPanel();
        this.ref.markForCheck();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$InitRepresentativePropertiesPanel.subscribe(elementsSelected => {
        console.log('=event=');
        this.InitPropertiesPanel();
        this.ref.markForCheck();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$ChangesRepresentativePropertiesPanel.pipe(debounceTime(200)).subscribe(elementsSelected => {
        console.log('=event=');
        console.log('$SelectedElementsChange');
        // console.log('unsubscribe');
        // this.propertiesForm.resetForm();
        //   this.SubscribeToFormChanges();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$MoleculeRemoved.subscribe(repMoleculeId => {
        console.log('=event=');
        if (this.elementFocused.Id === repMoleculeId) {
          this.workAreaService.HideDraggableWindows();
        }
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.Saving.$AllDataSaved.subscribe(repMoleculeId => {
        console.log('=event=');
        this.savingProperties = false;
        this.ref.markForCheck();
      }),
    );
    
    this.SubscribeToFormChanges();
    
    this.customControlParsedCss = this.toolsService.ParseCSS(this.elementFocused.Properties.customControl.css);
    
    if (this.elementFocused.Type === RepresentativeMoleculesType.Custom) {
      this.templateService.GetTemplateVersions(this.elementFocused.TemplateId).subscribe(versions => {
        this.templateVersions = versions;
      });
    }
    this.hasStyleApplied = this.elementFocused.StyleMetadata.styles.length > 0;
    this.hasManuallyAdjustedProperties = this.elementFocused.StyleMetadata.manualAdjustedProperties.length > 0;
  }
  
  ToggleAllStyles(checked: boolean) {
    this.representativeMoleculeStyles.forEach(s => {
      this.ToggleStyle(checked, s);
    });
  }
  
  RefreshStyles() {
    let styles: IRepresentativeMoleculeStyleData[] = [];
    const library = this.thematicService.GetStylesLibrary();
    
    if (this.elementFocused.StyleMetadata.styles.length > 0) {
      styles = library.filter(style => !this.elementFocused.StyleMetadata.styles.includes(style.styleId));
      
      const reverse = this.elementFocused.StyleMetadata.styles.slice().reverse();
      
      reverse.forEach(styleId => {
        styles.unshift(library.find(ls => ls.styleId === styleId));
      });
    } else {
      styles = library;
    }
    
    styles = styles.filter(s => {
      if (s) {
        return s;
      }
    });
    styles.forEach(s => {
      if (s) {
        s.indeterminate = s.checked = false;
      }
    });
    
    if (this.workAreaService.elementsSelected.length > 1) {
      const stylesInUse = (this.workAreaService.elementsSelected.map(es => es.StyleMetadata.styles) as any).flat();
      
      styles.forEach(s => {
        if (s) {
          const count = stylesInUse.filter(su => su === s.styleId).length;
          s.indeterminate = count > 0 && count !== this.workAreaService.elementsSelected.length;
          s.checked = count === this.workAreaService.elementsSelected.length;
        }
      });
    }
    
    this.representativeMoleculeStyles = styles.sort(this.toolsService.CompareValues('name'));
    
    this.MoveAppliedStylesToTop();
    this.GetSpecificStyles();
    this.UpdateAdjustedProperties();
  }
  
  UpdateAdjustedProperties() {
    this.manualAdjustedProperties = {};
    if (this.elementFocused.StyleMetadata.manualAdjustedProperties) {
      this.elementFocused.StyleMetadata.manualAdjustedProperties.forEach(
        ({ path, property }) => (this.manualAdjustedProperties[property] = property),
      );
    }
    this.hasManuallyAdjustedProperties = this.elementFocused.StyleMetadata.manualAdjustedProperties.length > 0;
    this.ref.markForCheck();
  }
  
  MoveAppliedStylesToTop() {
    let stylesApplied = this.representativeMoleculeStyles.filter(
      style => this.elementFocused.StyleMetadata.styles.includes(style.styleId) || style.checked,
    );
    stylesApplied = stylesApplied.filter((style, index) => stylesApplied.indexOf(style) === index);
    
    if (stylesApplied.length > 0) {
      this.representativeMoleculeStyles = (stylesApplied as any).flatMap(styleApplied => {
        return this.representativeMoleculeStyles.filter(style => style.styleId !== styleApplied.styleId);
      });
      
      this.representativeMoleculeStyles = [...stylesApplied, ...this.representativeMoleculeStyles];
      this.representativeMoleculeStyles = [...new Set(this.representativeMoleculeStyles)];
    }
  }
  
  SubscribeToFormChangesDebounced() {
    // to avoid new rep selection override values
    const input = document.querySelector('input[name="' + this.lastInputName + '"]') as any;
    
    if (input) {
      input.blur();
    }
    //
    
    console.log('SubscribeToFormChanges');
    this.formSubscriptions.unsubscribe();
    this.formSubscriptions = new Subscription();
    
    setTimeout(() => {
      Object.keys(this.propertiesForm.controls).forEach(key => {
        console.log(key);
        this.formSubscriptions.add(
          this.propertiesForm.form
          .get(key)
          .valueChanges.pipe(
            takeWhile(() => {
              return !this.thematicService.ApplyingStyle;
            }),
            debounceTime(400),
            startWith(this.propertiesForm.controls[key].value),
            pairwise(),
          )
          .subscribe(([prev, next]: [any, any]) => {
            // if (this.propertiesForm.dirty) {
            //   this.propertiesForm.form.markAsPristine();
            
            if (key.includes('cssProperty')) {
              this.SaveCustomControlCss();
            } else {
              if (!this.elementFocused.IsPropertyValueFromStyle(key, next)) {
                console.log('property modified: ' + key, next, prev);
                if (next !== prev) {
                  this.UpdateAndSaveElementProperty(key, next, prev);
                }
                this.lastInputName = key;
              }
            }
            // (document.querySelector('input[name="' + key + '"]') as any).blur();
            // }
          }),
        );
      });
      
      this.InitGradientPicker();
      console.log(this.formSubscriptions);
    }, 500);
  }
  
  SaveCustomControlCss() {
    const css = this.toolsService.BuildParsedCss(this.customControlParsedCss);
    this.UpdateAndSaveElementProperty('css', css);
  }
  
  InitPropertiesPanel() {
    // to avoid new rep selection override values
    const input = document.querySelector('input[name="' + this.lastInputName + '"]') as any;
    
    if (input) {
      input.blur();
    }
    //
    
    this.formSubscriptions.unsubscribe();
    this.formSubscriptions = new Subscription();
    console.log('init properties panel');
    this.disabled = true;
    if (this.workAreaService.elementsSelected.length === 1) {
      this.elementFocused = this.workAreaService.elementsSelected[0];
      this.primaryShadowElements = [new RepresentativeMolecule()];
    } else if (this.workAreaService.elementsSelected.length > 1) {
      this.elementFocused = new RepresentativeMolecule({
        EditableProperties: this.workAreaService.primaryElementsSelected[0].EditableProperties,
      });
      
      this.workAreaService.primaryElementsSelected.forEach(pes => {
        this.primaryShadowElements.push(new RepresentativeMolecule(pes));
      });
    }
    
    this.RefreshStyles();
    
    if (this.elementFocused.Type === RepresentativeMoleculesType.Chart && this.elementFocused.Properties.chartLibrary === 'ngxCharts') {
      this.SetNgxChartType();
    }
    
    this.ref.markForCheck();
    setTimeout(() => {
      this.disabled = false;
      this.ref.markForCheck();
    }, 500);
    // console.log(this.elementFocused);
    this.SubscribeToFormChanges();
    
    if (this.elementFocused.Type === RepresentativeMoleculesType.Breadcrumb) {
      this.cobbleService.Cobble.properties.views.forEach(view => {
        const renamedView = this.elementFocused.Properties.viewNames.find(vn => vn.id === view.id);
        this.viewsRenamed.push({
          id: view.id,
          name: view.name,
          rename: renamedView ? renamedView.name : '',
        });
      });
    }
    
    this.backgroundColorStyle = this.elementFocused.Properties.background.backgroundColor.includes('gradient') ? 'gradient' : 'solid';
  }
  
  ChangeBackgroundStyle() {
    if (this.backgroundColorStyle === 'gradient') {
      setTimeout(() => {
        this.InitGradientPicker();
      }, 500);
    } else {
      this.UpdateAndSaveElementProperty('backgroundColor', 'white');
    }
  }
  
  InitGradientPicker() {
    if (this.backgroundColorStyle === 'gradient') {
      this.gp = new Grapick({ el: '#gp' });
      if (this.elementFocused.Properties.background.backgroundColor.includes('gradient')) {
        console.log(this.elementFocused.Properties.background.backgroundColor);
        
        const gradientParsed = this.toolsService.ParseGradient(this.elementFocused.Properties.background.backgroundColor);
        console.log(gradientParsed);
        
        if (gradientParsed[0]) {
          switch (gradientParsed[0].type) {
            case 'linear-gradient':
              this.gp.setType('linear');
              this.gradientTypeSelector.value = 'linear';
              break;
            case 'radial-gradient':
              this.gp.setType('radial');
              this.gradientTypeSelector.value = 'radial';
              break;
          }
          
          if (gradientParsed[0].orientation) {
            this.gradientDirectionSelector.value = gradientParsed[0].orientation.value;
            this.gp.setDirection(gradientParsed[0].orientation.value);
          } else {
            this.gradientDirectionSelector.value = 'top';
            this.gp.setDirection('top');
          }
          
          gradientParsed[0].colorStops.forEach(stop => {
            this.gp.addHandler(+stop.length.value, `rgb(${ stop.value[0] }, ${ stop.value[1] }, ${ stop.value[2] })`);
          });
        }
        
        // Handlers are color stops
        // gp.addHandler(50, this.elementFocused.Properties.background.backgroundColor);
      } else {
        this.gradientTypeSelector.value = 'linear';
        this.gradientDirectionSelector.value = 'top';
        this.gp.addHandler(50, this.elementFocused.Properties.background.backgroundColor);
        this.ChangeGradientDirection('top');
      }
      // Do stuff on change of the gradient
      this.gp.on('change', complete => {
        this.SaveGradientBackground();
      });
    }
  }
  
  ChangeGradientDirection(direction: string) {
    this.gp.setDirection(direction);
  }
  
  ChangeGradientType(type: string) {
    this.gp.setType(type);
  }
  
  SaveGradientBackground() {
    this.UpdateAndSaveElementProperty('backgroundColor', this.gp.getSafeValue());
  }
  
  SetNgxChartType() {
    this.ngxRepMoleculeChartType = this.elementFocused.Properties.responsive[this.cobbleService.Cobble.deviceType].chartOptions.ngxChartType;
  }
  
  public UpdateAndSaveElementProperty(property: string, value: any, previousValue?: any) {
    if (!this.connectionStateService.IsOnline) {
      this.connectionStateService.ShowNoConnectionStatePopup();
      return;
    }
    
    const avoidSaveForMoleculeIds = [];
    
    console.log(property);
    try {
      let path = ['properties'];
      // force to make color inputs work
      switch (property) {
        case 'borderColorValue':
        case 'iconColorValue':
        case 'fontColorValue':
        case 'headerFontColorValue':
        case 'bodyFontColorValue':
        case 'hoverFontColorValue':
        case 'hoverBorderColorValue':
        case 'shadowColorValue':
        case 'backgroundColorValue':
        case 'paginationBackgroundColorValue':
        case 'firstBodyStripedColorValue':
        case 'secondBodyStripedColorValue':
        case 'bodyBackgroundColorValue':
        case 'headerBackgroundColorValue':
        case 'progressColorValue':
        case 'progressBackgroundColorValue':
          property = property.replace('Value', '');
          return;
          break;
      }
      //
      
      if (
        this.elementFocused.Properties.responsive.smartphone.hasOwnProperty(property) &&
        this.cobbleService.Cobble.deviceType === 'desktop' &&
        this.elementFocused.Properties.responsive.smartphone[property] === null
      ) {
        this.elementFocused.Properties.responsive.smartphone[property] = value;
      }
      
      switch (property) {
        case 'placeholders':
          path = [];
          break;
        case 'ngxChartType':
        case 'chartOptions':
        case 'pinched':
        case 'inverted':
        case 'dynamic':
        case 'labelColor':
        case 'labelSize':
        case 'funnelBottomWidth':
        case 'funnelPinchLevels':
        case 'curveHeight':
        case 'closedCurveType':
        case 'curveType':
        case 'schemeType':
        case 'colorScheme':
        case 'rangeFillOpacity':
        case 'showLegend':
        case 'showXAxis':
        case 'showYAxis':
        case 'gradient':
        case 'legendTitle':
        case 'legendPosition':
        case 'switchRowCols':
        case 'animations':
        case 'showXAxisLabel':
        case 'tooltipDisabled':
        case 'showText':
        case 'xAxisLabel':
        case 'showYAxisLabel':
        case 'yAxisLabel':
        case 'showGridLines':
        case 'barPadding':
        case 'groupPadding':
        case 'roundDomains':
        case 'maxRadius':
        case 'minRadius':
        case 'showSeriesOnHover':
        case 'roundEdges':
        case 'xScaleMin':
        case 'xScaleMax':
        case 'yScaleMin':
        case 'yScaleMax':
        case 'showDataLabel':
        case 'noBarWhenZero':
        case 'trimXAxisTicks':
        case 'trimYAxisTicks':
        case 'rotateXAxisTicks':
        case 'maxXAxisTickLength':
        case 'maxYAxisTickLength':
        case 'showLabels':
        case 'explodeSlices':
        case 'doughnut':
        case 'arcWidth':
        case 'gaugeUnits':
        case 'autoScale':
        case 'timeline':
        case 'margin':
        case 'marginTop':
        case 'marginRight':
        case 'marginBottom':
        case 'marginLeft':
          path.push('responsive');
          path.push(this.cobbleService.Cobble.deviceType);
          path.push('chartOptions');
          if (property === 'ngxChartType') {
            this.InitPropertiesPanel();
            this.SubscribeToFormChanges();
          }
          break;
        case 'ignoreValueDataIndex':
          this.workAreaService.elementsSelected.forEach(es => es.FireDataSourceBus());
          break;
        case 'name':
          this.communicationService.Event.Editor.$RecreateProcessBuses.emit();
          break;
        case 'itemsPerPage':
        case 'centerPositioning':
          path.push('responsive');
          path.push(this.cobbleService.Cobble.deviceType);
          break;
        case 'css':
        case 'html':
        case 'js':
        case 'receptors':
        case 'events':
        case 'options':
          path.push('customControl');
          break;
        case 'headerHeight':
        case 'headerFontSize':
        case 'headerFontColor':
        case 'headerFontFamily':
        case 'headerFontStyle':
        case 'stickyHeaders':
        case 'headerBackgroundColor':
        case 'headerTextAlignment':
          path.push('responsive');
          path.push(this.cobbleService.Cobble.deviceType);
          path.push('header');
          break;
        case 'bodyFontFamily':
        case 'bodyFontStyle':
        case 'bodyFontSize':
        case 'bodyHeight':
        case 'bodyFontColor':
        case 'bodyBackgroundColor':
        case 'isBodyStriped':
        case 'firstBodyStripedColor':
        case 'bodyHighlightRowOnHover':
        case 'secondBodyStripedColor':
        case 'stickyFooters':
        case 'bodyCenterText':
          path.push('responsive');
          path.push(this.cobbleService.Cobble.deviceType);
          path.push('body');
          break;
        case 'fontSize':
        case 'fontFamily':
        case 'fontStyle':
        case 'fontColor':
        case 'fontWeight':
          path.push('responsive');
          path.push(this.cobbleService.Cobble.deviceType);
          path.push('font');
          
          if (property === 'fontWeight') {
            value = value ? 'bold' : 'normal';
          }
          break;
        case 'hoverBackground':
        case 'hoverBorderRadius':
        case 'hoverBorderStyle':
        case 'hoverBorderColor':
        case 'hoverBorderWidth':
        case 'hoverFontColor':
        case 'hoverBackgroundOpacity':
        case 'hoverTextDecoration':
        case 'hoverPointer':
          path.push('hover');
          break;
        case 'iconType':
        case 'iconColor':
        case 'iconSize':
          path.push('icon');
          break;
        case 'stepperLabelPosition':
        case 'stepperSequential':
        case 'stepperScrollbars':
        case 'stepperOrientation':
          path.push('stepper');
          break;
        case 'backgroundColor':
        case 'backgroundImageUrl':
        case 'backgroundTypeImage':
        case 'backgroundOpacity':
          path.push('background');
          break;
        case 'borderWidth':
        case 'borderStyle':
        case 'borderColor':
        case 'borderRadius':
          path.push('bordersValues');
          break;
        case 'maskType':
          switch (value) {
            case 'currency':
              this.UpdateAndSaveElementProperty('mask', '$.2');
              break;
            case 'amount':
              this.UpdateAndSaveElementProperty('mask', '#.2');
              break;
            case 'phone':
              this.UpdateAndSaveElementProperty('mask', '(000) 000 0000');
              break;
            case 'ssn':
              this.UpdateAndSaveElementProperty('mask', '000-00-0000');
              break;
            case 'creditCard':
              this.UpdateAndSaveElementProperty('mask', '0000 0000 0000 0000');
              break;
            case 'postalCode':
              this.UpdateAndSaveElementProperty('mask', '00000-[0000]');
              break;
            case 'extension':
              this.UpdateAndSaveElementProperty('mask', '00000');
              break;
            case 'ip':
              this.UpdateAndSaveElementProperty('mask', '[000].[000].[000].[000]');
              break;
            case 'percentage':
              this.UpdateAndSaveElementProperty('mask', '[000000.00]%');
              break;
            case 'date':
              this.UpdateAndSaveElementProperty('mask', '00/00/0000');
              break;
            case 'numeric':
              this.UpdateAndSaveElementProperty('mask', '[000000000000000000000000000000000000000000000000000000]');
              break;
            case 'custom':
              this.UpdateAndSaveElementProperty('mask', '');
            
            default:
              this.UpdateAndSaveElementProperty('mask', '');
              break;
          }
          
          break;
        case 'badgeEnable':
        case 'badgeBackgroundColor':
        case 'badgeFontFamily':
        case 'badgeFontColor':
        case 'badgeStyle':
        case 'badgePosition':
        case 'badgeAnimate':
        case 'badgeIcon':
          path.push('badge');
          break;
        case 'tableWidth':
        case 'search':
        case 'header':
        case 'verticallyCenter':
        case 'centerPagination':
        case 'rowSeparator':
        case 'paginationBackgroundColor':
        case 'itemsCount':
        case 'columnSeparator':
        case 'collapse':
        case 'selectRow':
        case 'bodyRoundCorners':
        case 'rowPadding':
          path.push('responsive');
          path.push(this.cobbleService.Cobble.deviceType);
          path.push('tableOptions');
          break;
        case 'hShadow':
        case 'vShadow':
        case 'shadowColor':
        case 'shadowBlur':
          path.push('shadowValues');
          break;
        case 'chartOrientation':
          this.communicationService.Event.System.Update.$ChangesOnCharts.emit();
          break;
        case 'x':
        case 'y':
        case 'cols':
        case 'rows':
        case 'layer':
          if (property === 'layer' && value < 1) {
            this.snackerService.ShowMessageOnBottom('Layer must be grater than 0', 'exposure_plus_1');
            
            this.elementFocused.ResponsiveProperties().layer = 1;
            
            value = 1;
          }
          if (property === 'layer') {
            path.push('responsive');
            path.push(this.cobbleService.Cobble.deviceType);
          }
          
          if (property === 'x' || property === 'y' || property === 'cols' || property === 'rows') {
            path.push('responsive');
            path.push(this.cobbleService.Cobble.deviceType);
            
            if (this.workAreaService.elementsSelected.length > 1) {
              console.time('prop');
              this.workAreaService.elementsSelected.forEach((es, index) => {
                console.time('saving');
                console.log('=save for molecule=', index);
                // console.log('eval new positioning', es);
                const newPosition = cloneDeep(this.workAreaService.elementsSelectedOriginalPosition[es.Id]);
                // console.log('old value', newPosition[property]);
                newPosition[property] = newPosition[property] + value;
                // console.log('new value', newPosition[property]);
                
                if (this.workAreaService.CanPositionElement(es.Id, newPosition)) {
                  setTimeout(() => {
                    // todo: refactor
                    es.UpdateProperty(
                      null,
                      newPosition[property],
                      {
                        elementId: es.Id,
                        path: path.join('.'),
                        property: property,
                        value: newPosition[property],
                      },
                      this.busService,
                    );
                    this.ref.markForCheck();
                    
                    es.SavePropertyFromVersioning(
                      new PropertyVersioningDto({
                        elementId: es.Id.toString(),
                        property: property,
                        value: newPosition[property],
                        path: path.join('.'),
                        change: 'Edit',
                        name: property.replace(/([a-z])([A-Z])/g, '$1 $2'),
                      }),
                    ).subscribe();
                  }, 0);
                } else {
                  // console.log('can not position');
                  avoidSaveForMoleculeIds.push(es.Id);
                  this.snackerService.ShowMessageOnBottom('No enough space to place molecule', 'picture_in_picture_alt');
                }
                console.timeEnd('saving');
              });
              console.timeEnd('prop');
              return;
            } else {
              const position = {
                x: this.elementFocused.ResponsiveProperties().x,
                y: this.elementFocused.ResponsiveProperties().y,
                cols: this.elementFocused.ResponsiveProperties().cols,
                rows: this.elementFocused.ResponsiveProperties().rows,
              };
              this.workAreaService.sizeStart = {
                x: this.elementFocused.ResponsiveProperties().x,
                y: this.elementFocused.ResponsiveProperties().y,
                cols: this.elementFocused.ResponsiveProperties().cols,
                rows: this.elementFocused.ResponsiveProperties().rows,
              };
              this.workAreaService.sizeStart[property] = previousValue;
              position[property] = value;
              
              if (!this.workAreaService.CanPositionElement(this.elementFocused.Id, position)) {
                const positionPropertiesToSave = [];
                const parent = this.busService.Get(this.elementFocused.ParentId.toString());
                const parentPosition = parent.ResponsiveProperties();
                
                if (property === 'x') {
                  // console.log('CHANGE X');
                  
                  if (value < 0 || value > parentPosition.cols) {
                    this.elementFocused.ResponsiveProperties().x = 0;
                  }
                  
                  if (value + position.cols > parentPosition.cols) {
                    this.elementFocused.ResponsiveProperties().x = parentPosition.cols - position.cols;
                  }
                  
                  this.propertiesForm.controls[property].setValue(this.elementFocused.ResponsiveProperties().x);
                  
                  positionPropertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: this.elementFocused.Id.toString(),
                      property: property,
                      value: this.elementFocused.ResponsiveProperties().x,
                      path: path.join('.'),
                      change: 'Edit',
                      name: property.replace(/([a-z])([A-Z])/g, '$1 $2'),
                    }),
                  );
                }
                
                if (property === 'y') {
                  // console.log('CHANGE y');
                  
                  if (value < 0 || value > parentPosition.rows) {
                    this.elementFocused.ResponsiveProperties().y = 0;
                  }
                  
                  if (value + position.rows > parentPosition.rows) {
                    this.elementFocused.ResponsiveProperties().y = parentPosition.rows - position.rows;
                  }
                  
                  this.propertiesForm.controls[property].setValue(this.elementFocused.ResponsiveProperties().y);
                  
                  positionPropertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: this.elementFocused.Id.toString(),
                      property: property,
                      value: this.elementFocused.ResponsiveProperties().y,
                      path: path.join('.'),
                      change: 'Edit',
                      name: property.replace(/([a-z])([A-Z])/g, '$1 $2'),
                    }),
                  );
                }
                
                if (property === 'cols') {
                  if (value < 1) {
                    // console.log('primero');
                    
                    this.elementFocused.ResponsiveProperties().cols = 1;
                  }
                  
                  if (value > parentPosition.cols || this.elementFocused.ResponsiveProperties().x + value > parentPosition.cols) {
                    // console.log('segundo');
                    
                    this.elementFocused.ResponsiveProperties().cols = parentPosition.cols - this.elementFocused.ResponsiveProperties().x;
                  }
                  
                  this.propertiesForm.controls[property].setValue(this.elementFocused.ResponsiveProperties().cols);
                  
                  positionPropertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: this.elementFocused.Id.toString(),
                      property: property,
                      value: this.elementFocused.ResponsiveProperties().cols,
                      path: path.join('.'),
                      change: 'Edit',
                      name: property.replace(/([a-z])([A-Z])/g, '$1 $2'),
                    }),
                  );
                }
                
                if (property === 'rows') {
                  if (value < 1) {
                    // console.log('primero');
                    
                    this.elementFocused.ResponsiveProperties().rows = 1;
                  }
                  
                  if (value > parentPosition.rows || this.elementFocused.ResponsiveProperties().y + value > parentPosition.rows) {
                    // console.log('segundo');
                    
                    this.elementFocused.ResponsiveProperties().rows = parentPosition.rows - this.elementFocused.ResponsiveProperties().y;
                  }
                  
                  this.propertiesForm.controls[property].setValue(this.elementFocused.ResponsiveProperties().rows);
                  
                  positionPropertiesToSave.push(
                    new PropertyVersioningDto({
                      elementId: this.elementFocused.Id.toString(),
                      property: property,
                      value: this.elementFocused.ResponsiveProperties().rows,
                      path: path.join('.'),
                      change: 'Edit',
                      name: property.replace(/([a-z])([A-Z])/g, '$1 $2'),
                    }),
                  );
                }
                
                this.snackerService.ShowMessageOnBottom(`Can't place this element beyond limits`, 'move_selection_right');
                
                this.busService.Get(this.elementFocused.ParentId.toString()).GridsterConfig.api.optionsChanged();
                this.elementFocused.$UpdateValue.emit(true);
                this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(
                  this.elementFocused.Type === RepresentativeMoleculesType.WorkGroup
                    ? this.elementFocused
                    : this.busService.Get(this.elementFocused.ParentId.toString()),
                );
                this.ref.markForCheck();
                
                if (positionPropertiesToSave.length > 0) {
                  this.propertiesService.SaveProperties(positionPropertiesToSave).subscribe();
                }
                
                return;
              }
            }
          }
          
          break;
      }
      
      switch (property) {
        case 'search-property':
          property = property.replace('-property', '');
          break;
      }
      
      this.savingProperties = true;
      this.workAreaService.elementsSelected.forEach(es => {
        if (!avoidSaveForMoleculeIds.includes(es.Id)) {
          console.log('looping');
          // for values that alter others
          switch (property) {
            case 'borderRadius':
              if (es.Properties.hover.hoverBorderRadius === previousValue) {
                this.UpdateAndSaveElementProperty('hoverBorderRadius', value, previousValue);
              }
              break;
            
            case 'hoverBackgroundOpacity':
              if (es.Properties.hover.hoverBackground === 'transparent') {
                this.UpdateAndSaveElementProperty('hoverBackground', '#ffffff', 'transparent');
              }
              break;
            
            case 'backgroundOpacity':
              if (es.Properties.background.backgroundColor === 'transparent') {
                this.UpdateAndSaveElementProperty('backgroundColor', '#ffffff', 'transparent');
              }
              break;
          }
          
          this.UpdateAndSaveUpdatedProperty(es, property, value, path);
          es.RefreshUI();
          
          if (es.Type === RepresentativeMoleculesType.WorkGroup || es.Type === RepresentativeMoleculesType.Stepper) {
            this.communicationService.Event.System.App.$RefreshUI.emit();
          }
        }
      });
      
      if (property === 'name') {
        this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit(true);
      }
    } catch (error) {
      console.error('error saving property', error);
      this.snackerService.ShowMessageOnBottom('Error saving the changes!');
    }
  }
  
  UpdateAndSaveUpdatedProperty(repMolecule: IRepresentativeMolecule, property: string, value: any, path: string[]) {
    const parent = this.busService.Get(repMolecule.ParentId.toString());
    
    if (parent.Type === MoleculesType.Cobble) {
      this.communicationService.Event.System.App.$AppChanged.emit(true);
    } else {
      parent.GridsterConfig.api.optionsChanged();
    }
    
    if (repMolecule.Type === RepresentativeMoleculesType.WorkGroup || repMolecule.Type === RepresentativeMoleculesType.Stepper) {
      setTimeout(() => {
        if (property === 'cols') {
          // console.log('evaluate cols wg');
          
          this.workAreaService.ResizeWorkgroupWidth(repMolecule.Id);
          repMolecule.ResponsiveProperties().colsQty = value;
          repMolecule.GridsterConfig.minCols = repMolecule.GridsterConfig.maxCols = value;
          repMolecule.GridsterConfig.maxItemCols = value;
          
          repMolecule.GridsterConfig.api.optionsChanged();
          this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(repMolecule.Id);
          
          repMolecule
          .SavePropertyFromVersioning(
            new PropertyVersioningDto({
              elementId: repMolecule.Id.toString(),
              property: 'colsQty',
              value: value,
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
              change: 'Edit',
              name: 'Cols Qty',
            }),
          )
          .subscribe();
        }
        if (property === 'rows') {
          this.workAreaService.ResizeWorkgroupHeight(repMolecule.Id);
          repMolecule.ResponsiveProperties().rowsQty = value;
          repMolecule.GridsterConfig.minRows = repMolecule.GridsterConfig.maxRows = value;
          repMolecule.GridsterConfig.maxItemRows = value;
          
          if (repMolecule.GridsterConfig.api) {
            repMolecule.GridsterConfig.api.optionsChanged();
          }
          this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(repMolecule.Id);
          
          repMolecule
          .SavePropertyFromVersioning(
            new PropertyVersioningDto({
              elementId: repMolecule.Id.toString(),
              property: 'rowsQty',
              value: value,
              path: `properties.responsive.${ this.cobbleService.Cobble.deviceType }`,
              change: 'Edit',
              name: 'Rows Qty',
            }),
          )
          .subscribe();
        }
      }, 900);
    }
    
    repMolecule.UpdateProperty(
      null,
      value,
      {
        elementId: repMolecule.Id,
        path: path.join('.'),
        property: property,
        value: value,
      },
      this.busService,
    );
    
    repMolecule.$UpdateValue.emit(true);
    this.communicationService.Event.System.App.$RefreshUI.emit(true);
    this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(
      repMolecule.Type === RepresentativeMoleculesType.WorkGroup ? repMolecule : this.busService.Get(repMolecule.ParentId.toString()),
    );
    
    this.ref.markForCheck();
    
    if (Number.isInteger(value as any)) {
      value = parseInt(value, 10);
    }
    
    repMolecule
    .SavePropertyFromVersioning(
      new PropertyVersioningDto({
        elementId: repMolecule.Id.toString(),
        property: property,
        value: value,
        path: path.join('.'),
        change: 'Edit',
        name: property.replace(/([a-z])([A-Z])/g, '$1 $2'),
      }),
    )
    .subscribe();
    
    this.SaveManualAdjustedProperties(repMolecule, property, path.join('.'));
  }
  
  SaveManualAdjustedProperties(repMolecule: IRepresentativeMolecule, property: string, path: string) {
    if (
      repMolecule.StyleMetadata.styles.length > 0 &&
      !repMolecule.StyleMetadata.manualAdjustedProperties.find(p => p.path === path && p.property === property)
    ) {
      repMolecule.StyleMetadata.manualAdjustedProperties.push({ property, path });
      repMolecule
      .SavePropertyFromVersioning(
        new PropertyVersioningDto({
          elementId: repMolecule.Id.toString(),
          property: 'styleMetadata',
          value: repMolecule.StyleMetadata,
          path: '',
          change: 'Style edited',
          name: property.replace(/([a-z])([A-Z])/g, '$1 $2'),
        }),
      )
      .subscribe();
    }
    
    this.UpdateAdjustedProperties();
  }
  
  SelectChart(chartType: string) {
    this.UpdateAndSaveElementProperty('chartType', chartType);
    this.elementFocused.UpdateProperty('chartType', chartType, null, this.busService);
  }
  
  SelectIcon(icon: string) {
    this.elementFocused.Properties.icon.iconType = icon;
    this.UpdateAndSaveElementProperty('iconType', icon);
    this.communicationService.Event.System.Update.$ChangesOnMolecules.emit(this.elementFocused);
  }
  
  selectFile() {
    document.getElementById('imageFileSelector').click();
  }
  
  fileSelected(eventData: any[]) {
    // console.log('file selected', eventData[0]);
    const file = eventData[0];
    const filename = file.name;
    const noCompressFileTypes = ['image/gif'];
    
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageCompress.compressFile(event.target.result, -1, 60, 50).then(compressedImg => {
          this.toolsService
          .UrlToFile(file.size < 50000 || noCompressFileTypes.includes(file.type) ? event.target.result : compressedImg, filename, file.type)
          .then(imageFile => {
            // console.log('file compressed', imageFile);
            
            this.fileService.uploadFile([imageFile], 1).subscribe(result => {
              if (this.elementFocused.Type === RepresentativeMoleculesType.Image) {
                this.elementFocused.Properties.source = result;
                this.UpdateAndSaveElementProperty('source', result);
              } else {
                this.elementFocused.Properties.background.backgroundImageUrl = result;
                this.elementFocused
                .SavePropertyFromVersioning(
                  new PropertyVersioningDto({
                    elementId: this.elementFocused.Id.toString(),
                    property: 'backgroundImageUrl',
                    value: result,
                    path: 'properties.background',
                    change: `Background Image`,
                    name: 'Background image',
                  }),
                )
                .subscribe(r => {
                  this.SaveManualAdjustedProperties(this.elementFocused, 'backgroundImageUrl', 'properties.background');
                  this.communicationService.Event.System.Update.$RefreshWorkgroups.emit(this.elementFocused.Id);
                });
              }
            });
          });
        });
      };
      reader.readAsDataURL(file);
    }
  }
  
  GenerateTableColumnsArray() {
    const cols = [];
    if (this.elementFocused.Value && Array.isArray(this.elementFocused.Value) && this.elementFocused.Value.length > 0) {
      const lastCol = Math.max.apply(
        Math,
        this.elementFocused.Value.map(function(o) {
          return o.col;
        }),
      );
      
      for (let index = this.elementFocused.Value[0].col; index <= lastCol; index++) {
        cols.push(index);
      }
    }
    return cols;
  }
  
  ColumnWidthChanged(e: any, col: number) {
    const tableWidth = this.elementFocused.ResponsiveProperties().tableOptions.tableWidth || {};
    tableWidth[col] = {
      columnWidth: +e.target.value,
    };
    
    this.UpdateAndSaveElementProperty('tableWidth', this.elementFocused.ResponsiveProperties().tableOptions.tableWidth);
  }
  
  GetColumnWidthValue(col: number): number {
    const colWidth = this.elementFocused.ResponsiveProperties().tableOptions.tableWidth[col] || { columnWidth: Constants.Defaults.ColumnWidth };
    return colWidth.columnWidth >= 0 ? colWidth.columnWidth : Constants.Defaults.ColumnWidth;
  }
  
  ColumnShowChanged(e: any, col: number) {
    this.elementFocused.ResponsiveProperties().tableOptions.tableWidth[col + 1].columnShow = e.checked;
    
    this.toolsService.Throttle(
      (func, delay, context) => {
        this.UpdateAndSaveElementProperty('tableWidth', this.elementFocused.ResponsiveProperties().tableOptions.tableWidth);
      },
      1000,
      this,
      null,
    );
  }
  
  removeOption(option) {
    const index = (<any[]>this.elementFocused.Properties.options).findIndex(o => o.value === option.value);
    
    if (index >= 0) {
      (<any[]>this.elementFocused.Properties.options).splice(index, 1);
      this.UpdateAndSaveElementProperty('options', this.elementFocused.Properties.options);
      
      this.elementFocused.ResponsiveProperties().rows = this.elementFocused.ResponsiveProperties().rows - 3;
      this.UpdateAndSaveElementProperty('rows', this.elementFocused.ResponsiveProperties().rows);
    }
  }
  
  ExistsPrimaryType(type: string): boolean {
    return !!this.workAreaService.primaryElementsSelected.find(pse => pse.Type === type);
  }
  
  GetPrimaryElement(type = null) {
    if (this.workAreaService.elementsSelected.length > 1) {
      return this.elementFocused;
    }
    
    if (type) {
      return this.workAreaService.primaryElementsSelected.find(pes => pes.Type === type);
    } else {
      return this.workAreaService.primaryElementsSelected[0];
    }
  }
  
  GetPrimaryShadowElement(type = null) {
    if (type) {
      return this.primaryShadowElements.find(pes => pes.Type === type) || this.primaryShadowElements[0];
    } else {
      return this.primaryShadowElements[0];
    }
  }
  
  GetTableColumnName(colIndex: number): string {
    const colValue = this.GetPrimaryElement().HeaderValue.find(v => v.col === colIndex);
    const columnName: string =
      colValue && colValue.value
        ? colValue.value
        : colValue.context
          ? colValue.context.split(Constants.ContextSeparator).pop()
          : 'Column ' + colIndex;
    return columnName.toString().replace('(Heading)', '');
  }
  
  HeaderTextAlignmentChanged(alignment: string, e: any) {
    switch (alignment) {
      case 'left':
        this.headerRightTextAlignment.checked = false;
        this.headerCenterTextAlignment.checked = false;
        break;
      case 'center':
        this.headerLeftTextAlignment.checked = false;
        this.headerRightTextAlignment.checked = false;
        break;
      case 'right':
        this.headerLeftTextAlignment.checked = false;
        this.headerCenterTextAlignment.checked = false;
        break;
    }
    
    this.UpdateAndSaveElementProperty('headerTextAlignment', alignment);
  }
  
  ngOnDestroy(): void {
    if (this.subscriptions) {
      // console.log('unsibscribe');
      this.subscriptions.unsubscribe();
    }
  }
  
  ResetClicked(input) {
    input.clicked = false;
  }
  
  Deselect(input) {
    input.selectionStart = input.selectionEnd;
  }
  
  Select(event: MouseEvent) {
    const input = event.target as any;
    if (input.clicked) {
    } else {
      input.select();
      input.clicked = true;
    }
  }
  
  EvaluateLimits(input: NgModel, event: any, min: number, max: number) {
    if (input.control.value > max) {
      input.control.setValue(max);
    }
    
    if (input.control.value < min) {
      input.control.setValue(min);
    }
  }
  
  GetSpecificStyles() {
    if (this.workAreaService.elementsSelected.length > 1) {
      return;
    }
    
    const appliedStyles = this.representativeMoleculeStyles.filter(s => this.elementFocused.StyleMetadata.styles.includes(s.styleId));
    
    this.representativeMoleculeStylesDimension = [];
    this.representativeMoleculeStylesFont = [];
    this.representativeMoleculeStylesHover = [];
    this.representativeMoleculeStylesFrame = [];
    this.representativeMoleculeStylesAppearance = [];
    
    appliedStyles.forEach(as => {
      switch (as.section) {
        case 'all':
          this.representativeMoleculeStylesDimension.push(as);
          this.representativeMoleculeStylesFont.push(as);
          this.representativeMoleculeStylesHover.push(as);
          this.representativeMoleculeStylesFrame.push(as);
          this.representativeMoleculeStylesAppearance.push(as);
          
          if (this.elementFocused.StyleMetadata.specificStyles.dimension) {
            if (this.elementFocused.StyleMetadata.specificStyles.dimension.styleId === as.styleId) {
              this.representativeMoleculeStylesDimensionSelected = as;
            }
          } else {
            this.representativeMoleculeStylesDimensionSelected = as;
          }
          
          if (this.elementFocused.StyleMetadata.specificStyles.appearance) {
            if (this.elementFocused.StyleMetadata.specificStyles.appearance.styleId === as.styleId) {
              this.representativeMoleculeStylesAppearanceSelected = as;
            }
          } else {
            this.representativeMoleculeStylesAppearanceSelected = as;
          }
          
          if (this.elementFocused.StyleMetadata.specificStyles.frame) {
            if (this.elementFocused.StyleMetadata.specificStyles.frame.styleId === as.styleId) {
              this.representativeMoleculeStylesFrameSelected = as;
            }
          } else {
            this.representativeMoleculeStylesFrameSelected = as;
          }
          
          if (this.elementFocused.StyleMetadata.specificStyles.hover) {
            if (this.elementFocused.StyleMetadata.specificStyles.hover.styleId === as.styleId) {
              this.representativeMoleculeStylesHoverSelected = as;
            }
          } else {
            this.representativeMoleculeStylesHoverSelected = as;
          }
          
          if (this.elementFocused.StyleMetadata.specificStyles.font) {
            if (this.elementFocused.StyleMetadata.specificStyles.font.styleId === as.styleId) {
              this.representativeMoleculeStylesFontSelected = as;
            }
          } else {
            this.representativeMoleculeStylesFontSelected = as;
          }
          
          break;
        case 'dimension':
          this.representativeMoleculeStylesDimension.push(as);
          if (this.elementFocused.StyleMetadata.specificStyles.dimension) {
            if (this.elementFocused.StyleMetadata.specificStyles.dimension.styleId === as.styleId) {
              this.representativeMoleculeStylesDimensionSelected = as;
            }
          } else {
            this.representativeMoleculeStylesDimensionSelected = as;
          }
          break;
        case 'font':
          this.representativeMoleculeStylesFont.push(as);
          if (this.elementFocused.StyleMetadata.specificStyles.font) {
            if (this.elementFocused.StyleMetadata.specificStyles.font.styleId === as.styleId) {
              this.representativeMoleculeStylesFontSelected = as;
            }
          } else {
            this.representativeMoleculeStylesFontSelected = as;
          }
          break;
        case 'hover':
          this.representativeMoleculeStylesHover.push(as);
          
          if (this.elementFocused.StyleMetadata.specificStyles.hover) {
            if (this.elementFocused.StyleMetadata.specificStyles.hover.styleId === as.styleId) {
              this.representativeMoleculeStylesHoverSelected = as;
            }
          } else {
            this.representativeMoleculeStylesHoverSelected = as;
          }
          break;
        case 'frame':
          this.representativeMoleculeStylesFrame.push(as);
          if (this.elementFocused.StyleMetadata.specificStyles.frame) {
            if (this.elementFocused.StyleMetadata.specificStyles.frame.styleId === as.styleId) {
              this.representativeMoleculeStylesFrameSelected = as;
            }
          } else {
            this.representativeMoleculeStylesFrameSelected = as;
          }
          break;
        case 'appearance':
          this.representativeMoleculeStylesAppearance.push(as);
          
          if (this.elementFocused.StyleMetadata.specificStyles.appearance) {
            if (this.elementFocused.StyleMetadata.specificStyles.appearance.styleId === as.styleId) {
              this.representativeMoleculeStylesAppearanceSelected = as;
            }
          } else {
            this.representativeMoleculeStylesAppearanceSelected = as;
          }
          break;
      }
    });
  }
  
  RemoveStyles() {
    this.elementFocused.ResetStyles();
    this.GetSpecificStyles();
  }
  
  StyleOrderPriorityChange() {
    if (this.workAreaService.elementsSelected.length > 0) {
      this.workAreaService.elementsSelected.forEach(es => {
        const repMoleculeStyles = this.representativeMoleculeStyles.filter(as => es.StyleMetadata.styles.includes(as.styleId));
        es.StyleMetadata.styles = repMoleculeStyles.map(s => s.styleId);
        this.elementFocused.ApplyAssignedStyle();
        this.elementFocused
        .SavePropertyFromVersioning(
          new PropertyVersioningDto({
            elementId: es.Id.toString(),
            property: 'styleMetadata',
            value: es.StyleMetadata,
            path: '',
            change: 'Style edited',
            name: 'Styles',
          }),
        )
        .subscribe();
      });
      this.workAreaService.elementsSelected[0].RefreshParent();
    } else {
      const repMoleculeStyles = this.representativeMoleculeStyles.filter(as => this.elementFocused.StyleMetadata.styles.includes(as.styleId));
      this.elementFocused.StyleMetadata.styles = repMoleculeStyles.map(s => s.styleId);
      this.elementFocused.ApplyAssignedStyle();
      this.elementFocused
      .SavePropertyFromVersioning(
        new PropertyVersioningDto({
          elementId: this.elementFocused.Id.toString(),
          property: 'styleMetadata',
          value: this.elementFocused.StyleMetadata,
          path: '',
          change: 'Style edited',
          name: 'Styles',
        }),
      )
      .subscribe();
      this.elementFocused.RefreshParent();
    }
    
    this.GetSpecificStyles();
  }
  
  SaveStyles() {
    // const appliedStyles = this.representativeMoleculeStyles.filter(s => this.elementFocused.StyleMetadata.styles.includes(s.styleId));
    // this.elementFocused.StyleMetadata.styles = appliedStyles.map(s => s.styleId);
    this.GetSpecificStyles();
    this.elementFocused.ApplyAssignedStyle();
    
    // this.elementFocused.SavePropertyFromVersioning(new PropertyVersioningDto({
    //   elementId: this.elementFocused.Id.toString(),
    //   property: 'styleMetadata',
    //   value: this.elementFocused.StyleMetadata,
    //   path: '',
    //   change: 'Style edited',
    //   name: 'Styles'
    // })).subscribe();
    
    this.InitPropertiesPanel();
  }
  
  ToggleStylesPanel() {
    this.styleContainerOpen = !this.styleContainerOpen;
  }
  
  SetSpecificStyle(section: string, event: MatSelectChange) {
    console.log(section, event);
    
    const style = event.source.value;
    
    if (style === 'none') {
      if (this.elementFocused.StyleMetadata.specificStyles[section]) {
        const specificStyleId = this.elementFocused.StyleMetadata.specificStyles[section].styleId;
        this.elementFocused.RemoveStyle(specificStyleId);
      }
      
      this.elementFocused.StyleMetadata.specificStyles[section] = {
        styleId: style,
        path: `responsive.${ this.cobbleService.Cobble.deviceType }.${ section }`,
      };
      this.elementFocused.ApplyAssignedStyle();
      this.GetSpecificStyles();
    } else if (section && section !== '' && style) {
      this.elementFocused.StyleMetadata.specificStyles[section] = {
        styleId: style.styleId,
        path: `responsive.${ this.cobbleService.Cobble.deviceType }.${ section }`,
      };
      this.elementFocused.ApplyStyle([style], section);
    }
    
    this.elementFocused
    .SavePropertyFromVersioning(
      new PropertyVersioningDto({
        elementId: this.elementFocused.Id.toString(),
        property: 'styleMetadata',
        value: this.elementFocused.StyleMetadata,
        path: '',
        change: 'Style edited',
        name: 'Styles',
      }),
    )
    .subscribe();
  }
  
  ToggleStyle(checked: boolean, style: IRepresentativeMoleculeStyleData) {
    if (checked) {
      if (this.workAreaService.elementsSelected.length > 0) {
        this.workAreaService.elementsSelected.forEach(es => {
          es.AddStyle(style.styleId);
          es.ApplyAssignedStyle(true, true);
        });
      } else {
        this.elementFocused.AddStyle(style.styleId);
        this.elementFocused.ApplyAssignedStyle(true, true);
      }
      this.workAreaService.SaveLastUsedElement('style', style.section, style.name);
    } else {
      if (this.workAreaService.elementsSelected.length > 0) {
        this.workAreaService.elementsSelected.forEach(es => {
          es.RemoveStyle(style.styleId);
          es.ApplyAssignedStyle(true, true);
        });
      } else {
        this.elementFocused.RemoveStyle(style.styleId);
        this.elementFocused.ApplyAssignedStyle(true, true);
      }
    }
    this.SaveStyles();
    this.MoveAppliedStylesToTop();
  }
  
  PreviewRepresentativeMoleculeStyle(style: IRepresentativeMoleculeStyleData, event: MouseEvent) {
    const repMoleculeTemplate = this.templatesService.GetRepresentativeTemplateByType(style.representativeMoleculeType);
    const repMolecule = new RepresentativeMolecule(cloneDeep(repMoleculeTemplate));
    repMolecule.ApplyStyle([style]);
    
    this.draggableWindowService.OpenDraggableWindow(
      `Preview Style [${ style.representativeMoleculeType } - ${ style.section.toLocaleUpperCase() } - ${ style.name }]`,
      DraggableWindowType.PreviewRepresentativeMoleculeComponent,
      event,
      { repMolecule },
      true,
    );
  }
  
  EditStyle(style: IRepresentativeMoleculeStyleData, event: MouseEvent) {
    const repMoleculeTemplate = this.templatesService.GetRepresentativeTemplateByType(style.representativeMoleculeType);
    const repMolecule = new RepresentativeMolecule(cloneDeep(repMoleculeTemplate));
    repMolecule.ApplyStyle([style]);
    
    this.draggableWindowService.OpenDraggableWindow(
      `Style [${ style.representativeMoleculeType } - ${ style.name }] Properties`,
      DraggableWindowType.StyleProperties,
      event,
      { style },
      false,
    );
    this.communicationService.Event.Editor.WorkArea.$RefreshPropertiesStyles.emit();
  }
  
  changeSliderThumbLabel(value: boolean) {
    this.UpdateAndSaveElementProperty('sliderThumbLabel', value, !value);
    this.elementFocused.Properties.sliderThumbLabel = value;
  }
  
  changeSliderTicks(value: boolean) {
    this.UpdateAndSaveElementProperty('sliderTicks', value, !value);
    this.elementFocused.Properties.sliderTicks = value;
  }
  
  changeSliderConstraints() {
    this.elementFocused.Value = (this.elementFocused.Properties.maxValue + this.elementFocused.Properties.minValue) / 2;
  }
  
  BreadcrumbViewRenamed(event: any, view: View) {
    console.log(event.target.value, view);
    const newName = event.target.value;
    
    this.elementFocused.Properties.viewNames = this.elementFocused.Properties.viewNames.filter(vn => vn.id !== view.id);
    
    if (newName && newName !== '') {
      this.elementFocused.Properties.viewNames.push({
        id: view.id,
        name: newName,
      });
    }
    
    this.elementFocused
    .SavePropertyFromVersioning(
      new PropertyVersioningDto({
        elementId: this.elementFocused.Id.toString(),
        property: 'viewNames',
        value: this.elementFocused.Properties.viewNames,
        path: 'properties',
        change: 'Breadcrumb view renamed',
        name: 'View names',
      }),
    )
    .subscribe();
    
    console.log(this.elementFocused.Properties.viewNames);
  }
  
  AddCustomEvent(eventName: string) {
    if (eventName === '') {
      return;
    }
    console.log(eventName);
    this.elementFocused.Properties.customControl.events.push({ name: eventName });
    this.communicationService.Event.Editor.EventsTree.$RefreshEventsTree.emit();
    this.UpdateAndSaveElementProperty('customEvents', this.elementFocused.Properties.customControl.events);
  }
  
  OpenWebEditor() {
    this.draggableWindowService.OpenDraggableWindow('Custom Control Editor', DraggableWindowType.CustomControlEditor, null, {
      repMolecule: this.elementFocused,
    });
  }
  
  SwitchCustomControlTemplateVersion(version: any) {
    console.log(version);
    this.elementFocused.TemplateId = version.templateId;
    
    this.templateService.GetTemplate(version.templateId).subscribe(template => {
      const templateCustomControl = template.properties.customControl;
      this.UpdateAndSaveElementProperty('html', templateCustomControl.html);
      this.UpdateAndSaveElementProperty('css', templateCustomControl.css);
      this.UpdateAndSaveElementProperty('js', templateCustomControl.js);
      this.UpdateAndSaveElementProperty('options', templateCustomControl.options);
      this.UpdateAndSaveElementProperty('receptors', templateCustomControl.receptos);
      this.UpdateAndSaveElementProperty('events', templateCustomControl.events);
      
      this.templateService.ChangeMoleculeTemplate(this.elementFocused.Id, version.templateId).subscribe(response => {
        this.snackerService.ShowMessageOnBottom('Custom control template updated');
      });
    });
  }
  
  ResetAllManuallyAdjustedProperties() {
    this.elementFocused.StyleMetadata.manualAdjustedProperties = [];
    this.elementFocused.SaveProperty('StyleMetadata', 'Reset all style properties').subscribe();
    this.communicationService.Event.Editor.WorkArea.$PropertiesUpdated.emit();
    this.elementFocused.ApplyAssignedStyle(false);
    this.UpdateAdjustedProperties();
  }
  
  RemovePlaceholder(placeholder: string) {
    this.elementFocused.RemovePlaceholder(placeholder);
    this.UpdateAndSaveElementProperty('placeholders', this.elementFocused.Placeholders);
  }
}
