import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { flattenDeep, remove } from 'lodash-es';
import { Subscription } from 'rxjs';
import { BuilderService } from '../../core/builder/builder.service';
import { BusService } from '../../core/molecular/services/bus.service';
import { EventsService } from '../../core/molecular/services/events.service';
import { ApiPropertiesService } from '../../core/services/api-properties.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { TemplateService } from '../../core/services/template.service';
import { ToolsService } from '../../core/services/tools.service';
import { DragType } from '../../shared/enums/drag-type.enum';
import { MoleculesType } from '../../shared/enums/molecules-type.enum';
import { ParticleType } from '../../shared/enums/particle-type.enum';
import { Bus } from '../../shared/representative-molecule/interfaces/bus';
import { DataElement } from '../../shared/representative-molecule/interfaces/data-element';
import { LeapXLEvent } from '../../shared/representative-molecule/interfaces/leapxl-event';
import { Particle } from '../../shared/representative-molecule/interfaces/particle';
import { RepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule';
import { IRepresentativeMolecule } from '../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { CobbleService } from '../../shared/representative-molecule/services/cobble.service';
import { DragService } from '../../shared/representative-molecule/services/drag.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { DraggableWindowManagerService } from '../../shared/services/draggable-window-manager.service';
import { FactoryParticleService } from '../../shared/services/factory-particle.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SpreadsheetService } from '../../spreadsheet/spreadsheet.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-process-properties',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ transform: 'scale(0)', opacity: 0 }), animate('150ms', style({ transform: 'scale(1)', opacity: 1 }))]),
    ]),
  ],
})
export class ProcessComponent implements OnInit, OnDestroy {
  @ViewChild('editBusNameInput', { static: false })
  editBusNameInput: ElementRef;
  DraggableWindowType = 'ProcessPanel';
  elementFocused: IRepresentativeMolecule;
  hovering = false;
  events: any[] = [];
  refreshing = true;
  subscriptions: Subscription;
  scrollValue: number;
  intoPanel = false;
  draggableWindowHeight = 0;
  private draggableWindowExpansion = 1000;
  
  constructor(
    public workAreaService: WorkAreaService,
    public cobbleService: CobbleService,
    public busService: BusService,
    private eventsService: EventsService,
    public dragService: DragService,
    private builderService: BuilderService,
    private spreadsheetService: SpreadsheetService,
    public toolsService: ToolsService,
    public draggableWindowManagerService: DraggableWindowManagerService,
    private genericDialogService: GenericDialogService,
    private propertiesService: ApiPropertiesService,
    private templateService: TemplateService,
    private snackerService: SnackerService,
    private communicationService: CommunicationService,
    private particleFactoryService: FactoryParticleService,
  ) {
  }
  
  ngOnInit() {
    this.subscriptions = this.communicationService.Event.Editor.$RecreateProcessBuses.subscribe(value => {
      console.log('=event=');
      this.refreshing = true;
      setTimeout(() => {
        this.CreateProcessBuses();
      }, 100);
    });
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$SelectedElementsChange.subscribe(elementsSelected => {
        console.log('=event=');
        this.refreshing = true;
        this.CreateProcessBuses();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.WorkArea.$PropertiesUpdated.subscribe(elementsSelected => {
        console.log('=event=');
        this.refreshing = true;
        this.CreateProcessBuses();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$DragParticleStartFromSidePanelStart.subscribe(elementsSelected => {
        console.log('=event=');
        this.ExpandDraggableWindow();
      }),
    );
    
    this.subscriptions.add(
      this.communicationService.Event.Editor.$DragParticleStartFromSidePanelStop.subscribe(elementsSelected => {
        console.log('=event=');
        this.RevertExpandDraggableWindow();
      }),
    );
    
    this.CreateProcessBuses();
    this.events = this.eventsService.GetViewElementsEvents();
    
    // console.log(this.workAreaService.elementsSelected);
  }
  
  DragOver(e: any) {
    e.preventDefault();
    this.hovering = true;
  }
  
  CreateProcessBuses() {
    if (this.workAreaService.elementsSelected.length === 0) {
      return;
    }
    
    this.refreshing = false;
    this.elementFocused = this.workAreaService.primaryElementsSelected[0];
    this.workAreaService.GetEventsForSelectedMolecule(this.elementFocused);
    console.log('creating process panel');
    this.workAreaService.shadowBuses = [];
    if (this.workAreaService.elementsSelected.length > 1) {
      this.workAreaService.elementsSelectedShadowParticleAssociation = {};
      const buses = this.workAreaService.elementsSelected.map(es => es.Buses).filter(bg => bg.length > 0);
      const clonedBuses = [];
      
      buses.forEach(bg => bg.forEach(b => clonedBuses.push(b.Clone())));
      
      const allBuses: Bus[] = flattenDeep(clonedBuses) as Bus[];
      // console.log(allBuses);
      
      const busesGroup = [];
      let i = allBuses.length;
      while (allBuses.length > 0 && i > 0) {
        const group = remove(allBuses, bus => {
          return this.IsSameBus(bus, allBuses[0]);
        });
        
        // console.log(group, allBuses);
        // allBuses.shift();
        busesGroup.push(group);
        i = i - 1;
      }
      
      // this.elementFocused.Buses.forEach(b => {
      //   busesGroup.forEach(bg => {
      //     const bus = bg.find(bog => bog.id === b.id);
      //     if(bus){
      //       bg = bg.filter(bog => bog.id !== b.id);
      //       bg.unshift(b);
      //     }
      //   });
      // });
      
      // console.log('busesGroup', busesGroup);
      
      busesGroup.forEach((bg: Bus[]) => {
        if (bg.length > 0) {
          const shadowBus = new Bus({
            Name: bg[0].Name,
            Receptor: bg[0].Receptor,
            Particles: [],
          });
          
          const firstBus = bg.shift();
          
          firstBus.Particles.forEach(particle => {
            let elementsFound: IRepresentativeMolecule[] = [];
            
            switch (particle.ParticleType) {
              case ParticleType.Molecule:
                elementsFound = this.workAreaService.elementsSelected.filter(
                  es => !!es.Buses.find(b => b.Receptor === firstBus.Receptor && !!b.Particles.find(p => p.IsSameParticle(particle))),
                );
                break;
              
              case ParticleType.Event:
                elementsFound = this.workAreaService.elementsSelected.filter(
                  es => !!es.Buses.find(b => b.Receptor === firstBus.Receptor && !!b.Particles.find(p => p.IsSameParticle(particle))),
                );
                break;
              
              case ParticleType.DataElement:
                elementsFound = this.workAreaService.elementsSelected.filter(
                  es => !!es.Buses.find(b => b.Receptor === firstBus.Receptor && !!b.Particles.find(p => p.IsSameParticle(particle))),
                );
                break;
            }
            
            // console.log('elements found', particle, elementsFound);
            if (elementsFound.length >= 1) {
              // let particles = [];
              
              const bps = [];
              bps.push({
                busId: firstBus.id,
                particles: [particle.Clone()],
              });
              
              bg.forEach(b => {
                let busParticles = [];
                busParticles = b.Particles.filter(p => p.IsSameParticle(particle));
                
                if (busParticles.length > 0) {
                  // particles = particles.concat(busParticles);
                  bps.push({
                    busId: b.id,
                    particles: busParticles,
                  });
                }
              });
              
              // particles.push(particle);
              const particleBps = [];
              
              bps.forEach(bp => {
                particleBps.push({
                  particleId: particle.ParticleId,
                  busId: bp.busId,
                  repMolecule: this.GetRepresentativeMoleculeByBusId(this.workAreaService.elementsSelected, bp.busId),
                  particles: bp.particles,
                });
              });
              
              const busesFound = (flattenDeep(elementsFound.map(ef => ef.Buses)) as Bus[]).filter(
                b => this.IsSameBus(b, firstBus) && b.Particles.find(p => p.IsSameParticle(particle)),
              );
              
              // console.log('different', busesFound);
              particle.Enable = busesFound.length >= elementsFound.length && elementsFound.length === this.workAreaService.elementsSelected.length;
              // particle.ParticleId = this.toolsService.GenerateGuid();
              
              this.workAreaService.elementsSelectedShadowParticleAssociation[particle.ParticleId] = particleBps;
              shadowBus.Particles.push(particle);
            } else {
              particle.Enable = false;
              shadowBus.Particles.push(particle);
              
              this.workAreaService.elementsSelectedShadowParticleAssociation[particle.ParticleId] = [
                {
                  particleId: particle.ParticleId,
                  busId: firstBus.id,
                  repMolecule: this.GetRepresentativeMoleculeByBusId(this.workAreaService.elementsSelected, firstBus.id),
                  particles: [particle],
                },
              ];
            }
            
            // removing found repeated particles
            bg.forEach(b => {
              b.Particles = b.Particles.filter(p => !p.IsSameParticle(particle));
            });
          });
          
          // console.log('bus group', bg);
          
          flattenDeep(bg.map(b => b.Particles)).forEach((particle: Particle) => {
            const blah = [];
            bg.forEach(bg1 => {
              const found = remove(bg1.Particles, p => {
                return p.IsSameParticle(particle);
              });
              
              if (found.length > 0) {
                blah.push({
                  busId: bg1.id,
                  particles: found,
                });
              }
            });
            
            // console.log(blah);
            
            if (blah.length > 0) {
              // particle.ParticleId = this.toolsService.GenerateGuid();
              particle.Enable = false;
              shadowBus.Particles = shadowBus.Particles.concat(particle);
              
              const pm = [];
              blah.forEach(b => {
                pm.push({
                  particleId: particle.ParticleId,
                  busId: b.busId,
                  repMolecule: this.GetRepresentativeMoleculeByBusId(this.workAreaService.elementsSelected, b.busId),
                  particles: b.particles,
                });
              });
              this.workAreaService.elementsSelectedShadowParticleAssociation[particle.ParticleId] = pm;
            }
          });
          this.workAreaService.shadowBuses.push(shadowBus);
        }
      });
      
      // this include all not shared buses
      // allBuses.forEach(b => {
      //   b.Particles.forEach(p => {
      //     p.Enable = false;
      //   });
      //   this.workAreaService.shadowBuses.push(b);
      // });
      
      // console.log('shadowBuses', this.workAreaService.shadowBuses);
      // console.log('elementsSelectedShadowParticleAssociation', this.workAreaService.elementsSelectedShadowParticleAssociation);
    } else {
      if (this.elementFocused) {
        this.elementFocused.Buses.forEach(b => {
          b.Open = !(this.workAreaService.busState[b.id] === false);
          b.Particles.map(p => {
            p.Enable = true;
            return p;
          });
        });
      }
    }
    
    const processPanel = document.querySelector('.process-panel');
    
    if (processPanel && this.scrollValue) {
      setTimeout(() => {
        this.workAreaService.windowHorizontalLayout ? (processPanel.scrollLeft += this.scrollValue) : (processPanel.scrollTop += this.scrollValue);
        this.scrollValue = 0;
      }, 10);
    }
  }
  
  ClickNewProcess() {
    const repMoleculesToApply = this.workAreaService.elementsSelected.length > 1 ? this.workAreaService.elementsSelected : [this.elementFocused];
    
    repMoleculesToApply.forEach(repMolecule => {
      const newBus = repMolecule.GenerateNewBus(true);
      repMolecule.SaveProperty('buses', `New Bus Added`).subscribe();
    });
  }
  
  IsSameBus(bus1: Bus, bus2: Bus): boolean {
    return (
      bus1.Receptor === bus2.Receptor &&
      ((bus1.Particles.length === 0 && bus2.Particles.length === 0) ||
        (bus1.HasParticles() &&
          bus2.HasParticles() &&
          bus1.FirstParticle().IsEvent() &&
          bus2.FirstParticle().IsEvent() &&
          (bus1.FirstParticle() as LeapXLEvent).EventType === (bus2.Particles[0] as LeapXLEvent).EventType))
    );
  }
  
  DropData(positionIndex: number) {
    this.hovering = false;
    console.log('drag data', this.dragService.dragData);
    
    const repMoleculesToApply = this.workAreaService.elementsSelected.length > 1 ? this.workAreaService.elementsSelected : [this.elementFocused];
    if (this.dragService.dragData) {
      repMoleculesToApply.forEach(repMolecule => {
        let newBus: Bus = null;
        switch (this.dragService.dragData.dragType) {
          case DragType.Spreadsheet:
            newBus = repMolecule.GenerateNewBus(!(this.dragService.dragData.dragType === DragType.Event));
            
            const dataElement = new DataElement(this.spreadsheetService.SpreadSheetRangeSelected);
            dataElement.Reference = this.spreadsheetService.SpreadSheetRangeSelected.range;
            dataElement.ApplicationId = this.cobbleService.Cobble.id;
            dataElement.ParticleId = this.toolsService.GenerateGuid();
            // console.log(dataElement);
            
            this.particleFactoryService
            .CreateAndTranslateDataElements([dataElement], this.cobbleService.Cobble.id, DragType.Spreadsheet, this.spreadsheetService.editorDbMode)
            .then(dataElementsCreated => {
              this.templateService.GetActionMoleculeProperties(['GetElementsDatasourceDataMolecule']).subscribe(properties => {
                const newActionMolecule = this.particleFactoryService.GenerateActionMolecule(properties[0], repMolecule.Id, dataElementsCreated);
                newBus.AddParticles([newActionMolecule]);
                this.RefreshAndSaveDatSourceChange(repMolecule);
              });
            });
            
            break;
          case DragType.DataElement:
            newBus = repMolecule.GenerateNewBus(!(this.dragService.dragData.dragType === DragType.Event));
            const dataElements = this.dragService.dragData.data;
            dataElements.forEach(de => {
              de.ApplicationId = this.cobbleService.Cobble.id;
            });
            
            this.particleFactoryService
            .CreateAndTranslateDataElements(dataElements, this.cobbleService.Cobble.id, DragType.DataElement, this.spreadsheetService.editorDbMode)
            .then(dataElementsCreated => {
              this.templateService.GetActionMoleculeProperties(['GetElementsDatasourceDataMolecule']).subscribe(properties => {
                const newActionMolecule = this.particleFactoryService.GenerateActionMolecule(properties[0], repMolecule.Id, dataElementsCreated);
                newBus.AddParticles([newActionMolecule]);
                this.RefreshAndSaveDatSourceChange(repMolecule);
              });
            });
            
            break;
          case DragType.Event:
            newBus = repMolecule.GenerateNewBus(!(this.dragService.dragData.dragType === DragType.Event));
            newBus.AddParticle(new LeapXLEvent(this.dragService.dragData.data));
            repMolecule.SaveProperty('buses', `Molecule Added`).subscribe();
            
            break;
          case DragType.Molecule:
            switch (this.dragService.dragData.moleculeType) {
              case MoleculesType.CompoundMolecule:
                this.builderService.GetMolecule(repMolecule, this.dragService.dragData, null, null, [], 0, false, false);
                break;
              default:
                this.builderService
                .GetMolecule(repMolecule, this.dragService.dragData, null, null, [], 0, false, false)
                .then((molecule: RepresentativeMolecule) => {
                  this.communicationService.Event.Editor.WorkArea.$MoleculeUsed.emit(this.dragService.dragData);
                  repMolecule.SaveProperty('buses', `Molecule Added`).subscribe();
                });
                break;
            }
            break;
          default:
            break;
        }
      });
      const processPanel = document.querySelector('.process-panel');
      this.scrollValue = this.workAreaService.windowHorizontalLayout ? processPanel.scrollWidth : processPanel.scrollHeight;
      
      this.dragService.dragData.dragType = '';
    }
    
    setTimeout(() => {
      this.communicationService.Event.Editor.$RecreateProcessBuses.emit(true);
    }, 100);
  }
  
  GetRepresentativeMoleculeByBusId(repMolecules: IRepresentativeMolecule[], busId: string): IRepresentativeMolecule {
    return this.workAreaService.elementsSelected.find(es => !!es.Buses.find(b => b.id === busId));
  }
  
  BusDragStart(bus: Bus, event: DragEvent) {
    this.dragService.dragginBus = true;
    this.dragService.dragData = bus;
    bus.Dragging = true;
  }
  
  BusDragOver(bus: Bus) {
    if (this.dragService.dragData) {
      if (!this.dragService.dragginBus || this.dragService.dragData.id === bus.id) {
        return;
      }
      
      this.SwapBuses(
        this.elementFocused.Buses.findIndex(b => b.id === this.dragService.dragData.id),
        this.elementFocused.Buses.findIndex(b => b.id === bus.id),
      );
    }
  }
  
  RefreshAndSaveDatSourceChange(repMolecule: IRepresentativeMolecule) {
    this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
    
    setTimeout(() => {
      repMolecule.HighlightDatasourcesPath();
      repMolecule.HighlightEventsPath();
      repMolecule.HighlightViewsPath();
      repMolecule.FireDataSourceBus();
    }, 300);
    repMolecule.RefreshDatasourceConnected();
    repMolecule.SaveProperty('buses', `Molecule Added`).subscribe();
  }
  
  BusDragStop(bus: Bus, event: any) {
    bus.Dragging = false;
    this.dragService.dragData = null;
    this.dragService.dragginBus = false;
    this.elementFocused.SaveProperty('buses', `Buses reordered`).subscribe();
  }
  
  SwapBuses(firstBusIndex: number, secondBusIndex: number) {
    let temp = this.elementFocused.Buses[firstBusIndex];
    this.elementFocused.Buses[firstBusIndex] = this.elementFocused.Buses[secondBusIndex];
    this.elementFocused.Buses[secondBusIndex] = temp;
  }
  
  ExpandDraggableWindow() {
    const dwElement = document.querySelector('app-process-properties').parentElement;
    const draggableWindowHeight = +dwElement.style.height.replace('px', '');
    this.draggableWindowHeight = draggableWindowHeight;
    
    dwElement.style.height = draggableWindowHeight + this.draggableWindowExpansion + 'px';
  }
  
  RevertExpandDraggableWindow() {
    
    const processProperties = document.querySelector('app-process-properties');
    
    if (processProperties) {
      const dwElement = document.querySelector('app-process-properties').parentElement;
      dwElement.style.height = this.draggableWindowHeight + 'px';
    }
    
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
