import { Component, OnInit } from '@angular/core';
import { ApiAdminService } from 'app/core/services/api-admin.service';
import { CacheService } from 'app/core/services/cache.service';
import { version } from '../../../environments/version';
import { ClientStorageService } from '../../core/services/client-storage.service';
import { EditorStateService } from '../../core/services/editor-state.service';
import { GenericDialogService } from '../../core/services/generic-dialog.service';
import { ToolsService } from '../../core/services/tools.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { SnackerService } from '../../shared/services/snacker.service';
import { SystemService } from '../../shared/services/system.service';
import { WorkAreaService } from '../workarea.service';

@Component({
  selector: 'app-preferences-panel',
  templateUrl: './preferences-panel.component.html',
  styleUrls: ['./preferences-panel.component.scss'],
})
export class PreferencesPanelComponent implements OnInit {
  
  rearrangePanelEnabled = false;
  frontEndVersion = '';
  backEndVersion = '';
  systemTheme = '';
  ownerData = null;
  showInitialAssistPage = false;
  systemThemes = [
    {
      name: 'Auto',
      value: 'auto',
    },
    {
      name: 'Light',
      value: 'light',
    },
    {
      name: 'Dark (Beta)',
      value: 'dark',
    }];
  systemUIs = [
    {
      name: 'Classic',
      value: 'classic-ui',
    },
    {
      name: 'Modern',
      value: 'modern-ui',
    }];
  
  constructor(
    private cacheService: CacheService,
    private snackerService: SnackerService,
    private adminService: ApiAdminService,
    public systemService: SystemService,
    private toolsService: ToolsService,
    private dialogService: GenericDialogService,
    private clientStorageService: ClientStorageService,
    public workAreaService: WorkAreaService,
    private communicationService: CommunicationService,
    private editorStateService: EditorStateService,
  ) {
    
    const userPreferences = systemService.GetUserPreferences();
    this.systemTheme = userPreferences.theme ? userPreferences.theme : 'auto';
    this.rearrangePanelEnabled = this.clientStorageService.getHideRearrangeDatasource() === 'Y';
  }
  
  ngOnInit() {
    this.frontEndVersion = `${ version.build }-${ version.date }`;
    this.adminService.getBackendVersion().subscribe(result => {
      this.backEndVersion = result.backendVersion;
    });
    this.ownerData = {
      fullName: this.clientStorageService.getUserFullName(),
      email: this.clientStorageService.getUserName(),
      companyName: this.clientStorageService.getCompanyName(),
      role: this.clientStorageService.getUserRole(),
    };
    this.showInitialAssistPage = this.clientStorageService.getInitialAssistPage() !== 'N';
  }
  
  ClearLocalCache() {
    this.cacheService.Clear(true);
    this.snackerService.ShowMessageOnBottom('Local cache cleared', 'delete_sweep', null, true);
  }
  
  
  ChangeSystemTheme() {
    this.toolsService.SetTheme(this.systemTheme);
    this.systemService.SaveUserPreference('theme', this.systemTheme);
  }
  
  ToggleRearrangePanel(event: any) {
    const condition = event.checked ? 'Y' : 'N';
    // set flag on backend
    this.clientStorageService.setHideRearrangeDatasource(condition);
  }
  
  ToggleHideUnusedDataSources(event: any) {
    // set flag on backend
    this.workAreaService.hideUnusedDataSources = event.checked;
    this.DataSourceHideOptionChange();
  }
  
  ToggleHideNotOwnedDataSources(event: any) {
    // set flag on backend
    this.workAreaService.hideNotOwnedDataSources = event.checked;
    this.DataSourceHideOptionChange();
  }
  
  ToggleHideInitialAssistPage(event: any) {
    // set flag on backend
    this.clientStorageService.setInitialAssistPage(event.checked ? 'N' : 'Y');
  }
  
  DataSourceHideOptionChange() {
    this.communicationService.Event.Editor.DataSource.$DataSourceVisibilityChanged.emit();
    setTimeout(() => {
      this.communicationService.Event.Editor.DataSource.$RefreshDataSourcePanel.emit();
    }, 100);
    console.log('editor');
    
    this.editorStateService.SaveEditorState();
  }
  
  ChangeSystemUI() {
    this.systemUIs.map(ui => ui.value).forEach(ui => {
      document.body.classList.remove(ui);
    });
    
    document.body.classList.add(this.systemService.UIStyle);
  }
  
  ReadAgreements() {
    this.dialogService.OpenConfirmDialog({
      title: 'Terms of Service',
      message: this.systemService.termsOfService,
      confirmText: 'Ok',
      cancelText: '',
    });
  }
}
