import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CobbleUser } from '../../shared/models/cobble-user.model';
import { LicenseRole } from '../../shared/models/licenserole.model';
import { ErrorMessengerService } from '../../shared/services/error-messenger.service';
import { ApiDataService } from './api-data.service';
import { ClientStorageService } from './client-storage.service';
import { ToolsService } from './tools.service';

@Injectable({
  providedIn: 'root',
})
export class ApiAdminService extends ApiDataService {
  private usersSource = new BehaviorSubject<CobbleUser[]>([]);
  users$ = this.usersSource.asObservable();
  
  constructor(http: HttpClient, errorMessengerService: ErrorMessengerService, private toolsService: ToolsService, private clientStorageService: ClientStorageService, private router: Router) {
    super('admin', http, errorMessengerService);
  }
  
  AuthenticationSettings() {
    return this.http.get(this.apiEndpointUrl + `/AuthenticationSettings`).pipe(
      map(response => <{
        separateLogin: boolean,
        types: {
          type: string,
          priority: number,
        }[],
      }>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, 'Error getting system Authentication info'),
      ),
    );
  }
  
  authenticationTypes() {
    return this.http.get(this.apiEndpointUrl + `/AuthenticationTypes`).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, 'Error getting system Authentication Types'),
      ),
    );
  }
  
  GetSystemSetting(name: string) {
    return this.http.get(this.apiEndpointUrl + `/SystemSetting/` + name, { responseType: 'text' }).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, 'Error getting system setting'),
      ),
    );
  }
  
  GetSystemValue(key: string) {
    return this.http.get(this.apiEndpointUrl + `/systemvalue/` + key).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error getting ${ key }`, key)),
    );
  }
  
  GetAntiforgery() {
    this.http.get(this.apiEndpointUrl + `/Antiforgery`, {
      responseType: 'text',
    }).pipe(
      map(response => <any>response),
      map((response) => {
        // disable antiforgery save on local storage
        // this.clientStorageService.setAntiforgeryToken(response);
      }),
      catchError(error => this.errorMessengerService.HandleError(error, `Error getting Antiforgery token`)),
    ).subscribe();
  }
  
  GetError(status: number) {
    return this.http.get(this.apiEndpointUrl + `/Status/` + status).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error)),
    );
  }
  
  setUsersList(users: CobbleUser[]) {
    this.usersSource.next(users);
  }
  
  getUsersList() {
    return this.usersSource.value;
  }
  
  addHierarchyNodeToUser(userId: number, nodeId: number) {
    return this.http
    .post(this.apiEndpointUrl + `/addHierarchyNodeToUser`, {
      userId: userId,
      nodeId: nodeId,
    })
    .pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error adding hierarchy node to user ${ userId }`,
          {
            userId: userId,
            nodeId: nodeId,
          },
        ),
      ),
    );
  }
  
  ApplicationClosed() {
    return this.http
    .post(this.apiEndpointUrl + `/ApplicationClosed`, {})
    .pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error application closed`,
        ),
      ),
    );
  }
  
  KeepAlive() {
    return this.http
    .post(this.apiEndpointUrl + `/alive`, {})
    .pipe(
      map(response => <any>response),
      // catchError(error =>
      //   this.errorMessengerService.HandleError(
      //     error,
      //     `Error alive with server`
      //   )
      // )
    );
  }
  
  getRoles(): Observable<LicenseRole[]> {
    return this.http.get(this.apiEndpointUrl + `/role/list`).pipe(
      map(response => <LicenseRole[]>response),
      catchError(error => this.errorMessengerService.HandleError(error)),
    );
  }
  
  getBackendVersion() {
    return this.http.get(this.apiEndpointUrl + `/version`).pipe(
      map(response => <any>response),
      catchError(error => {
        if (error.status === 401 && !window.location.href.includes('register')) {
          (window.location as any) = `${ this.toolsService.GetDomain() }/register`;
        }
        return this.errorMessengerService.HandleError(error, 'Error getting backend version');
      }),
    );
  }
  
  removeCobbleUser(user: CobbleUser): any {
    return this.http
    .delete(`${ this.apiEndpointUrl }/user/${ user.id }/remove/`)
    .pipe(
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error removing user ${ user.firstName } ${ user.lastName } - ${ user.id }`,
          user,
        ),
      ),
    );
  }
  
  getRolesAvailables(companyId: number): Observable<any> {
    return this.http.get(this.apiEndpointUrl + `/${ companyId }/role/list`).pipe(
      map(response => this.parseCompanyRoles(<any>response)),
      catchError(error =>
        this.errorMessengerService.HandleError(error, 'Error getting list of Roles', companyId),
      ),
    );
  }
  
  getUsersForCompany(companyId: number): Observable<CobbleUser[]> {
    return this.http.get(`${ this.apiEndpointUrl }/${ companyId }/user/list`).pipe(
      map(response => this.parseCobbleUsers(<any>response)),
      catchError(error =>
        this.errorMessengerService.HandleError(
          error,
          `Error getting users for company ${ companyId }`,
          companyId,
        ),
      ),
    );
  }
  
  getCompanies() {
    return this.http.get(this.apiEndpointUrl + `/company/list`).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, `Error getting list of companies`),
      ),
    );
  }
  
  createLicensedUser(licensedUser: any) {
    return this.http.post(`${ this.apiEndpointUrl }/license/create`, licensedUser).pipe(
      map(response => <any>response),
      catchError(error =>
        this.errorMessengerService.HandleError(error, `Error creating license for user`),
      ),
    );
  }
  
  updateLicense(license: any) {
    return this.http.put(`${ this.apiEndpointUrl }/license/update`, license).pipe(
      map(response => <any>response),
      catchError(error => this.errorMessengerService.HandleError(error, `Error updating license`)),
    );
  }
  
  private parseCompanyRoles(roles: any) {
    const parsedRole = { availableRoles: [], licenseRoles: [] };
    parsedRole.licenseRoles = this.parseRoleArray(roles.licenseRoles);
    parsedRole.availableRoles = this.parseRoleArray(roles.availableRoles);
    return parsedRole;
  }
  
  private parseRoleArray(licenses: any[]): LicenseRole[] {
    const newLicenses = [];
    
    licenses.forEach(element => {
      newLicenses.push(this.parseRoleObject(element));
    });
    
    return newLicenses;
  }
  
  private parseRoleObject(license: any) {
    const newLicense: LicenseRole = new LicenseRole();
    Object.assign(newLicense, license);
    newLicense.list = [];
    
    license.list.forEach(element => {
      newLicense.list.push(element);
    });
    
    return newLicense;
  }
  
  private parseCobbleUsers(users: any[]): CobbleUser[] {
    const newUsers = [];
    
    users.forEach(element => {
      newUsers.push(this.parseUserCobbletObject(element));
    });
    
    return newUsers;
  }
  
  private parseUserCobbletObject(user: any) {
    const newUser: CobbleUser = new CobbleUser();
    Object.assign(newUser, user);
    return newUser;
  }
}
