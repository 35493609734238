import { ParticleType } from '../../enums/particle-type.enum';
import { Particle } from './particle';

export class LeapXLEvent extends Particle {
  ParticleType = ParticleType.Event;
  SourceId: any;
  EventName: string;
  EventSource: 'Molecule' | 'System' | 'View' | 'Custom';
  Enable = true;
  EventType: string;
  Data: any;
  Identifier: string;
  TriggeredByUser: boolean;
  Color? = 'transparent';
  
  constructor(event: any = {}) {
    super(event.particleId || event.ParticleId || '');
    this.AssingId(event.id || event.Id);
    
    this.EventName = event.eventName || event.EventName || '';
    this.EventSource = event.eventSource || event.EventSource || 'Molecule';
    this.SourceId = event.sourceId || event.SourceId || '';
    this.EventType = event.eventType || event.EventType || '';
    this.Touched = event.touched || event.Touched || '';
    this.TriggeredByUser = event.TriggeredByUser || event.triggeredByUser || false;
    this.TriggeredByBusId = event.triggeredByBusId || event.TriggeredByBusId || '';
    this.Data = event.data || event.Data || null;
    this.Priority = event.priority || event.Priority || 0;
    this.AvoidRun = event.avoidRun || event.AvoidRun || false;
    this.SetIdentifier();
  }
  
  SetIdentifier() {
    this.Identifier = this.EventName.replace(/\s/g, '')
      .replace(/`/g, '')
      .replace(/'/g, '')
      .replace(/&/g, '')
      + '-' + this.SourceId;
    this.Color = this.toolsService.GetRandomColorFromString(this.Identifier);
  }
  
  GlobalIdentifier() {
    return `${ this.EventSource.replace(/\s/g, '').replace(/&/g, '') }-${ this.EventType.replace(/\s/g, '').replace(/&/g, '') }-${ this.SourceId }`.toLowerCase();
  }
  
  public Clone() {
    return new LeapXLEvent({
      EventName: this.EventName,
      EventSource: this.EventSource,
      SourceId: this.SourceId,
      Identifier: this.Identifier,
      EventType: this.EventType,
      Priority: this.Priority,
      TriggeredByBusId: this.TriggeredByBusId,
      Data: this.cloneDeep(this.Data),
      ParticleId: this.ParticleId,
      id: this.id,
    });
  }
  
  public CloneAndAssignIds(): LeapXLEvent {
    const newEvent = this.Clone();
    newEvent.AssingId();
    newEvent.AssingParticleId();
    return newEvent;
  }
  
  GetName(): string {
    return `${ this.EventSource } - ${ this.EventName }`;
  }
  
  GetDisplayValues(): string {
    return this.EventName;
  }
  
  IsSameEvent(event: LeapXLEvent): boolean {
    return event.EventSource === this.EventSource && event.EventType === this.EventType && event.SourceId.toString() === this.SourceId.toString();
  }
  
  SetNewSourceId(sourceId: number) {
    this.SourceId = sourceId;
    this.SetIdentifier();
  }
  
  GetRawObject(): any {
    
    const object = {
      id: this.id,
      ParticleType: this.ParticleType,
      ParticleId: this.ParticleId,
      SourceId: this.SourceId,
      EventName: this.EventName,
      Priority: this.Priority,
      EventSource: this.EventSource,
      TriggeredByBusId: this.TriggeredByBusId,
      EventType: this.EventType,
      Data: this.Data,
      Touched: this.Touched,
    };
    
    return object;
  }
}
