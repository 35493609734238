import { Constants } from '../../../shared/constants';
import { IRepresentativeMolecule } from '../../../shared/representative-molecule/interfaces/representative-molecule.interface';
import { ActionMoleculeFunction } from '../base-molecules/action-molecule-function';

export class AppendMolecule extends ActionMoleculeFunction {
  public MoleculeProcess(
    particleId: string,
    repMoleculeId: string,
    busProcessorKey: string,
    rule: any,
    logic: any,
    dataBus: any,
    dataElementsData: any,
  ) {
    
    try {
      
      let append = '';
      
      if (dataElementsData) {
        append = this.toolsService.ExtractValuesByType(dataElementsData).string || Constants.Defaults.DataItemValue;
      } else {
        if (typeof rule === 'undefined') {
          append = Constants.Defaults.DataItemValue;
        } else {
          append = rule.toappend || Constants.Defaults.DataItemValue;
        }
      }
      
      dataBus = this.DataActionModifierApply(dataBus, this.Modifier, append);
      
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    } catch (error) {
      // console.log(error);
      this.Done(particleId, busProcessorKey, repMoleculeId, dataBus);
    }
  }
  
  public AfterRemove(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  public AfterAdded(repMolecule: IRepresentativeMolecule, data?: any) {
  }
  
  private Modifier(value: string, append: string): string {
    if (value.toString().endsWith(append)) {
      value = value.slice(0, -append.length);
    }
    return value + append;
  }
}
