import { ApiRegisterComponent } from '../admin/api-register/api-register.component';
import { CobbleDetailsComponent } from '../dashboard/cobble-details/cobble-details.component';
import { EventsTransactionComponent } from '../events-transaction/events-transaction.component';
import { MonitoringComponent } from '../monitoring/monitoring.component';
import { RequestsLogComponent } from '../requests-log/requests-log.component';
import { TestComponent } from '../test/test.component';
import { WebEditorComponent } from '../web-editor/web-editor.component';
import { ApiSetupComponent } from '../workarea/api-setup/api-setup.component';
import {
  AppThemeConfigurationComponent,
} from '../workarea/app-theme-configuration/app-theme-configuration.component';
import { BusLoopComponent } from '../workarea/bus-loop/bus-loop.component';
import { BusFrameComponent } from '../workarea/bus/bus-frame/bus-frame.component';
import { BusComponent } from '../workarea/bus/bus.component';
import { CloneApplicationComponent } from '../workarea/clone-application/clone-application.component';
import { CobblePropertiesComponent } from '../workarea/cobble-properties/cobble-properties.component';
import { CreateCobbletComponent } from '../workarea/create-cobblet/create-cobblet.component';
import { CreateCompoundComponent } from '../workarea/create-compound/create-compound.component';
import { CreateStyleComponent } from '../workarea/create-style/create-style.component';
import { CustomControlHelpComponent } from '../workarea/custom-control-help/custom-control-help.component';
import { DataAuditComponent } from '../workarea/data-quality/data-audit/data-audit.component';
import { DataDrillDownComponent } from '../workarea/data-quality/data-drill-down/data-drill-down.component';
import { DataProvenanceComponent } from '../workarea/data-quality/data-provenance/data-provenance.component';
import {
  DatasourceConnectedComponent,
} from '../workarea/datasource-connected/datasource-connected.component';
import {
  DatasourceRenameSheetComponent,
} from '../workarea/datasource-rename-sheet/datasource-rename-sheet.component';
import { DatasourceRenameComponent } from '../workarea/datasource-rename/datasource-rename.component';
import { DevToolsDataComponent } from '../workarea/dev-tools/dev-tools-data/dev-tools-data.component';
import {
  DevToolsToolbarComponent,
} from '../workarea/dev-tools/dev-tools-toolbar/dev-tools-toolbar.component';
import { DocumentationComponent } from '../workarea/documentation/documentation.component';
import { EventSourceComponent } from '../workarea/event-source/event-source.component';
import { GenerationAppComponent } from '../workarea/generation-app/generation-app.component';
import { InitialAssistComponent } from '../workarea/initial-assist/initial-assist.component';
import { LeapxlExportComponent } from '../workarea/leapxl-export/leapxl-export.component';
import { LeapxlImportComponent } from '../workarea/leapxl-import/leapxl-import.component';
import { PreferencesPanelComponent } from '../workarea/preferences-panel/preferences-panel.component';
import {
  PreviewRepresentativeMoleculeComponent,
} from '../workarea/preview-representative-molecule/preview-representative-molecule.component';
import { ProcessComponent } from '../workarea/process/process.component';
import {
  RepresentativePropertiesComponent,
} from '../workarea/representative-properties/representative-properties.component';
import {
  SmartTemplateWizardComponent,
} from '../workarea/smart-template-wizard/smart-template-wizard.component';
import { SnapshotsComponent } from '../workarea/snapshots/snapshots.component';
import { StylePropertiesComponent } from '../workarea/style-properties/style-properties.component';
import { TextComponent } from '../workarea/text-component/text-component.component';
import { UnifiedDbSetupComponent } from '../workarea/unified-db-setup/unified-db-setup.component';
import { HierarchySharedComponent } from './components/hierarchy-shared/hierarchy-shared.component';

export const Constants = {
  MimeTypes: {
    Image: ['image/jpeg', 'image/gif', 'image/png'],
    PDF: ['application/pdf'],
  },
  SessionId: 'te4bp7gta3qr',
  ContextSeparator: '::',
  SidePanelSectionHeaderHeight: 24,
  SeparatorSectionHeaderHeight: 5,
  ToolbarHeight: 40,
  LocalStoragePrefix: 'leap-',
  Defaults: {
    DataBus: {},
    IgnoreValueDataIndex: false,
    InitPageNumber: 1,
    Offset: 0,
    SearchFilter: '',
    OrientationOperation: 'col',
    DataItemValue: '',
    View: 1,
    TranslationId: 0,
    DataSourceId: 0,
    InternalName: '',
    MaxStringLength: 99999999,
    ColumnWidth: 120,
    Timeouts: {},
    DraggableWindow: {
      Title: '',
      Data: {},
    },
    DraggableWindowSizes: {
      PreviewRepresentativeMoleculeComponent: {
        name: 'PreviewRepresentativeMoleculeComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 500,
        height: 400,
        icon: 'preview',
        component: PreviewRepresentativeMoleculeComponent,
      },
      DevToolsToolbarComponent: {
        name: 'DevToolsToolbarComponent',
        changeLayout: true,
        x: 500,
        y: 50,
        width: 500,
        height: 400,
        icon: 'menu_open',
        component: DevToolsToolbarComponent,
      },
      DevTools: {
        name: 'BusComponent',
        changeLayout: true,
        x: 500,
        y: 50,
        width: 800,
        height: 700,
        icon: 'menu_open',
        component: BusComponent,
      },
      DevToolsBreakpointData: {
        name: 'DevToolsDataComponent',
        changeLayout: true,
        x: 500,
        y: 50,
        center: true,
        width: 400,
        height: 600,
        icon: 'menu_open',
        component: DevToolsDataComponent,
      },
      AppThemeConfigurationComponent: {
        name: 'AppThemeConfigurationComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 600,
        height: 600,
        resizable: false,
        icon: 'style',
        component: AppThemeConfigurationComponent,
      },
      Microlearning: {
        name: 'DocumentationComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 800,
        height: 600,
        icon: 'help_outline',
        component: DocumentationComponent,
      },
      InitialAssist: {
        name: 'InitialAssistComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 600,
        height: 385,
        center: true,
        resizable: false,
        icon: 'flag',
        component: InitialAssistComponent,
      },
      TextComponent: {
        name: 'TextComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 800,
        height: 600,
        icon: 'info',
        component: TextComponent,
      },
      CustomControlEditor: {
        name: 'WebEditorComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 1200,
        height: 800,
        center: true,
        icon: 'draw',
        component: WebEditorComponent,
      },
      CustomControlHelp: {
        name: 'CustomControlHelp',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 700,
        height: 800,
        center: true,
        icon: 'draw',
        component: CustomControlHelpComponent,
      },
      AutoGenerationApp: {
        name: 'AutoGenerationApp',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 1000,
        height: 800,
        center: true,
        icon: 'sound_sampler',
        component: GenerationAppComponent,
      },
      HierarchyShared: {
        name: 'HierarchySharedComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 450,
        height: 600,
        icon: 'share',
        component: HierarchySharedComponent,
      },
      AppProperties: {
        name: 'CobblePropertiesComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 700,
        height: 665,
        center: true,
        icon: 'settings_applications',
        component: CobblePropertiesComponent,
      },
      DevConsole: {
        name: 'MonitoringComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 800,
        height: 600,
        icon: 'help_outline',
        component: MonitoringComponent,
      },
      ApiRegister: {
        name: 'ApiRegisterComponent',
        changeLayout: false,
        x: 400,
        y: 70,
        width: 800,
        height: 700,
        icon: 'vpn_key',
        component: ApiRegisterComponent,
      },
      LeapxlImport: {
        name: 'LeapxlImportComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 600,
        height: null,
        icon: 'publish',
        center: true,
        component: LeapxlImportComponent,
      },
      CloneApplicationComponent: {
        name: 'CloneApplicationComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 600,
        height: null,
        center: true,
        autoHeight: true,
        icon: 'file_copy',
        component: CloneApplicationComponent,
      },
      LeapxlExport: {
        name: 'LeapxlExportComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 600,
        height: null,
        icon: 'get_app',
        center: true,
        component: LeapxlExportComponent,
      },
      Snapshots: {
        name: 'SnapshotsComponent',
        changeLayout: false,
        x: 600,
        y: 100,
        width: 600,
        height: 500,
        icon: 'cloud_circle',
        resizable: false,
        component: SnapshotsComponent,
      },
      BugReport: {
        name: 'DocumentationComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 650,
        height: 600,
        icon: 'bug_report',
        component: DocumentationComponent,
      },
      AppDetails: {
        name: 'CobbleDetailsComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 320,
        height: 190,
        icon: 'settings_applications',
        component: CobbleDetailsComponent,
      },
      DataAudit: {
        name: 'DataAuditComponent',
        changeLayout: false,
        x: 50,
        y: 50,
        width: 700,
        center: true,
        height: null,
        icon: 'find_in_page',
        component: DataAuditComponent,
      },
      DataProvenance: {
        name: 'DataProvenanceComponent',
        changeLayout: false,
        x: 50,
        y: 50,
        width: 500,
        center: true,
        height: null,
        icon: 'find_in_page',
        component: DataProvenanceComponent,
      },
      DataDrillDown: {
        name: 'DataDrillDownComponent',
        changeLayout: false,
        x: 50,
        y: 50,
        width: 700,
        center: true,
        height: 600,
        icon: 'data_exploration',
        component: DataDrillDownComponent,
      },
      ProcessPanel: {
        name: 'ProcessComponent',
        changeLayout: true,
        x: 500,
        y: 50,
        width: 400,
        height: null,
        icon: 'view_day',
        component: ProcessComponent,
      },
      Loop: {
        name: 'BusLoopComponent',
        changeLayout: false,
        x: 500,
        center: true,
        y: 50,
        width: 475,
        height: 475,
        icon: 'cycle',
        component: BusLoopComponent,
      },
      Welcome: {
        name: 'DocumentationComponent',
        changeLayout: false,
        x: 250,
        y: 80,
        width: 1100,
        height: 700,
        icon: 'home',
        component: DocumentationComponent,
      },
      RenameDatasource: {
        name: 'DatasourceRenameComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 220,
        height: 140,
        icon: 'drive_file_rename_outline',
        component: DatasourceRenameComponent,
      },
      RenameSheet: {
        name: 'DatasourceRenameSheetComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 220,
        height: 140,
        icon: 'drive_file_rename_outline',
        component: DatasourceRenameSheetComponent,
      },
      ApiSetup: {
        name: 'ApiSetupComponent',
        changeLayout: false,
        x: 350,
        y: 150,
        width: 360,
        height: 380,
        icon: 'add',
        component: ApiSetupComponent,
      },
      PreferencesPanel: {
        name: 'PreferencesPanelComponent',
        changeLayout: false,
        x: 650,
        y: 150,
        width: 360,
        height: 700,
        icon: 'settings_suggest',
        component: PreferencesPanelComponent,
      },
      UnifiedDbConnection: {
        name: 'UnifiedDbSetupComponent',
        changeLayout: false,
        x: 350,
        y: 150,
        width: 360,
        height: 340,
        icon: 'storage',
        component: UnifiedDbSetupComponent,
      },
      ApiDocumentation: {
        name: 'TestComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 800,
        height: 600,
        icon: 'help_center',
        component: TestComponent,
      },
      UnsavedData: {
        name: 'TestComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 800,
        height: 600,
        icon: 'help_center',
        center: true,
        component: TestComponent,
      },
      RepresentativeProperties: {
        name: 'RepresentativePropertiesComponent',
        changeLayout: false,
        x: 500,
        y: 60,
        width: 420,
        height: 600,
        icon: 'settings',
        component: RepresentativePropertiesComponent,
      },
      StyleProperties: {
        name: 'StylePropertiesComponent',
        changeLayout: false,
        x: 500,
        y: 60,
        width: 400,
        height: 600,
        icon: 'draw',
        component: StylePropertiesComponent,
      },
      CreateComponent: {
        name: 'CreateCobbletComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 320,
        height: 171,
        icon: 'add',
        component: CreateCobbletComponent,
      },
      CreateStyle: {
        name: 'CreateStyleComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 320,
        height: 171,
        icon: 'brush',
        component: CreateStyleComponent,
      },
      SeparateBus: {
        name: 'BusFrameComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 400,
        height: null,
        icon: 'launch',
        component: BusFrameComponent,
      },
      CreateCompound: {
        name: 'CreateCompoundComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: null,
        height: null,
        icon: 'add',
        component: CreateCompoundComponent,
      },
      LibraryMoleculeDocumentation: {
        name: 'DocumentationComponent',
        changeLayout: false,
        x: 500,
        y: 50,
        width: 800,
        height: 600,
        icon: 'help_center',
        component: DocumentationComponent,
      },
      EventSource: {
        name: 'EventSourceComponent',
        changeLayout: false,
        x: 100,
        y: 500,
        width: 300,
        height: 300,
        icon: 'insights',
        component: EventSourceComponent,
      },
      DatasourceConnected: {
        name: 'DatasourceConnectedComponent',
        changeLayout: false,
        x: 100,
        y: 350,
        width: 500,
        height: 300,
        icon: 'leak_add',
        component: DatasourceConnectedComponent,
      },
      TemplateWizard: {
        name: 'SmartTemplateWizardComponent',
        changeLayout: false,
        x: 200,
        y: 100,
        width: 1200,
        height: 700,
        center: false,
        icon: 'auto_activity_zone',
        component: SmartTemplateWizardComponent,
      },
      EventsTransaction: {
        name: 'EventsTransactionComponent',
        microlearning: {
          title: `Events Process Flow Microlearning`,
          id: 'Design_Process_Flow_GUI',
          type: 'Guide',
        },
        changeLayout: false,
        x: 100,
        y: 100,
        width: 700,
        height: 700,
        icon: 'insights',
        component: EventsTransactionComponent,
      },
      RequestsLog: {
        name: 'RequestsLogComponent',
        changeLayout: false,
        x: 100,
        y: 100,
        width: 700,
        height: 700,
        icon: 'import_export',
        component: RequestsLogComponent,
      },
    },
  },
  ContextInternalNameDefaults: {
    'InternetMessaging::Sms::To': 'Phone Number',
    'InternetMessaging::Sms::Cc': 'Phone Number (2)',
    'InternetMessaging::Sms::Bcc': 'Phone Number (3)',
  },
  IconsLibrary: [
    'search',
    'home',
    'menu',
    'close',
    'settings',
    'done',
    'expand_more',
    'check_circle',
    'favorite',
    'add',
    'delete',
    'arrow_back',
    'star',
    'chevron_right',
    'logout',
    'arrow_forward_ios',
    'add_circle',
    'cancel',
    'arrow_back_ios',
    'arrow_forward',
    'arrow_drop_down',
    'more_vert',
    'check',
    'check_box',
    'toggle_on',
    'grade',
    'open_in_new',
    'check_box_outline_blank',
    'refresh',
    'login',
    'chevron_left',
    'expand_less',
    'radio_button_unchecked',
    'more_horiz',
    'apps',
    'arrow_right_alt',
    'download',
    'radio_button_checked',
    'remove',
    'bolt',
    'toggle_off',
    'arrow_upward',
    'filter_list',
    'delete_forever',
    'autorenew',
    'key',
    'arrow_downward',
    'sync',
    'sort',
    'block',
    'add_box',
    'arrow_back_ios_new',
    'restart_alt',
    'shopping_cart_checkout',
    'menu_open',
    'expand_circle_down',
    'backspace',
    'undo',
    'arrow_circle_right',
    'done_all',
    'arrow_right',
    'do_not_disturb_on',
    'open_in_full',
    'manage_search',
    'double_arrow',
    'sync_alt',
    'zoom_in',
    'done_outline',
    'drag_indicator',
    'fullscreen',
    'keyboard_double_arrow_right',
    'star_half',
    'settings_accessibility',
    'ios_share',
    'arrow_drop_up',
    'reply',
    'exit_to_app',
    'unfold_more',
    'library_add',
    'cached',
    'select_check_box',
    'terminal',
    'change_circle',
    'disabled_by_default',
    'swap_horiz',
    'swap_vert',
    'close_fullscreen',
    'app_registration',
    'download_for_offline',
    'arrow_circle_left',
    'arrow_circle_up',
    'file_open',
    'minimize',
    'open_with',
    'dataset',
    'keyboard_double_arrow_left',
    'add_task',
    'start',
    'keyboard_double_arrow_down',
    'keyboard_voice',
    'create_new_folder',
    'forward',
    'downloading',
    'settings_applications',
    'compare_arrows',
    'redo',
    'publish',
    'zoom_out',
    'arrow_left',
    'html',
    'token',
    'switch_access_shortcut',
    'arrow_circle_down',
    'fullscreen_exit',
    'sort_by_alpha',
    'delete_sweep',
    'indeterminate_check_box',
    'first_page',
    'keyboard_double_arrow_up',
    'view_timeline',
    'settings_backup_restore',
    'arrow_drop_down_circle',
    'assistant_navigation',
    'sync_problem',
    'clear_all',
    'density_medium',
    'heart_plus',
    'filter_alt_off',
    'expand',
    'last_page',
    'subdirectory_arrow_right',
    'unfold_less',
    'arrow_outward',
    'download_done',
    '123',
    'swipe_left',
    'saved_search',
    'system_update_alt',
    'place_item',
    'maximize',
    'javascript',
    'search_off',
    'output',
    'swipe_up',
    'fit_screen',
    'select_all',
    'dynamic_form',
    'hide_source',
    'swipe_right',
    'switch_access_shortcut_add',
    'browse_gallery',
    'check_small',
    'density_small',
    'css',
    'assistant_direction',
    'youtube_searched_for',
    'move_up',
    'file_download_done',
    'swap_horizontal_circle',
    'data_thresholding',
    'install_mobile',
    'abc',
    'dataset_linked',
    'restore_from_trash',
    'move_down',
    'enable',
    'install_desktop',
    'keyboard_command_key',
    'view_kanban',
    'browse_activity',
    'reply_all',
    'switch_left',
    'compress',
    'swipe_down',
    'swap_vertical_circle',
    'remove_done',
    'apps_outage',
    'filter_list_off',
    'star_rate',
    'hide',
    'switch_right',
    'swipe_vertical',
    'more_up',
    'sync_disabled',
    'pinch',
    'keyboard_control_key',
    'eject',
    'key_off',
    'php',
    'subdirectory_arrow_left',
    'view_cozy',
    'transcribe',
    'do_not_disturb_off',
    'send_time_extension',
    'width_normal',
    'heart_minus',
    'view_comfy_alt',
    'share_reviews',
    'width_full',
    'unfold_more_double',
    'file_download_off',
    'view_compact_alt',
    'extension_off',
    'open_in_new_off',
    'check_indeterminate_small',
    'more_down',
    'width_wide',
    'repartition',
    'density_large',
    'swipe_left_alt',
    'swipe_down_alt',
    'swipe_right_alt',
    'swipe_up_alt',
    'unfold_less_double',
    'keyboard_option_key',
    'cycle',
    'progress_activity',
    'hls',
    'hls_off',
    'file_upload_off',
    'rebase',
    'expand_content',
    'expand_all',
    'collapse_all',
    'rebase_edit',
    'empty_dashboard',
    'arrow_split',
    'quick_reference_all',
    'arrow_upward_alt',
    'switches',
    'deployed_code',
    'directory_sync',
    'arrow_downward_alt',
    'left_click',
    'data_check',
    'stack',
    'acute',
    'quick_reference',
    'cards',
    'side_navigation',
    'sync_saved_locally',
    'bubble',
    'data_alert',
    'page_info',
    'steppers',
    'arrow_left_alt',
    'clock_loader_60',
    'move_item',
    'stat_3',
    'question_exchange',
    'search_check',
    'preliminary',
    'captive_portal',
    'data_info_alert',
    'point_scan',
    'star_rate_half',
    'clock_loader_10',
    'toolbar',
    'sweep',
    'new_window',
    'right_panel_close',
    'right_panel_open',
    'page_control',
    'arrow_insert',
    'patient_list',
    'unknown_med',
    'stack_star',
    'clock_loader_40',
    'stat_2',
    'stat_minus_1',
    'dialogs',
    'stat_minus_2',
    'drag_pan',
    'step',
    'capture',
    'heart_check',
    'rule_settings',
    'stat_1',
    'step_into',
    'left_panel_close',
    'left_panel_open',
    'magnification_large',
    'magnification_small',
    'partner_reports',
    'expand_circle_up',
    'dropdown',
    'move_group',
    'all_match',
    'clock_loader_90',
    'resize',
    'sliders',
    'clock_loader_80',
    'input_circle',
    'bottom_navigation',
    'bottom_panel_open',
    'expand_circle_right',
    'buttons_alt',
    'clock_loader_20',
    'stack_off',
    'stat_minus_3',
    'step_over',
    'bottom_sheets',
    'bottom_drawer',
    'chip_extraction',
    'chronic',
    'pip',
    'shelf_position',
    'tabs',
    'drag_click',
    'toast',
    'unknown_5',
    'chips',
    'iframe',
    'move_selection_left',
    'reopen_window',
    'right_click',
    'ripples',
    'share_windows',
    'arrow_range',
    'bottom_right_click',
    'deployed_code_update',
    'go_to_line',
    'step_out',
    'subheader',
    'app_badging',
    'arrow_and_edge',
    'event_list',
    'switch_access',
    'arrow_or_edge',
    'back_to_tab',
    'error_med',
    'move_selection_right',
    'output_circle',
    'pip_exit',
    'amend',
    'deployed_code_alert',
    'move_selection_up',
    'position_bottom_right',
    'position_top_right',
    'arrow_top_right',
    'bottom_app_bar',
    'arrow_top_left',
    'arrows_outward',
    'bottom_panel_close',
    'bubbles',
    'deployed_code_history',
    'iframe_off',
    'shelf_auto_hide',
    'move_selection_down',
    'open_in_new_down',
    'position_bottom_left',
    'jump_to_element',
    'radio_button_partial',
    'person',
    'person',
    'group',
    'share',
    'thumb_up',
    'groups',
    'person_add',
    'public',
    'handshake',
    'support_agent',
    'face',
    'sentiment_satisfied',
    'rocket_launch',
    'group_add',
    'workspace_premium',
    'psychology',
    'diversity_3',
    'emoji_objects',
    'water_drop',
    'eco',
    'pets',
    'travel_explore',
    'mood',
    'sunny',
    'quiz',
    'health_and_safety',
    'sentiment_dissatisfied',
    'sentiment_very_satisfied',
    'military_tech',
    'thumb_down',
    'gavel',
    'recycling',
    'diamond',
    'monitor_heart',
    'emoji_people',
    'diversity_1',
    'workspaces',
    'vaccines',
    'compost',
    'forest',
    'recommend',
    'waving_hand',
    'person_remove',
    'wc',
    'medication',
    'sentiment_neutral',
    'group_work',
    'diversity_2',
    'sentiment_very_dissatisfied',
    'front_hand',
    'psychology_alt',
    'cruelty_free',
    'man',
    'medical_information',
    'coronavirus',
    'add_reaction',
    'rocket',
    'female',
    'potted_plant',
    'emoji_nature',
    'rainy',
    'person_off',
    'cookie',
    'connect_without_contact',
    'woman',
    'mood_bad',
    'male',
    'bedtime',
    'communication',
    'groups_2',
    'solar_power',
    'thunderstorm',
    'partly_cloudy_day',
    'thumbs_up_down',
    'emoji_flags',
    'masks',
    'hive',
    'heart_broken',
    'sentiment_extremely_dissatisfied',
    'clear_day',
    'boy',
    'whatshot',
    'cloudy_snowing',
    'emoji_food_beverage',
    'wind_power',
    'emoji_transportation',
    'elderly',
    'face_6',
    'reduce_capacity',
    'sick',
    'face_3',
    'pregnant_woman',
    'bloodtype',
    'group_remove',
    'egg',
    'groups_3',
    'medication_liquid',
    'co2',
    'clear_night',
    'skull',
    'weight',
    'follow_the_signs',
    'face_4',
    'emoji_events',
    'elderly_woman',
    'oil_barrel',
    'transgender',
    'sanitizer',
    'clean_hands',
    'person_2',
    'bring_your_own_ip',
    'public_off',
    'face_2',
    'social_distance',
    'routine',
    'sign_language',
    'south_america',
    'sunny_snowing',
    'emoji_symbols',
    'garden_cart',
    'flood',
    'face_5',
    'egg_alt',
    'cyclone',
    'girl',
    'person_4',
    'dentistry',
    'tsunami',
    'group_off',
    'outdoor_garden',
    'partly_cloudy_night',
    'severe_cold',
    'snowing',
    'person_3',
    'tornado',
    'landslide',
    'vaping_rooms',
    'foggy',
    'safety_divider',
    'woman_2',
    'no_adult_content',
    'volcano',
    '6_ft_apart',
    'man_2',
    'blind',
    '18_up_rating',
    'not_accessible',
    'vape_free',
    'man_4',
    'radiology',
    'rib_cage',
    'hand_bones',
    'bedtime_off',
    'rheumatology',
    'man_3',
    'partner_exchange',
    'orthopedics',
    'tibia',
    'skeleton',
    'humerus',
    'foot_bones',
    'agender',
    'femur',
    'tibia_alt',
    'femur_alt',
    'diversity_4',
    'humerus_alt',
    'communities',
    'ulna_radius',
    'ulna_radius_alt',
    'specific_gravity',
    'cognition',
    'psychiatry',
    'eyeglasses',
    'labs',
    'breastfeeding',
    'crowdsource',
    'footprint',
    'neurology',
    'vital_signs',
    'nutrition',
    'social_leaderboard',
    'demography',
    'globe_asia',
    'stethoscope',
    'clinical_notes',
    'conditions',
    'sentiment_excited',
    'lab_research',
    'sentiment_stressed',
    'mystery',
    'taunt',
    'globe_uk',
    'person_raised_hand',
    'pill',
    'sentiment_calm',
    'strategy',
    'crossword',
    'altitude',
    'cardiology',
    'glucose',
    'prescriptions',
    'home_health',
    'sentiment_sad',
    'deceased',
    'recent_patient',
    'weather_hail',
    'chess',
    'falling',
    'sentiment_content',
    'sentiment_frustrated',
    'barefoot',
    'folded_hands',
    'genetics',
    'prayer_times',
    'share_off',
    'mist',
    'mountain_flag',
    'playing_cards',
    'sword_rose',
    'weather_mix',
    'earthquake',
    'globe',
    'helicopter',
    'wrist',
    'dew_point',
    'microbiology',
    'body_system',
    'cheer',
    'ent',
    'metabolism',
    'sentiment_worried',
    'emoticon',
    'explosion',
    'oral_disease',
    'pulmonology',
    'dermatology',
    'oxygen_saturation',
    'stethoscope_check',
    'domino_mask',
    'humidity_percentage',
    'infrared',
    'lab_panel',
    'person_apron',
    'water_lux',
    'allergies',
    'blood_pressure',
    'comic_bubble',
    'cookie_off',
    'stethoscope_arrow',
    'ward',
    'water_bottle',
    'mixture_med',
    'ophthalmology',
    'water_do',
    'water_voc',
    'allergy',
    'body_fat',
    'gastroenterology',
    'gynecology',
    'immunology',
    'manga',
    'oncology',
    'pediatrics',
    'person_check',
    'rainy_snow',
    'short_stay',
    'symptoms',
    'tactic',
    'water_orp',
    'water_ph',
    'endocrinology',
    'hematology',
    'inpatient',
    'moving_beds',
    'nephrology',
    'respiratory_rate',
    'salinity',
    'congenital',
    'destruction',
    'fluid',
    'fluid_balance',
    'fluid_med',
    'medical_mask',
    'outpatient',
    'outpatient_med',
    'person_alert',
    'procedure',
    'snowing_heavy',
    'surgical',
    'syringe',
    'urology',
    'wounds_injuries',
    'admin_meds',
    'bomb',
    'person_cancel',
    'pill_off',
    'pregnancy',
    'rainy_heavy',
    'total_dissolved_solids',
    'water_ec',
    'candle',
    'not_accessible_forward',
    'rainy_light',
    'water_bottle_large',
    'account_circle',
    'info',
    'visibility',
    'calendar_month',
    'schedule',
    'help',
    'language',
    'warning',
    'lock',
    'error',
    'visibility_off',
    'verified',
    'manage_accounts',
    'history',
    'task_alt',
    'event',
    'bookmark',
    'calendar_today',
    'question_mark',
    'lightbulb',
    'fingerprint',
    'category',
    'update',
    'lock_open',
    'priority_high',
    'code',
    'build',
    'date_range',
    'supervisor_account',
    'upload_file',
    'event_available',
    'ads_click',
    'today',
    'touch_app',
    'pending',
    'preview',
    'stars',
    'new_releases',
    'account_box',
    'celebration',
    'how_to_reg',
    'translate',
    'bug_report',
    'push_pin',
    'alarm',
    'edit_calendar',
    'edit_square',
    'label',
    'extension',
    'event_note',
    'record_voice_over',
    'rate_review',
    'web',
    'hourglass_empty',
    'published_with_changes',
    'support',
    'notification_important',
    'upload',
    'help_center',
    'accessibility_new',
    'bookmarks',
    'pan_tool_alt',
    'supervised_user_circle',
    'dangerous',
    'collections_bookmark',
    'interests',
    'all_inclusive',
    'rule',
    'change_history',
    'priority',
    'event_upcoming',
    'build_circle',
    'wysiwyg',
    'api',
    'pan_tool',
    'hotel_class',
    'circle_notifications',
    'manage_history',
    'web_asset',
    'accessible',
    'upgrade',
    'bookmark_add',
    'lock_reset',
    'input',
    'event_busy',
    'more_time',
    'flutter_dash',
    'model_training',
    'save_as',
    'backup',
    'accessibility',
    'alarm_on',
    'dynamic_feed',
    'pageview',
    'home_app_logo',
    'label_important',
    'perm_contact_calendar',
    'history_toggle_off',
    'approval',
    'square_foot',
    'more',
    'swipe',
    'component_exchange',
    'bookmark_added',
    'event_repeat',
    'app_shortcut',
    'unpublished',
    'open_in_browser',
    'notification_add',
    'offline_bolt',
    'no_accounts',
    'free_cancellation',
    'running_with_errors',
    'background_replace',
    'webhook',
    'anchor',
    '3d_rotation',
    'lock_person',
    'new_label',
    'lock_clock',
    'accessible_forward',
    'auto_delete',
    'add_alert',
    'domain_verification',
    'outbound',
    'hand_gesture',
    'tab',
    'chrome_reader_mode',
    'settings_power',
    'online_prediction',
    'gesture',
    'edit_notifications',
    'lightbulb_circle',
    'find_replace',
    'offline_pin',
    'backup_table',
    'wifi_protected_setup',
    'ad_units',
    'http',
    'bookmark_remove',
    'alarm_add',
    'pinch_zoom_out',
    'on_device_training',
    'snooze',
    'batch_prediction',
    'code_off',
    'pinch_zoom_in',
    'commit',
    'hourglass_disabled',
    'settings_overscan',
    'target',
    'polymer',
    'youtube_activity',
    'logo_dev',
    'time_auto',
    'voice_over_off',
    'person_add_disabled',
    'alarm_off',
    'update_disabled',
    'timer_10_alt_1',
    'rounded_corner',
    'label_off',
    'timer_3_alt_1',
    'all_out',
    'tab_unselected',
    'rsvp',
    'web_asset_off',
    'pin_invoke',
    'pin_end',
    'code_blocks',
    'arrow_selector_tool',
    'approval_delegation',
    'developer_guide',
    'feature_search',
    'reminder',
    'lists',
    'problem',
    'browse',
    'visibility_lock',
    'award_star',
    'data_loss_prevention',
    'ad_group',
    'select_window',
    'release_alert',
    'ad',
    'pan_zoom',
    'settings_account_box',
    'lock_open_right',
    'shadow',
    'draft_orders',
    'add_ad',
    'ad_group_off',
    'circles_ext',
    'gesture_select',
    'account_circle_off',
    'bookmark_manager',
    'shift',
    'circles',
    'watch_screentime',
    'alarm_smart_wake',
    'select_window_off',
    'water_lock',
    'help_clinic',
    'scrollable_header',
    'warning_off',
    'ad_off',
    'supervised_user_circle_off',
    'preview_off',
    'domain_verification_off',
    'info_i',
    'measuring_tape',
    'shift_lock',
    'indeterminate_question_box',
    'shadow_add',
    'shadow_minus',
    'mail',
    'mail',
    'call',
    'notifications',
    'send',
    'chat',
    'link',
    'forum',
    'inventory_2',
    'phone_in_talk',
    'contact_support',
    'chat_bubble',
    'notifications_active',
    'alternate_email',
    'sms',
    'comment',
    'power_settings_new',
    'hub',
    'person_search',
    'import_contacts',
    'contact_mail',
    'contacts',
    'live_help',
    'forward_to_inbox',
    'mark_email_unread',
    'reviews',
    'lan',
    'contact_phone',
    'hourglass_top',
    'mode_comment',
    'inbox',
    'drafts',
    'outgoing_mail',
    'hourglass_bottom',
    'mark_email_read',
    'link_off',
    'calendar_add_on',
    'add_comment',
    'phone_enabled',
    'speaker_notes',
    'g_translate',
    'perm_phone_msg',
    'co_present',
    'notifications_off',
    'call_end',
    'topic',
    'cell_tower',
    'mark_chat_unread',
    'schedule_send',
    'dialpad',
    'call_made',
    'satellite_alt',
    'mark_unread_chat_alt',
    'unarchive',
    '3p',
    'cancel_presentation',
    'mark_as_unread',
    'move_to_inbox',
    'attach_email',
    'phonelink_ring',
    'next_plan',
    'unsubscribe',
    'phone_callback',
    'call_received',
    'settings_phone',
    'call_split',
    'present_to_all',
    'add_call',
    'markunread_mailbox',
    'all_inbox',
    'voice_chat',
    'phone_forwarded',
    'mail_lock',
    'attribution',
    'voicemail',
    'duo',
    'contact_emergency',
    'mark_chat_read',
    'upcoming',
    'phone_disabled',
    'outbox',
    'swap_calls',
    'phonelink_lock',
    'spoke',
    'cancel_schedule_send',
    'picture_in_picture_alt',
    'ring_volume',
    'notifications_paused',
    'quickreply',
    'phone_missed',
    'comment_bank',
    'send_and_archive',
    'chat_add_on',
    'phonelink_erase',
    'settings_bluetooth',
    'picture_in_picture',
    'comments_disabled',
    'video_chat',
    'score',
    'pause_presentation',
    'speaker_phone',
    'cell_wifi',
    'speaker_notes_off',
    'auto_read_play',
    'mms',
    'call_merge',
    'play_for_work',
    'call_missed_outgoing',
    'call_missed',
    'wifi_channel',
    'calendar_apps_script',
    'phone_paused',
    'rtt',
    'auto_read_pause',
    'phone_locked',
    'wifi_calling',
    'chat_apps_script',
    'dialer_sip',
    'nat',
    'sip',
    'phone_bluetooth_speaker',
    'e911_avatar',
    'inbox_customize',
    'stacked_email',
    'tooltip',
    'business_messages',
    'chat_error',
    'notification_multiple',
    'ods',
    'chat_paste_go',
    'signal_cellular_add',
    'outbox_alt',
    'call_log',
    'call_quality',
    'odt',
    'network_intelligence_update',
    'stacked_inbox',
    'phonelink_ring_off',
    'network_intelligence_history',
    'network_manage',
    'wifi_proxy',
    'wifi_add',
    'edit',
    'edit',
    'photo_camera',
    'filter_alt',
    'image',
    'navigate_next',
    'tune',
    'timer',
    'picture_as_pdf',
    'circle',
    'palette',
    'add_a_photo',
    'photo_library',
    'navigate_before',
    'auto_stories',
    'add_photo_alternate',
    'brush',
    'imagesmode',
    'nature',
    'flash_on',
    'wb_sunny',
    'camera',
    'straighten',
    'looks_one',
    'landscape',
    'timelapse',
    'slideshow',
    'grid_on',
    'rotate_right',
    'crop_square',
    'crop_free',
    'adjust',
    'style',
    'aspect_ratio',
    'brightness_6',
    'photo',
    'nature_people',
    'filter_vintage',
    'image_search',
    'crop',
    'blur_on',
    'center_focus_strong',
    'contrast',
    'compare',
    'looks_two',
    'rotate_left',
    'colorize',
    'flare',
    'filter_none',
    'wb_incandescent',
    'filter_drama',
    'healing',
    'looks_3',
    'wb_twilight',
    'brightness_5',
    'invert_colors',
    'animation',
    'lens',
    'opacity',
    'incomplete_circle',
    'broken_image',
    'filter_center_focus',
    'add_to_photos',
    'brightness_4',
    'flash_off',
    'flip',
    'center_focus_weak',
    'auto_awesome_motion',
    'flip_camera_android',
    'mic_external_on',
    'grain',
    'no_photography',
    'details',
    'lens_blur',
    'image_not_supported',
    'web_stories',
    'panorama',
    'dehaze',
    'gif_box',
    'flaky',
    'loupe',
    'exposure_plus_1',
    'settings_brightness',
    'texture',
    'auto_awesome_mosaic',
    'looks_4',
    'filter_1',
    'flip_camera_ios',
    'timer_off',
    'panorama_fish_eye',
    'view_compact',
    'brightness_1',
    'filter',
    'control_point_duplicate',
    'photo_camera_front',
    'brightness_7',
    'photo_album',
    'transform',
    'linked_camera',
    'view_comfy',
    'crop_16_9',
    'looks',
    'hide_image',
    'looks_5',
    'exposure',
    'rotate_90_degrees_ccw',
    'filter_hdr',
    'gif',
    'brightness_3',
    'hdr_strong',
    'leak_add',
    'gradient',
    'crop_7_5',
    'hdr_auto',
    'crop_portrait',
    'vrpano',
    'blur_circular',
    'camera_roll',
    'motion_photos_auto',
    'rotate_90_degrees_cw',
    'photo_size_select_small',
    'brightness_2',
    'shutter_speed',
    'looks_6',
    'camera_front',
    'flash_auto',
    'crop_landscape',
    'filter_2',
    'filter_tilt_shift',
    'monochrome_photos',
    'deblur',
    'night_sight_auto',
    'motion_photos_paused',
    'crop_5_4',
    'filter_4',
    'hdr_weak',
    'filter_3',
    'crop_rotate',
    'crop_3_2',
    'tonality',
    'ev_shadow',
    'switch_camera',
    'photo_frame',
    'fluorescent',
    'exposure_zero',
    'photo_size_select_large',
    'macro_off',
    'filter_frames',
    'party_mode',
    'raw_on',
    'motion_blur',
    'exposure_plus_2',
    'photo_camera_back',
    'blur_linear',
    'exposure_neg_1',
    'wb_iridescent',
    'filter_b_and_w',
    'motion_photos_off',
    'switch_video',
    'panorama_horizontal',
    'face_retouching_off',
    'filter_5',
    'blur_medium',
    'invert_colors_off',
    'filter_7',
    'burst_mode',
    'panorama_photosphere',
    'hdr_on',
    'grid_off',
    'filter_9_plus',
    'filter_8',
    'blur_short',
    'timer_10',
    'dirty_lens',
    'filter_9',
    'wb_shade',
    'no_flash',
    'filter_6',
    'image_aspect_ratio',
    'trail_length',
    'exposure_neg_2',
    'vignette',
    'timer_3',
    'leak_remove',
    '60fps_select',
    'blur_off',
    '30fps_select',
    'perm_camera_mic',
    'camera_rear',
    'mic_external_off',
    'trail_length_medium',
    'trail_length_short',
    'panorama_vertical',
    'night_sight_auto_off',
    'autofps_select',
    'panorama_wide_angle',
    'mp',
    'hdr_enhanced_select',
    'hdr_off',
    '24mp',
    'hdr_on_select',
    '22mp',
    '10mp',
    '12mp',
    'hdr_plus',
    '18mp',
    'hdr_auto_select',
    'raw_off',
    'wb_auto',
    '9mp',
    '13mp',
    '20mp',
    '5mp',
    '7mp',
    '15mp',
    'hdr_off_select',
    'hevc',
    '16mp',
    '19mp',
    '14mp',
    '23mp',
    '2mp',
    '8mp',
    '3mp',
    '6mp',
    '11mp',
    '21mp',
    '17mp',
    '4mp',
    'gallery_thumbnail',
    'masked_transitions',
    'motion_photos_on',
    'settings_photo_camera',
    'photo_prints',
    'settings_panorama',
    'vr180_create2d',
    'planner_banner_ad_pt',
    'settings_night_sight',
    'unknown_2',
    'settings_timelapse',
    'settings_video_camera',
    'motion_mode',
    'settings_cinematic_blur',
    'settings_motion_mode',
    'vr180_create2d_off',
    '50mp',
    'background_dot_large',
    'settings_b_roll',
    'background_grid_small',
    'macro_auto',
    'shutter_speed_add',
    'ev_shadow_add',
    'high_density',
    'low_density',
    'settings_slow_motion',
    'shutter_speed_minus',
    'contrast_rtl_off',
    'ev_shadow_minus',
    'texture_add',
    'texture_minus',
    'shopping_cart',
    'shopping_cart',
    'payments',
    'shopping_bag',
    'monitoring',
    'credit_card',
    'receipt_long',
    'attach_money',
    'storefront',
    'trending_up',
    'sell',
    'database',
    'account_balance',
    'work',
    'paid',
    'account_balance_wallet',
    'analytics',
    'query_stats',
    'store',
    'savings',
    'monetization_on',
    'calculate',
    'qr_code_scanner',
    'bar_chart',
    'account_tree',
    'add_shopping_cart',
    'receipt',
    'redeem',
    'currency_exchange',
    'trending_flat',
    'shopping_basket',
    'qr_code_2',
    'domain',
    'precision_manufacturing',
    'qr_code',
    'leaderboard',
    'corporate_fare',
    'timeline',
    'insert_chart',
    'currency_rupee',
    'wallet',
    'show_chart',
    'euro',
    'work_history',
    'meeting_room',
    'credit_score',
    'barcode_scanner',
    'pie_chart',
    'loyalty',
    'copyright',
    'conversion_path',
    'barcode',
    'track_changes',
    'trending_down',
    'price_check',
    'euro_symbol',
    'schema',
    'add_business',
    'add_card',
    'card_membership',
    'currency_bitcoin',
    'price_change',
    'production_quantity_limits',
    'donut_large',
    'tenancy',
    'data_exploration',
    'bubble_chart',
    'donut_small',
    'contactless',
    'money',
    'stacked_line_chart',
    'stacked_bar_chart',
    'toll',
    'money_off',
    'cases',
    'currency_yen',
    'currency_pound',
    'area_chart',
    'atr',
    'remove_shopping_cart',
    'room_preferences',
    'add_chart',
    'shop',
    'domain_add',
    'grouped_bar_chart',
    'card_travel',
    'legend_toggle',
    'scatter_plot',
    'credit_card_off',
    'mediation',
    'ssid_chart',
    'candlestick_chart',
    'waterfall_chart',
    'currency_ruble',
    'full_stacked_bar_chart',
    'domain_disabled',
    'strikethrough_s',
    'shop_two',
    'next_week',
    'atm',
    'multiline_chart',
    'currency_lira',
    'currency_yuan',
    'no_meeting_room',
    'troubleshoot',
    'currency_franc',
    'finance',
    'data_table',
    'bar_chart_4_bars',
    'bid_landscape',
    'contactless_off',
    'chart_data',
    'universal_currency_alt',
    'podium',
    'order_approve',
    'conveyor_belt',
    'family_history',
    'send_money',
    'flowsheet',
    'forklift',
    'qr_code_2_add',
    'box_add',
    'pallet',
    'front_loader',
    'inactive_order',
    'box',
    'trolley',
    'bid_landscape_disabled',
    'barcode_reader',
    'conversion_path_off',
    'order_play',
    'work_alert',
    'box_edit',
    'work_update',
    'network_node',
    'pin_drop',
    'pin_drop',
    'location_on',
    'map',
    'home_pin',
    'explore',
    'restaurant',
    'flag',
    'my_location',
    'local_fire_department',
    'person_pin_circle',
    'local_mall',
    'near_me',
    'where_to_vote',
    'business_center',
    'east',
    'restaurant_menu',
    'handyman',
    'factory',
    'local_library',
    'home_work',
    'medical_services',
    'layers',
    'local_activity',
    'share_location',
    'emergency',
    'north_east',
    'add_location',
    'fastfood',
    'warehouse',
    'navigation',
    'person_pin',
    'local_parking',
    'home_repair_service',
    'local_hospital',
    'south',
    'local_police',
    'zoom_out_map',
    'location_searching',
    'local_florist',
    'location_away',
    'crisis_alert',
    'west',
    'local_gas_station',
    'park',
    'maps_ugc',
    'cleaning_services',
    'local_atm',
    'package',
    '360',
    'electrical_services',
    'north',
    'flag_circle',
    'add_location_alt',
    'directions',
    'fmd_bad',
    'theater_comedy',
    'local_drink',
    'location_home',
    'local_pizza',
    'not_listed_location',
    'local_post_office',
    'wine_bar',
    'beenhere',
    'signpost',
    'local_convenience_store',
    'alt_route',
    'tour',
    'trip_origin',
    'church',
    'traffic',
    'local_laundry_service',
    'safety_check',
    'ev_station',
    'takeout_dining',
    'moving',
    'zoom_in_map',
    'soup_kitchen',
    'pest_control',
    'stadium',
    'transfer_within_a_station',
    'location_off',
    'connecting_airports',
    'multiple_stop',
    'wrong_location',
    'edit_location',
    'plumbing',
    'mode_of_travel',
    'minor_crash',
    'south_east',
    'add_road',
    'local_pharmacy',
    'fire_truck',
    'castle',
    'dry_cleaning',
    'set_meal',
    'baby_changing_station',
    'edit_location_alt',
    'layers_clear',
    'mosque',
    'north_west',
    'local_car_wash',
    'edit_attributes',
    'run_circle',
    'transit_enterexit',
    'sos',
    'satellite',
    'edit_road',
    'south_west',
    'streetview',
    'add_home',
    'kebab_dining',
    'airline_stops',
    'fire_hydrant',
    'local_see',
    'assist_walker',
    'add_home_work',
    'flight_class',
    'remove_road',
    'no_meals',
    'synagogue',
    'fort',
    'temple_buddhist',
    'location_disabled',
    'compass_calibration',
    'temple_hindu',
    'explore_off',
    'pest_control_rodent',
    'directions_alt',
    'near_me_disabled',
    'pergola',
    'directions_off',
    'directions_alt_off',
    'pet_supplies',
    'moved_location',
    'move_location',
    'safety_check_off',
    'moving_ministry',
    'move',
    'package_2',
    'description',
    'description',
    'content_copy',
    'dashboard',
    'edit_note',
    'menu_book',
    'grid_view',
    'list',
    'folder',
    'list_alt',
    'inventory',
    'folder_open',
    'article',
    'fact_check',
    'attach_file',
    'format_list_bulleted',
    'assignment',
    'task',
    'checklist',
    'cloud_upload',
    'draft',
    'summarize',
    'feed',
    'draw',
    'cloud',
    'newspaper',
    'view_list',
    'file_copy',
    'note_add',
    'border_color',
    'book',
    'history_edu',
    'design_services',
    'pending_actions',
    'format_quote',
    'post_add',
    'request_quote',
    'cloud_download',
    'drag_handle',
    'table',
    'contact_page',
    'archive',
    'space_dashboard',
    'content_paste',
    'percent',
    'attachment',
    'assignment_ind',
    'format_list_numbered',
    'assignment_turned_in',
    'tag',
    'table_chart',
    'sticky_note_2',
    'dashboard_customize',
    'text_fields',
    'reorder',
    'integration_instructions',
    'format_bold',
    'find_in_page',
    'text_snippet',
    'note',
    'document_scanner',
    'checklist_rtl',
    'note_alt',
    'edit_document',
    'cloud_sync',
    'table_rows',
    'perm_media',
    'cloud_done',
    'title',
    'table_view',
    'content_cut',
    'notes',
    'data_object',
    'cut',
    'subject',
    'functions',
    'format_italic',
    'content_paste_search',
    'format_color_fill',
    'folder_shared',
    'plagiarism',
    'horizontal_rule',
    'file_present',
    'folder_copy',
    'format_align_left',
    'ballot',
    'cloud_off',
    'team_dashboard',
    'format_paint',
    'add_link',
    'view_column',
    'read_more',
    'difference',
    'view_agenda',
    'format_size',
    'format_underlined',
    'vertical_align_top',
    'toc',
    'height',
    'vertical_align_bottom',
    'copy_all',
    'view_week',
    'drive_folder_upload',
    'assignment_late',
    'format_color_text',
    'view_module',
    'drive_file_move',
    'low_priority',
    'assignment_return',
    'format_align_center',
    'segment',
    'folder_special',
    'calendar_view_month',
    'folder_zip',
    'polyline',
    'square',
    'breaking_news_alt_1',
    'format_align_right',
    'grading',
    'view_headline',
    'linear_scale',
    'view_quilt',
    'edit_off',
    'view_carousel',
    'text_increase',
    'request_page',
    'view_sidebar',
    'pages',
    'text_format',
    'format_align_justify',
    'calendar_view_week',
    'hexagon',
    'numbers',
    'docs_add_on',
    'folder_delete',
    'format_shapes',
    'forms_add_on',
    'imagesearch_roller',
    'calendar_view_day',
    'video_file',
    'font_download',
    'format_list_numbered_rtl',
    'join_inner',
    'add_to_drive',
    'content_paste_go',
    'restore_page',
    'rectangle',
    'rule_folder',
    'vertical_split',
    'format_color_reset',
    'cloud_circle',
    'view_stream',
    'format_indent_increase',
    'spellcheck',
    'assignment_returned',
    'data_array',
    'align_horizontal_left',
    'pivot_table_chart',
    'text_decrease',
    'deselect',
    'vertical_align_center',
    'pentagon',
    'merge_type',
    'space_bar',
    'format_strikethrough',
    'short_text',
    'view_day',
    'flip_to_front',
    'join_left',
    'border_all',
    'shape_line',
    'format_line_spacing',
    'line_weight',
    'horizontal_split',
    'format_indent_decrease',
    'join_right',
    'align_horizontal_center',
    'snippet_folder',
    'subtitles_off',
    'align_vertical_bottom',
    'folder_off',
    'glyphs',
    'align_horizontal_right',
    'format_clear',
    'function',
    'insert_page_break',
    'vertical_distribute',
    'content_paste_off',
    'line_axis',
    'horizontal_distribute',
    'superscript',
    'line_style',
    'flip_to_back',
    'align_vertical_center',
    'align_vertical_top',
    'margin',
    'clarify',
    'wrap_text',
    'view_array',
    'subscript',
    'counter_1',
    'border_clear',
    'border_style',
    'amp_stories',
    'border_outer',
    'type_specimen',
    'text_rotate_vertical',
    'ink_pen',
    'padding',
    'forms_apps_script',
    'ink_eraser',
    'border_vertical',
    'text_rotation_none',
    'format_textdirection_l_to_r',
    'docs_apps_script',
    'format_overline',
    'border_horizontal',
    'font_download_off',
    'format_textdirection_r_to_l',
    'newsmode',
    'text_rotation_angleup',
    'border_bottom',
    'text_rotation_down',
    'border_inner',
    'finance_chip',
    'border_top',
    'border_left',
    'news',
    'text_rotation_angledown',
    'assignment_add',
    'text_rotate_up',
    'border_right',
    'format_h1',
    'ink_highlighter',
    'view_column_2',
    'join',
    'overview',
    'equal',
    'full_coverage',
    'colors',
    'slide_library',
    'counter_2',
    'format_underlined_squiggle',
    'frame_inspect',
    'format_h2',
    'lab_profile',
    'format_paragraph',
    'breaking_news',
    'format_list_bulleted_add',
    'counter_3',
    'format_image_left',
    'variables',
    'signature',
    'format_image_right',
    'ink_marker',
    'format_h3',
    'csv',
    'format_h5',
    'format_h6',
    'remove_selection',
    'process_chart',
    'voting_chip',
    'format_h4',
    'format_ink_highlighter',
    'location_chip',
    'shapes',
    'sticky_note',
    'counter_4',
    'export_notes',
    'scan_delete',
    'stylus_laser_pointer',
    'unknown_document',
    'add_notes',
    'frame_source',
    'text_ad',
    'width',
    'diagnosis',
    'folder_managed',
    'cell_merge',
    'contract_edit',
    'draw_abstract',
    'select',
    'table_chart_view',
    'contract',
    'format_text_overflow',
    'grid_guides',
    'regular_expression',
    'reset_image',
    'business_chip',
    'counter_5',
    'format_letter_spacing_standard',
    'list_alt_add',
    'source_notes',
    'table_rows_narrow',
    'custom_typography',
    'stock_media',
    'text_select_move_forward_character',
    'lasso_select',
    'match_case',
    'smb_share',
    'ungroup',
    'decimal_increase',
    'insert_text',
    'counter_6',
    'format_letter_spacing_2',
    'line_end',
    'line_end_arrow',
    'line_end_arrow_notch',
    'thumbnail_bar',
    'line_start',
    'stroke_full',
    'tab_close',
    'counter_9',
    'format_letter_spacing',
    'note_stack',
    'scan',
    'counter_7',
    'highlighter_size_3',
    'line_end_square',
    'post',
    'text_select_start',
    'counter_8',
    'draw_collage',
    'flex_wrap',
    'folder_supervised',
    'format_letter_spacing_wide',
    'format_letter_spacing_wider',
    'language_chinese_quick',
    'line_start_diamond',
    'match_word',
    'other_admission',
    'article_shortcut',
    'counter_0',
    'ink_eraser_off',
    'language_spanish',
    'line_curve',
    'line_end_circle',
    'line_start_circle',
    'special_character',
    'tab_duplicate',
    'tab_move',
    'text_select_jump_to_beginning',
    'align_justify_space_around',
    'align_justify_space_between',
    'align_space_between',
    'contract_delete',
    'format_text_clip',
    'line_end_diamond',
    'note_stack_add',
    'pen_size_5',
    'person_book',
    'tab_group',
    'tab_new_right',
    'top_panel_close',
    'top_panel_open',
    'tsv',
    'attach_file_add',
    'fit_width',
    'flex_direction',
    'frame_reload',
    'heap_snapshot_multiple',
    'heap_snapshot_thumbnail',
    'language_french',
    'language_gb_english',
    'language_international',
    'language_korean_latin',
    'line_start_square',
    'sheets_rtl',
    'stroke_partial',
    'tab_close_right',
    'tab_recent',
    'text_select_move_forward_word',
    'text_select_move_up',
    'align_justify_space_even',
    'heap_snapshot_large',
    'highlighter_size_5',
    'language_chinese_dayi',
    'language_chinese_wubi',
    'language_pinyin',
    'line_start_arrow',
    'line_start_arrow_notch',
    'pen_size_3',
    'text_select_move_down',
    'align_center',
    'align_flex_center',
    'align_flex_start',
    'align_justify_center',
    'align_justify_flex_end',
    'align_justify_flex_start',
    'align_self_stretch',
    'align_space_around',
    'align_space_even',
    'fit_page',
    'format_text_wrap',
    'highlighter_size_4',
    'language_chinese_array',
    'language_chinese_cangjie',
    'language_chinese_pinyin',
    'language_us',
    'language_us_colemak',
    'language_us_dvorak',
    'letter_switch',
    'pen_size_2',
    'pen_size_4',
    'text_select_end',
    'text_select_jump_to_end',
    'align_end',
    'align_flex_end',
    'align_items_stretch',
    'align_justify_stretch',
    'align_start',
    'align_stretch',
    'decimal_decrease',
    'flex_no_wrap',
    'folder_data',
    'highlighter_size_1',
    'highlighter_size_2',
    'pen_size_1',
    'text_select_move_back_character',
    'text_select_move_back_word',
    'play_arrow',
    'play_arrow',
    'play_circle',
    'mic',
    'videocam',
    'volume_up',
    'pause',
    'music_note',
    'library_books',
    'movie',
    'skip_next',
    'speed',
    'replay',
    'volume_off',
    'pause_circle',
    'view_in_ar',
    'fiber_manual_record',
    'skip_previous',
    'stop_circle',
    'stop',
    'equalizer',
    'subscriptions',
    'video_library',
    'fast_forward',
    'playlist_add',
    'video_call',
    'repeat',
    'volume_mute',
    'shuffle',
    'mic_off',
    'hearing',
    'library_music',
    'playlist_add_check',
    'podcasts',
    'fast_rewind',
    'queue_music',
    'sound_detection_dog_barking',
    'video_camera_front',
    'subtitles',
    'volume_down',
    'play_pause',
    'album',
    'radio',
    'discover_tune',
    'av_timer',
    'library_add_check',
    'videocam_off',
    'closed_caption',
    'stream',
    'forward_10',
    'not_started',
    'playlist_play',
    'replay_10',
    'fiber_new',
    'branding_watermark',
    'text_to_speech',
    'recent_actors',
    'playlist_remove',
    'interpreter_mode',
    'slow_motion_video',
    'frame_person',
    'playlist_add_check_circle',
    'settings_voice',
    'video_settings',
    'featured_play_list',
    'audio_file',
    'sound_detection_loud_sound',
    'lyrics',
    'play_lesson',
    'hd',
    'repeat_one',
    'call_to_action',
    'high_quality',
    'add_to_queue',
    'music_off',
    'video_camera_back',
    'spatial_audio_off',
    'shuffle_on',
    'playlist_add_circle',
    'volume_down_alt',
    'hearing_disabled',
    'featured_video',
    'replay_5',
    'repeat_on',
    'queue_play_next',
    'speech_to_text',
    'spatial_audio',
    'art_track',
    'explicit',
    'airplay',
    'forward_5',
    '4k',
    'forward_30',
    'music_video',
    'replay_30',
    'control_camera',
    'spatial_tracking',
    'closed_caption_disabled',
    'digital_out_of_home',
    'video_label',
    'fiber_smart_record',
    'play_disabled',
    'repeat_one_on',
    'broadcast_on_personal',
    'sd',
    'missed_video_call',
    'brand_awareness',
    'surround_sound',
    'fiber_pin',
    '10k',
    'sound_detection_glass_break',
    '60fps',
    'remove_from_queue',
    'broadcast_on_home',
    'fiber_dvr',
    '30fps',
    '4k_plus',
    'video_stable',
    '8k',
    '1k',
    'privacy',
    'instant_mix',
    '8k_plus',
    '2k',
    '7k',
    '1k_plus',
    '9k',
    '9k_plus',
    '5k',
    '6k',
    '2k_plus',
    '5k_plus',
    '6k_plus',
    '3k',
    '7k_plus',
    '3k_plus',
    'ar_on_you',
    'no_sound',
    'video_search',
    'cinematic_blur',
    'hangout_video',
    'genres',
    'media_link',
    'video_camera_front_off',
    'movie_edit',
    'voice_selection',
    'autoplay',
    'mic_double',
    'autopause',
    'forward_media',
    'movie_info',
    'resume',
    'hangout_video_off',
    'select_to_speak',
    'audio_description',
    'autostop',
    '2d',
    'full_hd',
    'forward_circle',
    'frame_person_off',
    'view_in_ar_off',
    'sound_sampler',
    'local_shipping',
    'local_shipping',
    'directions_car',
    'flight',
    'directions_run',
    'directions_walk',
    'flight_takeoff',
    'directions_bus',
    'train',
    'directions_bike',
    'airport_shuttle',
    'pedal_bike',
    'directions_boat',
    'two_wheeler',
    'agriculture',
    'local_taxi',
    'sailing',
    'electric_car',
    'flight_land',
    'hail',
    'no_crash',
    'commute',
    'motorcycle',
    'car_crash',
    'tram',
    'departure_board',
    'subway',
    'electric_moped',
    'turn_right',
    'electric_scooter',
    'fork_right',
    'directions_subway',
    'tire_repair',
    'electric_bike',
    'rv_hookup',
    'bus_alert',
    'turn_left',
    'transportation',
    'airlines',
    'taxi_alert',
    'u_turn_left',
    'directions_railway',
    'electric_rickshaw',
    'turn_slight_right',
    'u_turn_right',
    'fork_left',
    'railway_alert',
    'bike_scooter',
    'turn_sharp_right',
    'turn_slight_left',
    'no_transfer',
    'snowmobile',
    'turn_sharp_left',
    'flightsmode',
    'swap_driving_apps_wheel',
    'ambulance',
    'school',
    'school',
    'campaign',
    'construction',
    'engineering',
    'volunteer_activism',
    'science',
    'sports_esports',
    'confirmation_number',
    'real_estate_agent',
    'cake',
    'self_improvement',
    'sports_soccer',
    'air',
    'biotech',
    'water',
    'hiking',
    'architecture',
    'sports_score',
    'personal_injury',
    'sports_basketball',
    'waves',
    'theaters',
    'sports_tennis',
    'switch_account',
    'nights_stay',
    'sports_gymnastics',
    'how_to_vote',
    'backpack',
    'sports_motorsports',
    'surfing',
    'sports_kabaddi',
    'piano',
    'sports',
    'toys',
    'sports_volleyball',
    'sports_baseball',
    'sports_martial_arts',
    'camping',
    'downhill_skiing',
    'swords',
    'scoreboard',
    'kayaking',
    'phishing',
    'sports_handball',
    'sports_football',
    'skateboarding',
    'sports_golf',
    'toys_fan',
    'sports_cricket',
    'trophy',
    'nordic_walking',
    'roller_skating',
    'kitesurfing',
    'rowing',
    'storm',
    'scuba_diving',
    'sports_mma',
    'paragliding',
    'snowboarding',
    'sports_hockey',
    'ice_skating',
    'snowshoeing',
    'sports_rugby',
    'sledding',
    'family_link',
    'piano_off',
    'no_backpack',
    'ifl',
    'rewarded_ads',
    'health_metrics',
    'cake_add',
    'mindfulness',
    'steps',
    'sprint',
    'exercise',
    'readiness_score',
    'stress_management',
    'laps',
    'menstrual_health',
    'ecg_heart',
    'relax',
    'azm',
    'person_play',
    'pace',
    'floor',
    'distance',
    'person_celebrate',
    'avg_pace',
    'avg_time',
    'fertile',
    'onsen',
    'podiatry',
    'water_medium',
    'play_shapes',
    'spo2',
    'water_full',
    'bath_outdoor',
    'eda',
    'interactive_space',
    'bia',
    'elevation',
    'hr_resting',
    'glass_cup',
    'water_loss',
    'monitor_weight_loss',
    'sauna',
    'sleep_score',
    'bath_private',
    'monitor_weight_gain',
    'bath_public_large',
    'check_in_out',
    'physical_therapy',
    'phone_iphone',
    'phone_iphone',
    'save',
    'smartphone',
    'print',
    'keyboard_arrow_down',
    'computer',
    'devices',
    'desktop_windows',
    'smart_display',
    'dns',
    'keyboard_backspace',
    'headphones',
    'smart_toy',
    'phone_android',
    'memory',
    'keyboard_arrow_right',
    'keyboard',
    'live_tv',
    'laptop_mac',
    'headset_mic',
    'keyboard_arrow_up',
    'tv',
    'device_thermostat',
    'mouse',
    'balance',
    'route',
    'point_of_sale',
    'keyboard_arrow_left',
    'laptop_chromebook',
    'keyboard_return',
    'watch',
    'power',
    'laptop_windows',
    'router',
    'developer_board',
    'display_settings',
    'scale',
    'book_online',
    'developer_mode',
    'cast',
    'fax',
    'cast_for_education',
    'videogame_asset',
    'device_hub',
    'straight',
    'screen_search_desktop',
    'desktop_mac',
    'mobile_friendly',
    'settings_ethernet',
    'settings_input_antenna',
    'monitor',
    'important_devices',
    'send_to_mobile',
    'devices_other',
    'tablet_mac',
    'system_update',
    'settings_remote',
    'monitor_weight',
    'screen_rotation',
    'screen_share',
    'keyboard_alt',
    'settings_input_component',
    'speaker',
    'merge',
    'sim_card',
    'vibration',
    'tablet',
    'keyboard_tab',
    'power_off',
    'connected_tv',
    'screenshot_monitor',
    'remember_me',
    'browser_updated',
    'security_update_good',
    'sd_card',
    'cast_connected',
    'device_unknown',
    'tablet_android',
    'charging_station',
    'phonelink_setup',
    'punch_clock',
    'scanner',
    'screenshot',
    'settings_input_hdmi',
    'stay_current_portrait',
    'tap_and_play',
    'print_disabled',
    'keyboard_hide',
    'security_update_warning',
    'keyboard_capslock',
    'disc_full',
    'app_blocking',
    'speaker_group',
    'mobile_screen_share',
    'aod',
    'sd_card_alert',
    'tty',
    'add_to_home_screen',
    'lift_to_talk',
    'earbuds',
    'perm_device_information',
    'stop_screen_share',
    'mobile_off',
    'headset_off',
    'desktop_access_disabled',
    'reset_tv',
    'offline_share',
    'adf_scanner',
    'headphones_battery',
    'screen_lock_portrait',
    'roundabout_right',
    'dock',
    'settop_component',
    'settings_input_svideo',
    'watch_off',
    'smart_screen',
    'stay_current_landscape',
    'chromecast_device',
    'settings_cell',
    'earbuds_battery',
    'home_max',
    'power_input',
    'no_sim',
    'ramp_right',
    'screen_lock_landscape',
    'roundabout_left',
    'stay_primary_landscape',
    'stay_primary_portrait',
    'developer_board_off',
    'tv_off',
    'home_mini',
    'phonelink_off',
    'ramp_left',
    'screen_lock_rotation',
    'videogame_asset_off',
    'open_in_phone',
    'aod_tablet',
    'gamepad',
    'robot',
    'rear_camera',
    'devices_wearables',
    'jamboard_kiosk',
    'mimo',
    'tv_options_edit_channels',
    'app_promo',
    'developer_mode_tv',
    'mimo_disconnect',
    'joystick',
    'touchpad_mouse',
    'camera_video',
    'tv_signin',
    'robot_2',
    'aod_watch',
    'memory_alt',
    'hard_drive',
    'night_sight_max',
    'ecg',
    'tv_guide',
    'devices_off',
    'open_jam',
    'stream_apps',
    'deskphone',
    'screenshot_tablet',
    'cast_pause',
    'cast_warning',
    'keyboard_tab_rtl',
    'pacemaker',
    'tv_remote',
    'watch_wake',
    'hard_drive_2',
    'print_error',
    'lda',
    'print_lock',
    'watch_button_press',
    'audio_video_receiver',
    'print_add',
    'print_connect',
    'reset_wrench',
    'ventilator',
    'dark_mode',
    'dark_mode',
    'light_mode',
    'wifi',
    'signal_cellular_alt',
    'password',
    'widgets',
    'pin',
    'storage',
    'android',
    'rss_feed',
    'battery_full',
    'wifi_off',
    'bluetooth',
    'battery_charging_full',
    'dvr',
    'thermostat',
    'graphic_eq',
    'nightlight',
    'battery_5_bar',
    'signal_wifi_4_bar',
    'gpp_maybe',
    'cable',
    'gpp_bad',
    'data_usage',
    'battery_4_bar',
    'signal_cellular_4_bar',
    'radar',
    'battery_full_alt',
    'airplanemode_active',
    'battery_0_bar',
    'cameraswitch',
    'wallpaper',
    'signal_disconnected',
    'flashlight_on',
    'network_check',
    'battery_6_bar',
    'charger',
    'wifi_tethering',
    'sim_card_download',
    'usb',
    'quick_phrases',
    'splitscreen',
    'battery_3_bar',
    'adb',
    'battery_1_bar',
    'network_wifi_3_bar',
    'battery_low',
    'battery_alert',
    'bluetooth_searching',
    'network_wifi',
    'bluetooth_connected',
    'wifi_find',
    '5g',
    'battery_2_bar',
    'brightness_high',
    'network_cell',
    'nfc',
    'pattern',
    'data_saver_on',
    'bluetooth_disabled',
    'signal_wifi_statusbar_not_connected',
    'signal_cellular_3_bar',
    'signal_wifi_bad',
    'network_wifi_2_bar',
    'noise_control_off',
    'network_wifi_1_bar',
    'brightness_medium',
    'signal_wifi_off',
    'brightness_low',
    'mode_standby',
    'battery_very_low',
    'signal_wifi_0_bar',
    'mobiledata_off',
    'battery_charging_20',
    'battery_charging_80',
    'grid_4x4',
    'battery_saver',
    'battery_charging_90',
    'flashlight_off',
    'signal_wifi_statusbar_null',
    'battery_charging_50',
    'settings_system_daydream',
    'battery_unknown',
    'signal_cellular_2_bar',
    'screen_rotation_alt',
    'signal_cellular_1_bar',
    'wifi_calling_3',
    'badge_critical_battery',
    '4g_mobiledata',
    'wifi_lock',
    'noise_aware',
    'do_not_disturb_on_total_silence',
    'battery_charging_60',
    'signal_cellular_connected_no_internet_0_bar',
    'nearby_error',
    'signal_cellular_0_bar',
    'battery_charging_30',
    'network_ping',
    'signal_cellular_connected_no_internet_4_bar',
    'wifi_tethering_error',
    'brightness_auto',
    'edgesensor_high',
    'wifi_calling_1',
    'wifi_2_bar',
    'airplanemode_inactive',
    'signal_cellular_nodata',
    'grid_3x3',
    '1x_mobiledata',
    'lte_mobiledata',
    'perm_data_setting',
    'signal_cellular_alt_2_bar',
    'devices_fold',
    'bluetooth_drive',
    'perm_scan_wifi',
    'network_locked',
    'media_bluetooth_on',
    'wifi_calling_2',
    '4g_plus_mobiledata',
    'signal_cellular_off',
    'timer_10_select',
    'wifi_tethering_off',
    'edgesensor_low',
    'signal_cellular_alt_1_bar',
    'wifi_1_bar',
    '3g_mobiledata',
    'usb_off',
    'apk_install',
    'signal_cellular_null',
    'lte_plus_mobiledata',
    'grid_goldenratio',
    'g_mobiledata',
    'portable_wifi_off',
    'noise_control_on',
    'media_bluetooth_off',
    'timer_3_select',
    'e_mobiledata',
    'apk_document',
    'nearby_off',
    'h_mobiledata',
    'r_mobiledata',
    'h_plus_mobiledata',
    'dual_screen',
    'dock_to_left',
    'stylus',
    'nearby',
    'stylus_note',
    'dock_to_right',
    'screenshot_region',
    'overview_key',
    'brightness_alert',
    'keyboard_keys',
    'brightness_empty',
    'battery_status_good',
    'screen_record',
    'network_wifi_3_bar_locked',
    'splitscreen_left',
    'splitscreen_right',
    'dock_to_bottom',
    'keyboard_off',
    'screenshot_keyboard',
    'wallpaper_slideshow',
    'battery_error',
    'keyboard_external_input',
    'magnify_fullscreen',
    'screenshot_frame',
    'battery_plus',
    'display_external_input',
    'network_wifi_2_bar_locked',
    '1x_mobiledata_badge',
    'battery_change',
    'keyboard_full',
    'magnify_docked',
    '5g_mobiledata_badge',
    'backlight_high',
    'backlight_low',
    'keyboard_onscreen',
    'network_wifi_1_bar_locked',
    'wifi_notification',
    '4g_mobiledata_badge',
    'keyboard_capslock_badge',
    'keyboard_previous_language',
    'lte_mobiledata_badge',
    'lte_plus_mobiledata_badge',
    'screen_rotation_up',
    'wifi_home',
    '3g_mobiledata_badge',
    'battery_share',
    'e_mobiledata_badge',
    'ev_mobiledata_badge',
    'g_mobiledata_badge',
    'grid_3x3_off',
    'h_mobiledata_badge',
    'h_plus_mobiledata_badge',
    'signal_cellular_pause',
    'splitscreen_bottom',
    'splitscreen_top',
    'badge',
    'verified_user',
    'admin_panel_settings',
    'report',
    'security',
    'vpn_key',
    'shield',
    'policy',
    'exclamation',
    'privacy_tip',
    'assured_workload',
    'vpn_lock',
    'disabled_visible',
    'e911_emergency',
    'enhanced_encryption',
    'private_connectivity',
    'vpn_key_off',
    'add_moderator',
    'no_encryption',
    'sync_lock',
    'wifi_password',
    'encrypted',
    'key_visualizer',
    'remove_moderator',
    'report_off',
    'passkey',
    'shield_lock',
    'shield_person',
    'vpn_key_alert',
    'shield_locked',
    'apartment',
    'apartment',
    'location_city',
    'fitness_center',
    'lunch_dining',
    'spa',
    'cottage',
    'local_cafe',
    'hotel',
    'family_restroom',
    'beach_access',
    'local_bar',
    'pool',
    'other_houses',
    'luggage',
    'liquor',
    'casino',
    'airplane_ticket',
    'room_service',
    'sports_bar',
    'bakery_dining',
    'ramen_dining',
    'nightlife',
    'local_dining',
    'holiday_village',
    'icecream',
    'escalator_warning',
    'dinner_dining',
    'museum',
    'food_bank',
    'night_shelter',
    'festival',
    'attractions',
    'golf_course',
    'stairs',
    'villa',
    'smoke_free',
    'smoking_rooms',
    'car_rental',
    'airline_seat_recline_normal',
    'elevator',
    'gite',
    'child_friendly',
    'airline_seat_recline_extra',
    'breakfast_dining',
    'carpenter',
    'car_repair',
    'cabin',
    'brunch_dining',
    'no_food',
    'do_not_touch',
    'houseboat',
    'rice_bowl',
    'tapas',
    'wheelchair_pickup',
    'bento',
    'no_drinks',
    'do_not_step',
    'bungalow',
    'airline_seat_flat',
    'airline_seat_individual_suite',
    'escalator',
    'chalet',
    'no_luggage',
    'airline_seat_legroom_extra',
    'airline_seat_flat_angled',
    'airline_seat_legroom_normal',
    'airline_seat_legroom_reduced',
    'travel',
    'no_stroller',
    'your_trips',
    'concierge',
    'house',
    'house',
    'bed',
    'ac_unit',
    'chair',
    'coffee',
    'electric_bolt',
    'child_care',
    'sensors',
    'back_hand',
    'checkroom',
    'emergency_home',
    'grass',
    'shower',
    'mode_fan',
    'mop',
    'kitchen',
    'thermometer',
    'styler',
    'yard',
    'bathtub',
    'king_bed',
    'roofing',
    'energy_savings_leaf',
    'window',
    'valve',
    'cooking',
    'garage_home',
    'door_front',
    'mode_heat',
    'light',
    'foundation',
    'outdoor_grill',
    'garage',
    'dining',
    'table_restaurant',
    'sensor_occupied',
    'deck',
    'weekend',
    'coffee_maker',
    'humidity_high',
    'flatware',
    'highlight',
    'fireplace',
    'humidity_low',
    'mode_night',
    'electric_meter',
    'tv_gen',
    'humidity_mid',
    'bedroom_parent',
    'chair_alt',
    'blender',
    'scene',
    'microwave',
    'heat_pump',
    'oven_gen',
    'single_bed',
    'bedroom_baby',
    'bathroom',
    'in_home_mode',
    'hot_tub',
    'hardware',
    'mode_off_on',
    'sprinkler',
    'table_bar',
    'gas_meter',
    'crib',
    'countertops',
    'soap',
    'mode_cool',
    'living',
    'home_iot_device',
    'propane_tank',
    'fire_extinguisher',
    'outlet',
    'remote_gen',
    'matter',
    'gate',
    'sensor_door',
    'event_seat',
    'airware',
    'faucet',
    'dishwasher_gen',
    'energy_program_saving',
    'air_freshener',
    'balcony',
    'water_damage',
    'wash',
    'camera_indoor',
    'bedroom_child',
    'house_siding',
    'microwave_gen',
    'switch',
    'detector_smoke',
    'door_sliding',
    'iron',
    'desk',
    'energy_program_time_used',
    'water_heater',
    'umbrella',
    'fence',
    'dresser',
    'door_back',
    'doorbell',
    'mode_fan_off',
    'hvac',
    'emergency_share',
    'kettle',
    'camera_outdoor',
    'emergency_heat',
    'air_purifier_gen',
    'stroller',
    'curtains',
    'multicooker',
    'shield_moon',
    'sensors_off',
    'mode_heat_cool',
    'thermostat_auto',
    'emergency_recording',
    'smart_outlet',
    'controller_gen',
    'blinds',
    'roller_shades',
    'dry',
    'blinds_closed',
    'roller_shades_closed',
    'propane',
    'sensor_window',
    'thermostat_carbon',
    'range_hood',
    'doorbell_3p',
    'blanket',
    'tv_with_assistant',
    'vertical_shades_closed',
    'vertical_shades',
    'curtains_closed',
    'mode_heat_off',
    'mode_cool_off',
    'tamper_detection_off',
    'shelves',
    'thermometer_gain',
    'thermometer_add',
    'wall_art',
    'thermometer_loss',
    'hallway',
    'emergency_share_off',
    'thermometer_minus',
    'stadia_controller',
    'stadia_controller',
    'door_open',
    'nest_eco_leaf',
    'device_reset',
    'nest_remote_comfort_sensor',
    'nest_clock_farsight_analog',
    'laundry',
    'battery_horiz_075',
    'shield_with_heart',
    'temp_preferences_eco',
    'familiar_face_and_zone',
    'tools_power_drill',
    'airwave',
    'productivity',
    'battery_horiz_050',
    'nest_heat_link_gen_3',
    'nest_display',
    'weather_snowy',
    'activity_zone',
    'ev_charger',
    'nest_remote',
    'cleaning_bucket',
    'settings_alert',
    'nest_cam_indoor',
    'arrows_more_up',
    'home_storage',
    'nest_heat_link_e',
    'nest_multi_room',
    'nest_secure_alarm',
    'battery_horiz_000',
    'google_wifi',
    'light_group',
    'nest_cam_outdoor',
    'detection_and_zone',
    'nest_thermostat_gen_3',
    'mfg_nest_yale_lock',
    'tools_pliers_wire_stripper',
    'detector_alarm',
    'tools_ladder',
    'nest_cam_iq_outdoor',
    'early_on',
    'floor_lamp',
    'nest_clock_farsight_digital',
    'nest_cam_iq',
    'home_speaker',
    'nest_mini',
    'nest_hello_doorbell',
    'nest_audio',
    'home_max_dots',
    'nest_wifi_router',
    'house_with_shield',
    'zone_person_urgent',
    'motion_sensor_active',
    'nest_display_max',
    'cool_to_dry',
    'shield_with_house',
    'nest_farsight_weather',
    'chromecast_2',
    'battery_profile',
    'window_closed',
    'heat_pump_balance',
    'arming_countdown',
    'nest_found_savings',
    'self_care',
    'detector_status',
    'window_open',
    'tools_level',
    'nest_thermostat_zirconium_eu',
    'arrows_more_down',
    'nest_true_radiant',
    'nest_cam_wired_stand',
    'zone_person_alert',
    'nest_detect',
    'detector',
    'climate_mini_split',
    'nest_wifi_point',
    'door_sensor',
    'nest_doorbell_visitor',
    'nest_tag',
    'quiet_time',
    'nest_cam_floodlight',
    'tools_installation_kit',
    'nest_connect',
    'nest_thermostat_sensor_eu',
    'nest_sunblock',
    'nest_thermostat_sensor',
    'tools_phillips',
    'nest_wifi_gale',
    'nest_thermostat_e_eu',
    'doorbell_chime',
    'detector_co',
    'detector_battery',
    'tools_flat_head',
    'nest_wake_on_approach',
    'nest_wake_on_press',
    'table_lamp',
    'motion_sensor_urgent',
    'motion_sensor_alert',
    'window_sensor',
    'tamper_detection_on',
    'nest_cam_magnet_mount',
    'zone_person_idle',
    'quiet_time_active',
    'nest_cam_stand',
    'wall_lamp',
    'detector_offline',
    'nest_cam_wall_mount',
    'motion_sensor_idle',
    'water_pump',
    'nest_thermostat',
    'assistant_on_hub',
    'nest_protect',
    'google_tv_remote',
    'nest_wifi_pro',
    'nest_wifi_pro_2',
    'feedback',
    'flutter',
    'keep_public',
  ],
  
  InterpolationTypes: [
    'Basis',
    'Bundle',
    'Cardinal',
    'Catmull Rom',
    'Linear',
    'Monotone X',
    'Monotone Y',
    'Natural',
    'Step',
    'Step After',
    'Step Before',
  ],
  
  ClosedInterpolationTypes: ['Basis Closed', 'Cardinal Closed', 'Catmull Rom Closed', 'Linear Closed'],
  ChartTypes: [
    {
      name: 'Area Stacked',
      value: 'lineStackedAreaChart',
      beta: true,
    },
    {
      name: 'Area',
      value: 'lineAreaChart',
      beta: true,
    },
    {
      name: 'Area Normalized',
      value: 'lineNormalizedAreaChart',
      beta: true,
    },
    {
      name: 'Area Line',
      value: 'lineChart',
      beta: true,
    },
    {
      name: 'Bar Normalized Horizontal',
      value: 'normalizedHorizontalBarChart',
    },
    {
      name: 'Bar Stacked Vertical',
      value: 'stackedVerticalBarChart',
    },
    {
      name: 'Bar Vertical',
      value: 'verticalBarChart',
    },
    {
      name: 'Bar Grouped Horizontal',
      value: 'groupedHorizontalBarChart',
    },
    {
      name: 'Bar Normalized Vertical',
      value: 'normalizedVerticalBarChart',
    },
    {
      name: 'Bar Stacked Horizontal',
      value: 'stackedHorizontalBarChart',
    },
    {
      name: 'Bar Grouped Vertical',
      value: 'groupedVerticalBarChart',
    },
    {
      name: 'Bar Horizontal',
      value: 'horizontalBarChart',
    },
    {
      name: 'Heat Map',
      value: 'heatMapChart',
    },
    {
      name: 'Pie Grid',
      value: 'pieGridChart',
    },
    {
      name: 'Pie Advanced',
      value: 'advancedPieChart',
    },
    {
      name: 'Pie',
      value: 'pieChart',
    },
    {
      name: 'Gauge',
      value: 'gaugeChart',
      beta: true,
    },
    {
      name: 'Polar Radar',
      value: 'polarRadarChart',
      beta: true,
    },
    {
      name: 'Linear Gauge',
      value: 'linearGaugeChart',
      beta: true,
    },
    {
      name: 'Number Card',
      value: 'numberCardChart',
      beta: true,
    },
    {
      name: 'Tree Map',
      value: 'treeMapChart',
      beta: true,
    },
    {
      name: 'Funnel',
      value: 'funnelChart',
      beta: true,
    },
    {
      name: 'Spark Line',
      value: 'sparkLineChart',
      beta: true,
    },
    {
      name: 'Line Reference',
      value: 'lineReferencesChart',
      beta: true,
    },
    {
      name: 'Combo',
      value: 'comboChart',
      beta: true,
    },
  ],
  FontFamilies: [
    'Georgia',
    'serif',
    'Palatino Linotype',
    'Book Antiqua',
    'Palatino',
    'Times New Roman',
    'Times',
    'Arial',
    'Helvetica',
    'sans-serif',
    'Arial Black',
    'Gadget',
    'Avant Garde',
    'Garamond',
    'Century Schoolbook',
    'Bookman',
    'Comic Sans MS',
    'cursive',
    'MS Sans Serif',
    'San Francisco',
    'Impact',
    'Segoe UI',
    'Charcoal',
    'Lucida Sans Unicode',
    'Lucida Grande',
    'Tahoma',
    'Trebuchet MS',
    'Verdana',
    'Geneva',
    'Courier New',
    'Courier',
    'monospace',
    'Lucida //console',
    'Monaco',
    'Source Code Pro',
    'Source Sans Pro',
    'Titillium Web',
  ].sort(),
  Development: {
    Internal: ['luis.ventura@leapxl.com', 'jhordy.rosario@leapxl.com', 'luis.ventura005@gmail.com',
      'leuris@leapxl.com'],
    Public: ['luis.ventura@leapxl.com', 'jhordy.rosario@leapxl.com', 'brian.stack@leapxl.com',
      'leuris@leapxl.com'],
  },
  Environment: {
    domain: '',
    production: true,
    https: true,
    debug: false,
    apiUrl: '/api/',
    hubConnectionUrl: '/messages',
  },
};
